import { Grid } from '@mui/material'
import { PimProduktJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { TextField } from '@utils/ui/fields/TextField'
import React from 'react'

export interface PimArtikelProduktProps {
  produkt: PimProduktJson
  onChange: ValueChangeFn<SeArtikelJson>
  errors: any
}

export const PimArtikelProdukt = ({ produkt, onChange, errors }: PimArtikelProduktProps) => {
  return (
    <CardEx title="Produkt" name="Pimprodukt">
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={6} md={4}>
          <TextField label="hage-Nr." value={produkt.hageNummer} name="hageNummer" />
        </Grid> */}

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Zolltarif-Nummer"
            value={produkt.version.zolltarifnummer}
            onChange={onChange}
            name="pimArtikel.version.pimProdukt.version.zolltarifnummer"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Statistische Warennummer"
            value={produkt.version.statistischeWarennummer}
            onChange={onChange}
            name="pimArtikel.version.pimProdukt.version.statistischeWarennummer"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Marke"
            value={produkt.version.marke}
            onChange={onChange}
            name="pimArtikel.version.pimProdukt.version.marke"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Vertriebskanal"
            value={produkt.version.vertriebskanal}
            onChange={onChange}
            name="pimArtikel.version.pimProdukt.version.vertriebskanal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Primärklassifikation"
            value={produkt.version?.sprache?.primaerklassifikation}
            onChange={onChange}
            name="pimArtikel.version.pimProdukt.version.sprache.primaerklassifikation"
            fullWidth
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelProduktMemo = React.memo(PimArtikelProdukt)
