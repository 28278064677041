import { Grid } from '@mui/material'
import { PimArtikelJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'

export interface ArtikelPimBasisDatenProps {
  artikel: PimArtikelJson
}

export const ArtikelPimBezeichner = ({ artikel }: ArtikelPimBasisDatenProps) => {
  if (!artikel || !artikel.version || !artikel.versionKontext) {
    return null
  }

  return (
    <CardEx title="Bezeichner">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StaticField
            label="Bontext"
            value={artikel.versionKontext.bontext}
            name="bontext"
            empty
          />
        </Grid>
        <Grid item xs={6}>
          <StaticField
            label="Ursprungsland"
            value={artikel.version.ursprungsland}
            name="ursprungsland"
            empty
          />
        </Grid>
        <Grid item xs={6}>
          <StaticField
            label="Ursprungsregion"
            value={artikel.version.intrastatRegion}
            name="intrastatRegion"
            empty
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
