import { ButtonProps, Grid, Toolbar, Typography } from '@mui/material'
import { aidOf, ensureArray } from '@utils/utils'
import React, { ComponentType, ReactElement, ReactNode, useContext, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { AppContext, CheckUserRoleFn } from './App/AppContext'
import { Button } from './Buttons/Button'
import { IconButton } from './Buttons/IconButton'
import { TooltipWrapper } from './TooltipWrapper'

export interface Action {
  key?: string
  visible?: boolean
  enabled?: boolean
  disabled?: boolean
  name?: string
  role?: string
  tooltip?: string
  component?: ReactNode
  navlink?: string
  link?: string
  text?: string
  icon?: React.ReactNode
  Icon?: ComponentType<any>
  variant?: ButtonProps['variant']
  onClick?: (e: any) => void
  body?: ReactNode
  pre?: boolean
}

export type ActionItem = Action | ReactElement

export type Actions = Action | Action[] | ReactElement[] | ReactElement

const nodeco = { textDecoration: 'none' }

const buildButton = (action: Action, tiny = false) => {
  if (!action) {
    return null
  }
  if (React.isValidElement(action.body)) {
    return action.body
  }
  if (React.isValidElement(action.component)) {
    return action.component
  }
  const key = action.key || aidOf(action)
  if (action.navlink || action.link) {
    return (
      <NavLink key={key} to={action.navlink || action.link} style={nodeco}>
        {action.text ? (
          <Button
            key={key}
            data-name={action.name}
            startIcon={action.icon}
            StartIcon={action.Icon}
            variant={action.variant}
            size="small"
            disabled={action.enabled === false || action.disabled}
            label={action.text}
          />
        ) : (
          <IconButton
            key={key}
            disabled={action.enabled === false || action.disabled}
            data-name={action.name}
            size={tiny ? 'tiny' : 'small'}
            icon={action.icon}
            Icon={action.Icon}
          />
        )}
      </NavLink>
    )
  }
  if (action.text) {
    return (
      <Button
        key={key}
        data-name={action.name}
        onClick={action.onClick}
        startIcon={action.icon}
        StartIcon={action.Icon}
        variant={action.variant}
        size="small"
        disabled={action.enabled === false || action.disabled}
        label={action.text}
      />
    )
  }
  if (action.icon || action.Icon) {
    return (
      <IconButton
        key={key}
        onClick={action.onClick}
        disabled={action.enabled === false || action.disabled}
        data-name={action.name}
        size={tiny ? 'tiny' : 'small'}
        icon={action.icon}
        Icon={action.Icon}
      />
    )
  }

  // eslint-disable-next-line no-console
  console.error('unhandled action', action)
  return null
}

export type buildActionButtonProps = {
  checkUserRole: CheckUserRoleFn | null
  action: Action | undefined | null
  tiny?: boolean
  pre?: boolean
}

const buildActionButton = ({
  action,
  checkUserRole,
  tiny,
  pre
}: buildActionButtonProps): ReactElement => {
  if (!action) {
    return null
  }
  if (React.isValidElement(action)) {
    return action
  }
  if (
    action.visible === false ||
    (action.role && checkUserRole && !checkUserRole(action.role)) ||
    !!action.pre !== !!pre
  ) {
    return null
  }
  const item = buildButton(action, tiny)
  if (action.tooltip && item) {
    return (
      <TooltipWrapper key={item.key} title={action.tooltip}>
        {item}
      </TooltipWrapper>
    )
  }

  return item
}

export type buildActionButtonsProps = {
  actions?: Actions | undefined | null
  checkUserRole?: CheckUserRoleFn | null
  xtra?: Actions | undefined | null
  tiny?: boolean
  pre?: boolean
  asgriditem?: boolean
}

export const buildActionButtons = ({
  checkUserRole,
  actions,
  xtra,
  tiny,
  pre,
  asgriditem
}: buildActionButtonsProps): React.ReactNode => {
  if (asgriditem) {
    if (React.isValidElement(actions)) {
      return <Grid item>{actions}</Grid>
    }
    return [...ensureArray(actions), ...ensureArray(xtra)]
      .map((action) => buildActionButton({ action, checkUserRole, tiny, pre }))
      .filter((btn) => btn != null)
      .map((btn, idx) => (
        <Grid item key={btn.key ?? 'key-' + idx}>
          {btn}
        </Grid>
      ))
  }
  if (React.isValidElement(actions)) {
    return actions
  }
  return [...ensureArray(actions), ...ensureArray(xtra)]
    .map((action, idx) => {
      const c = buildActionButton({ action, checkUserRole, tiny, pre })
      return c ? <span key={action.key ?? 'key-' + idx}>{c}</span> : null
    })
    .filter((btn) => btn != null)
}

export interface ActionHeaderProps {
  title: string
  actions?: Actions
}

const useStyles = makeStyles()((theme: any) => ({
  title: {
    flex: '1 1 100%'
  },
  toolbarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  }
}))

export const ActionHeader = ({ title, actions }: ActionHeaderProps) => {
  const { classes } = useStyles()
  const { checkUserRole } = useContext(AppContext)

  const realActions = useMemo(
    () => buildActionButtons({ checkUserRole, actions }),
    [checkUserRole, actions]
  )

  return (
    <Toolbar className={classes.toolbarRoot}>
      <Typography className={classes.title} variant="h6" component="div">
        {title}
      </Typography>
      {realActions}
    </Toolbar>
  )
}
