import { api } from '@one/api'
import { ArtikelBetriebstypField } from '@one/components/common/ArtikelBetriebstypField'
import {
  ArtikelDatenpoolKeyJson,
  ArtikelDatenpoolUebernahmeBearbeitenJson,
  ArtikelDatenpoolUebernahmeChangeJson,
  ArtikelDatenpoolUebernahmeJson,
  ArtikelDatenpoolUebernahmeStatus,
  EANGruppeDisplayJson,
  UseCaseStateJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useFormState } from '@utils/formstate'
import { Button } from '@utils/ui/Buttons/Button'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { SelectField } from '@utils/ui/fields/SelectField'
import { SnackbarType, useSnackbarEx } from '@utils/ui/snackbarex'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { UebernahmeEigenlistungTable } from './UebernahmeEigenlistungTable'
import { AppContext } from '@utils/ui/App/AppContext'
import { NeonKontextField } from '@one/components/common/NeonKontextField'
import { Grid } from '@mui/material'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { HkmEnum } from '@one/enums/HkmEnum'
import { WarengruppeField } from '@one/components/Warengruppe/WarengruppeField'
import { SpartenField } from '@one/components/Warengruppe/SparteField'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { messageToErrors } from '@utils/utils'

export interface UebernahmeEigenlistungsDialogProps {
  open: boolean
  onClose: (saved: boolean) => void
  artikelKeys: ArtikelDatenpoolKeyJson[]
}

export const UebernahmeEigenlistungsDialog = ({
  open,
  onClose,
  artikelKeys
}: UebernahmeEigenlistungsDialogProps) => {
  const { isPIMModel } = useContext(AppContext)
  const [apiCall, apiBusy] = useApiCaller(api)
  const [usecase, setUsecase] = useState<ArtikelDatenpoolUebernahmeBearbeitenJson>({})
  const [state, onChange] = useFormState<ArtikelDatenpoolUebernahmeChangeJson>({
    eanGruppeId: null,
    bt: null,
    kontext: null,
    sparteId: null,
    warengruppeId: null
  })
  const [selected, setSelected] = useState<Set<ArtikelDatenpoolUebernahmeJson>>(new Set())
  const [error, setError] = useState<UseCaseStateJson>()
  const { enqueMsg } = useSnackbarEx()

  const errors = useMemo<any>(
    () => messageToErrors(error?.mainMessage && [error?.mainMessage], 'flat'),
    [error?.messages]
  )

  console.log('errors', errors)

  const create = useCallback(
    () =>
      apiCall<ArtikelDatenpoolUebernahmeBearbeitenJson>({
        method: 'POST',
        rest: '/artikeldatenpool/uebernahme/create',
        data: artikelKeys,
        onSuccess: (data) => {
          setUsecase(data)
          setError(null)
          onChange(() => ({
            bt: data.defaultBetriebstyp,
            artikelArtId: data.defaultArtikelArtId,
            eanGruppeId: data.defaultEanGruppeId,
            kontext: data.defaultKontext,
            pimArtikelArt: data.defaultPimArtikelArt
          }))
        },
        onError: (error: UseCaseStateJson) => {
          setError(error)
        }
      }),
    [apiCall, artikelKeys, onChange]
  )

  useEffect(() => {
    create()
  }, [create])

  const onSave = useCallback(
    () =>
      apiCall<ArtikelDatenpoolUebernahmeBearbeitenJson>({
        method: 'POST',
        rest: '/artikeldatenpool/uebernahme/save',
        data: {
          bt: state.bt,
          artikelArtId: state.artikelArtId,
          eanGruppeId: state.eanGruppeId,
          kontext: state.kontext,
          sparteId: state.sparteId,
          warengruppeId: state.warengruppeId,
          keyList: Array.from(selected).map((item) => ({
            hageNummer: item.dpArtikel.hageNummer,
            supplierID: item.dpArtikel.supplierID
          }))
        },
        onSuccess: () => {
          enqueMsg('Anlage der Eigenlistung erfolgreich', SnackbarType.success)
          onClose(true)
        },
        onError: (error: UseCaseStateJson) => {
          setError(error)
        }
      }),
    [apiCall, state, selected, onClose, enqueMsg]
  )

  const validSelection = useMemo(() => {
    const invalidSelection = Array.from(selected).find(
      (artikel) =>
        artikel.status !== ArtikelDatenpoolUebernahmeStatus.ANLAGE &&
        artikel.status !== ArtikelDatenpoolUebernahmeStatus.AKTUALISIEREN
    )
    return (
      invalidSelection === undefined &&
      (isPIMModel ? !!state.kontext : !!state.bt && !!state.eanGruppeId) &&
      !!state.artikelArtId &&
      !!state.warengruppeId &&
      !!state.sparteId
    )
  }, [isPIMModel, selected, state])

  const actions = (
    <>
      <Button
        key="eigenlistung"
        label="Eigenlistung anlegen"
        disabled={selected.size === 0 || !validSelection}
        onClick={onSave}
        variant="contained"
        tooltip={
          !validSelection
            ? 'Bitte ein Betriebstyp und eine EAN-Gruppe wählen'
            : selected.size === 0
              ? 'Bitte Artikel auswählen'
              : ''
        }
      />
      <CancelButton onClick={() => onClose(false)} />
    </>
  )

  return (
    <DialogEx
      open={open}
      onClose={() => onClose(false)}
      fullWidth
      height="800px"
      maxWidth="xl"
      title="Eigenlistungs-Anlage"
      actions={actions}
    >
      <StatePlane wait={apiBusy} noNav>
        <Frame space>
          <FrameRow>
            <Grid container spacing={2} padding={2} direction="row">
              {isPIMModel ? (
                <>
                  <Grid item xs={12} sm={6} lg={3}>
                    <NeonKontextField
                      value={state.kontext}
                      label="Kontext"
                      required
                      onChange={onChange}
                      name="kontext"
                      error={errors['kontext']}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <SelectEnumField
                      label="Artikelart"
                      value={state.pimArtikelArt}
                      name="artikelArtId"
                      onChange={onChange}
                      enumType={HkmEnum.PimArtikelArt}
                      required
                      fullWidth
                      error={errors['pimArtikelArt']}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6} lg={3}>
                    <ArtikelBetriebstypField
                      label="Betriebstyp"
                      value={state.bt}
                      onChange={onChange}
                      name="bt"
                      required
                      error={errors['bt']}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <SelectField
                      options={usecase.artikelArten}
                      optionText={(art) => `${art.kurz} - ${art.bez}`}
                      value={state.artikelArtId}
                      label="Artikelart"
                      name="artikelArtId"
                      onChange={onChange}
                      fullWidth
                      required
                      error={errors['artikelArtId']}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <SelectField
                      name="eanGruppeId"
                      label="Standard EAN-Gruppe"
                      // error={errors.defaultEanGruppeId}
                      value={state.eanGruppeId}
                      options={usecase.eanGruppen}
                      onChange={onChange}
                      renderItem={(eanGruppe: EANGruppeDisplayJson) =>
                        eanGruppe.name + ' - ' + eanGruppe.bezeichnung
                      }
                      fullWidth
                      required
                      error={errors['eanGruppeId']}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6} lg={3}>
                <WarengruppeField
                  label="Warengruppe"
                  value={state.warengruppeId}
                  onChange={onChange}
                  name="warengruppeId"
                  fullWidth
                  idMode="seId"
                  required
                  gfMitStatistik
                  error={errors['warengruppeId']}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <SpartenField
                  value={state.sparteId}
                  name="sparteId"
                  onChange={onChange}
                  fullWidth
                  required
                  error={errors['sparteId']}
                />
              </Grid>
            </Grid>
          </FrameRow>
          <FrameBody>
            <UebernahmeEigenlistungTable
              eintraege={usecase.eintraege}
              selected={selected}
              setSelected={setSelected}
            />
          </FrameBody>
        </Frame>
      </StatePlane>
    </DialogEx>
  )
}
