import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { VkPreiseMasseBearbeitenEintragJson } from '@one/typings/apiTypings'
import { formatDateTime } from '@utils/dateutils'
import { notifyObservers, useObserver } from '@utils/observer'
import { useStateEx } from '@utils/stateex'
import { Action } from '@utils/ui/Action'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { AppRouteCtx } from '@utils/ui/App/AppRouteCtx'
import { Button } from '@utils/ui/Buttons/Button'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { DeleteButton } from '@utils/ui/Buttons/DeleteButton'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useSnackbarEx } from '@utils/ui/snackbarex'
import { arrayFromSet } from '@utils/utils'
import { useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { VkPeisMassenFrachtkostenDialog } from './VkPeisMassenFrachtkostenDialog'
import { VkPeisMassenAenderDialog } from './VkPreisMassenAenderDialog'
import { VkPreisMassenPflegeKopf } from './VkPreisMassenPflegeKopf'
import { VkPreisMassenPflegeTable } from './VkPreisMassenPflegeTable'
import { useVkPreisMassenKopf } from './model/VkPreisMassenKopfModel'

export const VkPreisMassenPflegeView = () => {
  const { id } = useContext(RouteContext) as any
  const { visible } = useContext(AppRouteCtx)

  const [DlgAnker, showDlg] = useDialogAnker()

  const navigate = useNavigate()

  const { askToConfirm } = useMessageDialog()

  const { kopf, updateArtikelliste, reload, apiCall, apiBusy, remove, removeSelected } =
    useVkPreisMassenKopf(id)

  const [selected, setSelected, getSelected] = useStateEx(
    new Set<VkPreiseMasseBearbeitenEintragJson>()
  )

  const { actionFreigeben, actionAbschliessen } = kopf

  const { enqueMsg } = useSnackbarEx()

  const observerId = useObserver(
    EventNames.VKPREISLISTE,
    (event) => {
      const listeId = event.data?.id
      if (listeId && kopf?.id == listeId) {
        enqueMsg('Die Daten wurden außerhalb des Editors geändert!', 'warning')
        reload()
      }
    },
    visible
  )

  const notifyVkPreisListenEvent = useCallback(() => {
    notifyObservers({
      name: EventNames.VKPREISLISTE,
      origin: observerId,
      data: { id: kopf.id }
    })
  }, [kopf.id, observerId])

  const onChangePreis = useCallback(() => {
    showDlg((visible, onClose) => (
      <VkPeisMassenAenderDialog
        kopf={kopf}
        visible={visible}
        onClose={onClose}
        onComplete={(updated) => {
          updateArtikelliste(updated)
          notifyVkPreisListenEvent()
        }}
        selected={Array.from(getSelected())}
      />
    ))
  }, [showDlg, kopf, getSelected, updateArtikelliste, notifyVkPreisListenEvent])

  const onChangeFrachtkosten = useCallback(() => {
    showDlg((visible, onClose) => (
      <VkPeisMassenFrachtkostenDialog
        kopf={kopf}
        visible={visible}
        onClose={onClose}
        onComplete={(updated) => {
          updateArtikelliste(updated)
          notifyVkPreisListenEvent()
        }}
        selected={Array.from(getSelected())}
      />
    ))
  }, [getSelected, kopf, notifyVkPreisListenEvent, showDlg, updateArtikelliste])

  const onFreigeben = useCallback(() => {
    askToConfirm({
      title: 'Freigabe',
      message: 'Diese Preisliste freigeben. Eine Bearbeitung ist danach nicht mehr möglich.',
      onConfirm: () =>
        apiCall({
          method: 'POST',
          rest: '/vkpreisliste/alleFreigeben',
          params: { id: kopf.id },
          onErrorMsg: 'Die Preisliste konnte nicht freigegeben werden',
          onSuccessMsg: 'Die Preisliste wurde erfolgreich freigegeben.',
          onSuccess: () => {
            reload()
            notifyVkPreisListenEvent()
          }
        })
    })
  }, [apiCall, askToConfirm, kopf.id, notifyVkPreisListenEvent, reload])

  const onAlleAbschliessen = useCallback(() => {
    askToConfirm({
      title: 'Soll die Preisliste abgeschlossen werden?',
      message: 'Es werden alle Preise abgeschlossen und können dann freigegeben werden.',
      onConfirm: () => {
        apiCall({
          method: 'POST',
          rest: '/vkpreisliste/alleAbschliessen',
          params: { id: kopf.id },
          onErrorMsg: 'Die Preisliste konnte nicht abgeschlossen werden',
          onSuccessMsg: 'Die Preisliste wurde erfolgreich abgeschlossen.',
          onSuccess: () => {
            reload()
            notifyVkPreisListenEvent()
          }
        })
      }
    })
  }, [apiCall, askToConfirm, kopf.id, notifyVkPreisListenEvent, reload])

  const onDeleteClick = useCallback(
    () =>
      remove({
        onRemoved: () => {
          navigate(AppPaths.VkPreisUebersicht[0])
          notifyVkPreisListenEvent()
        }
      }),
    [navigate, notifyVkPreisListenEvent, remove]
  )

  const onDeleteSelectedArtikel = useCallback(() => {
    if (kopf.artikelListe.length === getSelected().size) {
      onDeleteClick()
    } else {
      removeSelected({
        selected: Array.from(getSelected()).map((eintrag: any) => eintrag.vkPreisListeEintragId),
        onRemoved: () => {
          reload()
          notifyVkPreisListenEvent()
        }
      })
    }
  }, [
    kopf.artikelListe.length,
    getSelected,
    onDeleteClick,
    removeSelected,
    reload,
    notifyVkPreisListenEvent
  ])

  const actions = kopf.freigegegen ? (
    <span>{`Die Preisliste wurde am ${formatDateTime(kopf.freigegebenUm)} freigegeben`}</span>
  ) : (
    <>
      {actionFreigeben && actionFreigeben.visible && (
        <Button
          label="Preisliste freigeben"
          onClick={onFreigeben}
          variant="text"
          color="primary"
          disabled={actionFreigeben.enabled === false}
          tooltip={actionFreigeben.info}
        />
      )}
      {actionAbschliessen && actionAbschliessen.visible && (
        <Button
          label="Alle abschließen"
          onClick={onAlleAbschliessen}
          variant="text"
          color="primary"
          disabled={actionAbschliessen.enabled === false}
          title={actionAbschliessen.info}
        />
      )}
      <DeleteButton
        label="Liste löschen"
        onDelete={onDeleteClick}
        isNew={false}
        deleteMsg="Gesamte Preisliste wirklich löschen"
        tooltip="Gesamte Preisliste löschen"
      />
      <ReloadButton onClick={() => reload()} />
    </>
  )

  const tableActions = useMemo(
    () =>
      [
        {
          text: 'VK-Preise ändern',
          variant: 'contained',
          enabled:
            selected.size !== 0 && arrayFromSet(selected).find((s) => s.error != null) == null,
          visible: !kopf.freigegegen,
          onClick: onChangePreis
        },
        {
          text: 'Frachtkosten und Skonto ändern',
          variant: 'contained',
          enabled:
            selected.size !== 0 && arrayFromSet(selected).find((s) => s.error != null) == null,
          visible: !kopf.freigegegen && !kopf.forEinzelhandel,
          onClick: onChangeFrachtkosten
        },
        <DeleteButton
          key="artikel-entfernen"
          // className={classes.deleteButton}
          onDelete={onDeleteSelectedArtikel}
          disabled={actionFreigeben?.enabled}
          visible={!kopf.freigegegen}
          size="small"
          tooltip="Ausgewählte Artikel werden aus der Liste entfernt"
          isNew={!(selected.size !== 0)}
          deleteMsg="Möchten Sie alle gewählten Artikel wirklich aus dieser VK-Preisliste entfernen?"
        />,
        {
          text: 'Letzte Änderung rückgängig machen',
          variant: 'contained',
          enabled: false,
          visible: false
        }
      ] as Action[],
    [
      selected,
      kopf.freigegegen,
      kopf.forEinzelhandel,
      onChangePreis,
      onChangeFrachtkosten,
      onDeleteSelectedArtikel,
      actionFreigeben?.enabled
    ]
  )

  return (
    <StatePlane wait={apiBusy} error={kopf.fatalError} altLink={AppPaths.VkPreisUebersicht[0]}>
      <Frame space>
        <FrameRow>
          <VkPreisMassenPflegeKopf model={kopf} setStandortId={({ value }) => reload(value)} />
        </FrameRow>
        <FrameBody>
          <VkPreisMassenPflegeTable
            selected={selected}
            reload={reload}
            onSelect={setSelected}
            data={kopf.artikelListe}
            preisgruppen={kopf.preisgruppen}
            actions={tableActions}
            preislisteId={id}
            zufuhrMultis={kopf.zufuhrMultis}
            forEinzelhandel={kopf.forEinzelhandel}
          />
        </FrameBody>
        <FrameRow>
          <ButtonRow>{actions}</ButtonRow>
          <DlgAnker />
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
