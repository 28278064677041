import { Button } from '@mui/material'
import { useMemo } from 'react'

export const NextButton = ({ onClick, isChanged, abgeschlossen, artikelCount, error }) => {
  const nextText = useMemo(() => {
    if (!error) {
      if (isChanged) {
        if (!abgeschlossen) {
          if (artikelCount > 1) {
            return 'Speichern, abschließen & weiter'
          }
          return 'Speichern & abschließen'
        }
        if (artikelCount > 1) {
          return 'Speichern & weiter'
        }
        return 'Speichern'
      }

      if (!abgeschlossen) {
        if (artikelCount > 1) {
          return 'Abschließen & weiter'
        }
        return 'Abschließen'
      }
    }
    return 'Weiter'
  }, [abgeschlossen, artikelCount, error, isChanged])

  return artikelCount > 1 || !abgeschlossen ? (
    <Button onClick={onClick} variant="contained" color="primary">
      {nextText}
    </Button>
  ) : null
}
