import { Box } from '@mui/material'
import { api } from '@one/api'
import { useModelMgr } from '@utils/modelmgr'
import { Button } from '@utils/ui/Buttons/Button'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { RadioGroup } from '@utils/ui/fields/RadioGroup'
import { EkPreiseMasseBearbeitenEintragExJson } from './model/EkPreisMassenPflegeModel'

export interface EkPreisMassenAbgeschlossenDialogProps {
  kopf: any
  visible?: boolean
  onClose?: (fn?: () => void) => () => void
  selected?: EkPreiseMasseBearbeitenEintragExJson[]
  onComplete?: (updated?: any) => void
}

export const EkPreisMassenAbgeschlossenDialog = ({
  onClose,
  onComplete,
  kopf,
  visible = true,
  selected = []
}: EkPreisMassenAbgeschlossenDialogProps) => {
  const { model, onValueChange, isChanged, save } = useModelMgr({
    id: 'neu',
    api,
    title: 'EK-Preis Massenänderung',
    rest: '/ekpreisliste/executeaction',
    restSave: 'abschliessen',
    restCreate: null,
    init: () => ({ abgeschlossen: selected[0]?.abgeschlossen || false }),
    onSave: onClose(onComplete),
    saveMutator: (data) => {
      return {
        id: 4711, // ActionId
        listeId: kopf.id,
        preisEbeneId: kopf.preisEbeneId,
        eintraege: selected.map((s) => s.eintragId),
        abgeschlossen: data.abgeschlossen
      }
    }
  })

  const actions = (
    <>
      <Button
        label={`Für ${selected.length} Einträge anwenden`}
        onClickVoid={save}
        variant="contained"
        disabled={!isChanged}
      />
      <CancelButton onClick={onClose()} />
    </>
  )

  const radios = [
    {
      label: 'Abgeschlossen',
      value: true
    },
    {
      label: 'Nicht abgeschlossen',
      value: false
    }
  ]

  return (
    <DialogEx
      open={visible}
      onClose={onClose()}
      maxWidth={false}
      actions={actions}
      title="Abgeschlossen ändern"
      disabledFullscreen
      smallHeader
      height="250px"
    >
      <Box padding={1}>
        <RadioGroup
          type="boolean"
          name="abgeschlossen"
          value={model.abgeschlossen}
          onChange={onValueChange}
          options={radios}
        />
      </Box>
    </DialogEx>
  )
}
