import { Grid, Typography } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { ArtikelAboSection } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelAboSection'
import { ArtikelErpEkPreise } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelErpEkPreise'
import { ArtikelErpVkPreise } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelErpVkPreise'
import { ArtikelLabelTableView } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelLabelTableView'
import { ArtikelLieferantenSection } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelLieferantenSection'
import { ArtikelListenSection } from '@one/components/Artikel/ArtikelAnzeigen/details/ArtikelListenSection'
import { ArtikelPimBezeichner } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimBezeichner'
import { ArtikelPimCompliance } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimCompliance'
import { ArtikelPimComplianceLaender } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimComplianceLaender'
import { ArtikelPimDisplayArtikel } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimDisplayArtikel'
import { ArtikelPimERPStatus } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimERPStatus'
import { ArtikelPimEigenschaften } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimEigenschaften'
import { ArtikelPimEkPreise } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimEkPreise'
import { ArtikelPimFliesen } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimFliesen'
import { ArtikelPimKontext } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimKontext'
import { ArtikelPimLieferanten } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimLieferanten'
import { ArtikelPimLogistik } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimLogistik'
import { ArtikelPimMasse } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimMasse'
import { ArtikelPimMengeneinheiten } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimMengeneinheiten'
import { ArtikelPimPackstuecke } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimPackstuecke'
import { ArtikelPimPflanzen } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimPflanzen'
import { ArtikelPimPimProdukt } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimProdukt'
import { ArtikelPimSprache } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimSprache'
import { ArtikelPimSteuer } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimSteuer'
import { ArtikelPimVkPreise } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimVkPreise'
import { ArtikelPimVkSperre } from '@one/components/Artikel/ArtikelAnzeigen/pim/ArtikelPimVkSperre'
import { ArtikelAbonniertStatusMedal } from '@one/components/common/ArtikelAbonniertStatusMedal'
import { formatNeonKontext } from '@one/components/common/formatters'
import { ArtikelQuelle, PimArtikelAnzeigenJson, SuchArtikelTyp } from '@one/typings/apiTypings'
import { formatDateTime } from '@utils/dateutils'
import { useEnums } from '@utils/enums'
import { AppContext } from '@utils/ui/App/AppContext'
import { Button } from '@utils/ui/Buttons/Button'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { CardEx } from '@utils/ui/CardEx'
import { getValueComparator } from '@utils/ui/DataTable/DataTableUtils'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { IndexPanel, IndexPanelItem } from '@utils/ui/IndexPanel'
import { ThemeContext } from '@utils/ui/Theme'
import { SelectField } from '@utils/ui/fields/SelectField'
import { asNumber, sortArray } from '@utils/utils'
import { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { StandortArtikelRegalplatz } from '@one/components/Artikel/ArtikelAnzeigen/details/StandortArtikelRegalplatz'

export type PimArtikelViewProps = {
  data: PimArtikelAnzeigenJson
  reload: () => void
}

export const PimArtikelView = ({ data, reload }: PimArtikelViewProps) => {
  const navigate = useNavigate()

  const { et } = useEnums()
  const { darkMode } = useContext(ThemeContext)

  const { isWithErpImport } = useContext(AppContext)

  const display = data.display
  const kontext = data.kontext
  const id = display.id
  const partnerArtikelId = data.partnerArtikelId
  const aboStatus = data.details?.aboStatus
  const zentral = data.display.quelle === ArtikelQuelle.ZENTRAL

  const header = useMemo(() => {
    const title = display.bez1 ? `"${display.bez1}"` : ''

    const kontextField = data.kontexte && (
      <SelectField
        value={kontext}
        onChange={(e) => {
          const val = data.kontexte.find((k) => k.pimKontext.kontext == e.target.value)
          if (val) {
            navigate(AppPaths.ArtikelExFn(display.id, null, val.pimKontext.kontext))
          }
        }}
        notNull
        options={data.kontexte}
        optionValue={(k) => k.pimKontext.kontext}
        renderItem={(k) => formatNeonKontext(k.pimKontext)}
        renderSelected={(k) => `Kontext: ${formatNeonKontext(k.pimKontext)}`}
        withButtons
      />
    )
    const revisionField = data.versionen && (
      <SelectField
        value={data.artikel?.version?.id}
        onChange={(e) => navigate(AppPaths.ArtikelExFn(id, asNumber(e.target.value), kontext))}
        options={sortArray(
          data.versionen,
          getValueComparator('desc', (o) => o.nr)
        )}
        renderItem={(k) => (
          <span>
            Revision {k.nr}: <b>{formatDateTime(k.zeitpunkt)}</b>
          </span>
        )}
        renderSelected={(k) => (
          <span>
            Revision {k.nr}: <b>{formatDateTime(k.zeitpunkt)}</b>
          </span>
        )}
        notNull
        withButtons="reverse"
      />
    )

    return (
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <Typography variant="h6">
            {data.quelle === ArtikelQuelle.ERP ? 'ERP-Artikel ' : 'PIM-Artikel '}
            {title}
          </Typography>
        </Grid>
        {aboStatus && (
          <Grid item>
            <ArtikelAbonniertStatusMedal value={aboStatus} et={et} darkMode={darkMode} />
          </Grid>
        )}
        <Grid item>{kontextField}</Grid>
        <Grid item>{revisionField}</Grid>
        {zentral && data.artikel?.record?.offset && (
          <Grid item flexGrow={1} textAlign="right" fontSize="60%">
            Kafka-Offset {data.artikel?.record?.offset}
          </Grid>
        )}
        {partnerArtikelId && (
          <Grid item flexGrow={1} textAlign="right">
            <Button
              label={zentral ? 'Zum ERP-artikel' : 'Zum PIM-Artikel'}
              to={AppPaths.ArtikelFn(partnerArtikelId)}
            />
          </Grid>
        )}
      </Grid>
    )
  }, [
    display.bez1,
    display.id,
    data.kontexte,
    data.versionen,
    data.artikel?.version?.id,
    data.artikel?.record?.offset,
    data.quelle,
    kontext,
    aboStatus,
    et,
    darkMode,
    zentral,
    partnerArtikelId,
    navigate,
    id
  ])

  const items = [
    {
      id: 'bezeichner',
      label: 'Bezeichner',
      body: () => <ArtikelPimBezeichner artikel={data.artikel} />
    },
    {
      id: 'eigwnschaften',
      label: 'Eigenschaften',
      body: () => <ArtikelPimEigenschaften artikel={data.artikel} details={data.details} />
    },
    {
      id: 'masse',
      label: 'Maße',
      exclude: !data.artikel.version,
      body: () => <ArtikelPimMasse model={data.artikel.version} />
    },
    {
      id: 'kontext',
      label: 'Kontext',
      exclude: !data.artikel.versionKontext,
      body: () => <ArtikelPimKontext model={data.artikel.versionKontext} />
    },
    {
      id: 'display',
      label: 'Display',
      body: () => <ArtikelPimDisplayArtikel artikel={data.artikel} />
    },
    {
      id: 'produkt',
      label: 'Produkt',
      body: () => <ArtikelPimPimProdukt artikel={data.artikel} />
    },
    {
      id: 'status',
      label: 'ERP Import-Status',
      exclude: !isWithErpImport,
      body: () => <ArtikelPimERPStatus pimData={data} />
    },
    {
      id: 'vksperre',
      label: 'VK-Sperre',
      body: () => <ArtikelPimVkSperre pimData={data} />
    },
    {
      id: 'lieferanten',
      label: 'Lieferanten',
      body: () =>
        zentral ? (
          <ArtikelPimLieferanten artikel={data.artikel} />
        ) : (
          <ArtikelLieferantenSection details={data.details} />
        )
    },
    {
      id: 'hagebau-logistik',
      label: 'Hagebau-Logistik',
      body: () => <ArtikelPimLogistik artikel={data.artikel} />
    },
    {
      id: 'eans',
      label: 'Mengeneinheiten und EANs',
      body: () => <ArtikelPimMengeneinheiten model={data.artikel?.version} />,
      exclude: data.artikel?.version?.mengeneinheiten == null
    },
    {
      id: 'packstuecke',
      label: 'Packstücke',
      body: () => <ArtikelPimPackstuecke packstuecke={data.artikel.version?.packstuecke} />,
      exclude: !(data.artikel.version?.packstuecke?.length > 0)
    },
    {
      id: 'ekpreise',
      label: 'EK-Preise',
      body: () =>
        zentral ? (
          <ArtikelPimEkPreise lieferanten={data.artikel.lieferanten} />
        ) : (
          <ArtikelErpEkPreise
            ekPreise={data?.details?.ekPreise}
            konditionenDefinitionen={data?.details?.konditionenDefinitionen}
          />
        ),
      exclude:
        // Since the backend return for artikel.lieferant an "[null]" as empty a length check won't work
        // so we need to check if the first element is null
        (zentral && data.artikel?.lieferanten?.at(0) == null) ||
        (!zentral && data.details?.ekPreise == null)
    },
    {
      id: 'vkpreise',
      label: 'VK-Preise',
      body: () =>
        zentral ? (
          <ArtikelPimVkPreise vkPreise={data.vkPreise} />
        ) : (
          <ArtikelErpVkPreise
            vkPreise={data.details?.vkPreise}
            preisGruppen={data?.details?.vkPreisgruppen}
            mengeneinheiten={data.details?.mengeneinheiten}
          />
        ),
      exclude:
        (zentral && data.vkPreise?.length == 0) || (!zentral && data.details?.vkPreise == null)
    },
    {
      id: 'fliese',
      label: 'Fliese',
      body: () => <ArtikelPimFliesen fliese={data.artikel.version.fliese} />,
      exclude: data.artikel?.version?.fliese == null
    },
    {
      id: 'pflanze',
      label: 'Pflanze',
      body: () => <ArtikelPimPflanzen pflanze={data.artikel.version.pflanze} />,
      exclude: data.artikel?.version?.pflanze == null
    },
    {
      id: 'compliance',
      label: 'Compliance',
      body: () => <ArtikelPimCompliance compliance={data.artikel.version.compliance} />,
      exclude: data.artikel.version.compliance == null
    },
    {
      id: 'compliance-laender',
      label: 'Compliance-Länder',
      body: () => <ArtikelPimComplianceLaender compliance={data.artikel} />,
      exclude: data.artikel.version.compliance == null
    },
    {
      id: 'texte',
      label: 'Texte DE',
      body: () => <ArtikelPimSprache artikel={data.artikel} />
    },
    {
      id: 'steuer',
      label: 'Steuerindikation',
      body: () => <ArtikelPimSteuer artikel={data.artikel} />
    },
    {
      id: 'regalplaetze',
      label: 'Regalplätze',
      body: () => <StandortArtikelRegalplatz regalplaetze={data.details?.regalplaetze} />,
      exclude: zentral || !(data.details?.regalplaetze?.length > 0)
    },
    {
      id: 'artikelabo',
      label: 'Artikelabo',
      body: () => <ArtikelAboSection selektionen={data.details?.selektionen} />,
      exclude: zentral
    },
    {
      id: 'artikellisten',
      label: 'Artikellisten',
      body: () => <ArtikelListenSection manuelleListen={data.details?.manuelleListen} />,
      exclude: zentral || !(data.details?.manuelleListen?.length > 0)
    },
    {
      id: 'artikellabel',
      label: 'Artikel-Label',
      body: () => (
        <ArtikelLabelTableView
          labels={data.details?.labels}
          artikel={{ typ: SuchArtikelTyp.DEZENTRAL, id }}
          reload={reload}
        />
      ),
      exclude: zentral
    }
  ] as IndexPanelItem[]

  return (
    <Frame space>
      <FrameRow>
        <CardEx
          backLink
          overviewLink={AppPaths.ArtikelUebersicht}
          header={header}
          contentStyle={{ display: 'none' }}
        />
      </FrameRow>
      <FrameBody>
        <IndexPanel content={items} leftStartWidth={300} />
      </FrameBody>
      <FrameRow>
        <ButtonRow>
          <ReloadButton onClick={reload} />
        </ButtonRow>
      </FrameRow>
    </Frame>
  )
}
