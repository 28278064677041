import { Grid } from '@mui/material'
import { PimArtikelAnzeigenJson } from '@one/typings/apiTypings'
import { formatDate } from '@utils/dateutils'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'

export interface ArtikelPimVkSperreProps {
  pimData: PimArtikelAnzeigenJson
}

export const ArtikelPimVkSperre = ({ pimData }: ArtikelPimVkSperreProps) => {
  const artikel = pimData?.artikel
  // if (artikel?.version?.vkSperre == null) {
  //   return null
  // }

  return (
    <CardEx title="VK-Sperre" name="vksperre">
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} sm={12} lg={2}>
          <StaticField name="vkSperre" label="VK Sperre" value={!!artikel.version.vkSperre} yesno />
        </Grid>
        <Grid item xs={12} sm={12} lg={2}>
          <StaticField
            name="vkSperreAb"
            label="VK Sperre ab"
            value={formatDate(artikel.versionKontext?.vkSperreAb)}
            empty
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={2}>
          <StaticField
            name="vkSperreBis"
            label="VK Sperre bis"
            value={formatDate(artikel.versionKontext?.vkSperreBis)}
            empty
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={5}>
          <StaticField
            name="vkSperreGrund"
            label="Grund"
            value={artikel.versionKontext?.vkSperreGrund}
            empty
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
