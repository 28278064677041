import { Grid } from '@mui/material'
import { PimArtikelJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'

export interface ArtikelPimLogistikProps {
  artikel: PimArtikelJson
}

export const ArtikelPimLogistik = ({ artikel }: ArtikelPimLogistikProps) => {
  if (!artikel || !artikel.version) {
    return null
  }
  return (
    <CardEx title="Hagebau-Logistik" name="HaugebauLogistik">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Stapel-Höhe"
            value={artikel.version.hagebauLogistikStapelhoehe}
            name="hagebauLogistikStapelhoehe"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Regalfähigkeit"
            value={artikel.version.hagebauLogistikRegalfaehigkeit}
            name="hagebauLogistikRegalfaehigkeit"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Produktmanager"
            value={artikel.version.hagebauLogistikProduktmanager}
            name="hagebauLogistikProduktmanager"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Feinkommissionierung"
            value={artikel.version.hagebauLogistikFeinkommissionierung}
            name="hagebauLogistikFeinkommissionierung"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Lagerbereich"
            value={artikel.version.hagebauLogistikLagerbereich}
            name="hagebauLogistikLagerbereich"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Warenausgang-Handlingskategorie"
            value={artikel.version.hagebauLogistikWarenausgangHandlingskategorie}
            name="hagebauLogistikWarenausgangHandlingskategorie"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Wareneingang-Handlingskategorie"
            value={artikel.version.hagebauLogistikWareneingangHandlingskategorie}
            name="hagebauLogistikWareneingangHandlingskategorie"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Einkauf-Mengeneinheit"
            value={artikel.version.hagebauLogistikEinkaufMengeneinheit}
            name="hagebauLogistikEinkaufMengeneinheit"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Lagerkosten-Mengeneinheit"
            value={artikel.version.hagebauLogistikLagerkostenMengeneinheit}
            name="hagebauLogistikLagerkostenMengeneinheit"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Warenausgang-Mengeneinheit"
            value={artikel.version.hagebauLogistikWarenausgangMengeneinheit}
            name="hagebauLogistikWarenausgangMengeneinheit"
            empty
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
