import { orange } from '@mui/material/colors'
import {
  ArtikelBetriebstypCell,
  getArtikelBetriebstypLabel
} from '@one/components/common/ArtikelBetriebstypCell'
import { formatLieferant, formatMengeneinheit } from '@one/components/common/formatters'
import { HeaderTitleCell } from '@one/components/common/HeaderTitleCell'
import { LieferantCell } from '@one/components/common/LieferantCell'
import { WarengruppeNameCell, WarengruppeNrCell } from '@one/components/common/WarengruppeCell'
import { LabelChipContainer } from '@one/components/Label/LabelChipContainer'
import { NeonKontextChips } from '@one/components/Label/NeonKontextChips'
import { useArtikelBetriebstypCache } from '@one/datacaches/useArtikelBetriebsTypeCache'
import { useArtikelLabelCache } from '@one/datacaches/useArtikelLabelCache'
import {
  ArtikelAboArtikelDisplayJson,
  ArtikelBetriebstyp,
  ArtikelDisplayJson,
  BetriebstypJson,
  LabelJson,
  SuchArtikelTyp
} from '@one/typings/apiTypings'
import { AppContext } from '@utils/ui/App/AppContext'
import { Column } from '@utils/ui/DataTable/DataTable'
import { computeColumns, DefaultColumnsProps } from '@utils/ui/DataTable/DataTableColumnUtils'
import { Medal } from '@utils/ui/fields/Medal'
import React, { useContext, useMemo } from 'react'
import { EnumFields, EnumInfo, useEnums } from '@utils/enums'
import { HkmEnum } from '@one/enums/HkmEnum'

export interface ArtikelDefaultColumnsProps<T = any> extends DefaultColumnsProps<T> {
  artikelColumnDecorator?: (row: T, body: React.ReactNode) => React.ReactNode
}

export const useArtikelColumns = <T = any,>(props: ArtikelDefaultColumnsProps<T> = {}) => {
  const { get: getLabel } = useArtikelLabelCache()
  const { get: getBetriebstyp } = useArtikelBetriebstypCache()
  const { isPIMModel, isGesellschafter } = useContext(AppContext)
  const { et } = useEnums()

  return useMemo(
    () =>
      getDefaultArtikelColumns({
        ...props,
        et,
        getLabel,
        getBetriebstyp,
        isPIMModel,
        isGesellschafter
      }),
    [props, et, getLabel, getBetriebstyp, isPIMModel, isGesellschafter]
  )
}

export interface getDefaultArtikelColumnsProps<T = any> extends ArtikelDefaultColumnsProps<T> {
  isGesellschafter?: boolean
  isPIMModel?: boolean
  getLabel: (id: number) => LabelJson
  et: (type: EnumInfo, id: string, field?: EnumFields) => string
  getBetriebstyp?: (value: ArtikelBetriebstyp) => BetriebstypJson
  artikelColumnDecorator?: (row: T, body: React.ReactNode) => React.ReactNode
}

const getDefaultArtikelColumns = <T extends ArtikelDisplayJson = ArtikelDisplayJson>(
  props: getDefaultArtikelColumnsProps<T>
) => {
  const importFehlerFormatter = (row) => {
    const nas = []
    if (row?.importFehlerData) {
      nas.push('Daten')
    }
    if (row?.importFehlerPreiseEk) {
      nas.push('EK-Preise')
    }
    if (row?.importFehlerPreiseVk) {
      nas.push('VK-Preise')
    }
    return nas
  }

  const decorator = props.artikelColumnDecorator as any

  const defaultColumns: Column<any>[] = [
    {
      field: 'importFehlerData',
      header: 'Import-Fehler',
      hideIfEmpty: true,
      valueGetter: importFehlerFormatter,
      align: 'center',
      body: (row) => {
        return row.importFehlerData || row.importFehlerPreiseEk || row.importFehlerPreiseVk
          ? importFehlerFormatter(row).map((medal) => (
              <Medal
                key={medal}
                text={medal}
                tooltip="Wegen Aktualisierungsfehler nicht aktuell"
                backgroundColor={orange[500]}
              />
            ))
          : null
      }
    },
    {
      field: 'hageNummer',
      header: 'hage-Nr.',
      sortable: true,
      decorator
    },
    {
      off: props.isPIMModel,
      field: 'btNummer',
      header: 'Artikel-Nr.',
      sortable: true,
      decorator
    },
    {
      field: 'ean',
      header: 'EAN',
      sortable: true,
      decorator,
      body: (row) => {
        const eanIgnoriert = row?.eanIgnoriert
        return (
          <div
            style={
              eanIgnoriert
                ? { fontStyle: 'italic', textDecoration: 'line-through', cursor: 'help' }
                : null
            }
            title={
              eanIgnoriert ? 'Die EAN ist einem Artikel einer anderen EAN-Gruppe zugeordnet' : null
            }
          >
            {row?.ean}
          </div>
        )
      }
    },
    {
      field: 'industrieArtikelNummer',
      header: <HeaderTitleCell title="IAN" subtitle="HL" />,
      headerTip: 'Industrieartikelnummer des Hauptlieferanten',
      sortable: true,
      decorator
    },
    {
      field: 'bez1',
      header: 'Bez1',
      sortable: true,
      decorator
    },
    {
      field: 'bez2',
      header: 'Bez2',
      sortable: true,
      decorator
    },
    {
      field: 'bez3',
      header: 'Bez3',
      sortable: true,
      decorator
    },
    {
      field: 'bez4',
      header: 'Bez4',
      sortable: true,
      decorator
    },
    {
      field: 'labels',
      header: 'Labels',
      off: !props.getLabel,
      sortable: true,
      valueGetter: (row) => row.labels?.map((id) => props.getLabel(id)?.kurz),
      body: (row) => <LabelChipContainer labels={row?.labels?.map(props.getLabel)} />,
      decorator
    },
    {
      field: 'minEinLager',
      header: 'LK',
      headerTip: 'Lagerkennzeichen',
      align: 'center',
      valueGetter: (row) => (row.minEinLager ? '+' : ''),
      decorator
      // off: props.isGesellschafter
    },
    {
      field: 'lagerME',
      header: 'LME',
      headerTip: 'Lagermengeneinheit',
      valueGetter: (row) => formatMengeneinheit(row.lagerME),
      decorator
    },
    {
      field: 'artikelArt',
      header: 'Artikelart',
      align: 'center',
      valueGetter: (row) =>
        row.pimArtikelArt
          ? props.et(HkmEnum.PimArtikelArt, row.pimArtikelArt)
          : (row.artikelArt?.kurz ?? row.artikelArt?.bez),
      body: (row) =>
        row.pimArtikelArt
          ? props.et(HkmEnum.PimArtikelArt, row.pimArtikelArt)
          : row.artikelArt && (
              <span style={row.artikelArt?.ignoriert ? { color: 'grey' } : null}>
                {row.artikelArt?.kurz ?? row.artikelArt?.bez}
              </span>
            ),
      tooltip: (row) => {
        const tooltip = row.artikelArt?.kurz ? row.artikelArt?.bez : null

        if (row.artikelArt?.ignoriert) {
          return `${tooltip} - ignoriert`
        }
        return tooltip
      },
      decorator
    },
    {
      field: 'sparte.nr',
      header: 'Sparte-Nr',
      align: 'right'
    },
    {
      field: 'sparte.bezeichnung',
      header: 'Sparte-Name'
    },
    {
      field: 'warengruppe.nummer',
      header: 'WG-Nr',
      align: 'right',
      body: (row) => row && <WarengruppeNrCell warengruppe={row.warengruppe} />
    },
    {
      field: 'warengruppe.name',
      header: 'WG-Name',
      body: (row) => row && <WarengruppeNameCell warengruppe={row.warengruppe} />
    },
    {
      // off: props.isPIMModel,
      field: 'hauptlieferant',
      header: 'Hauptlieferant',
      valueGetter: (row) => formatLieferant(row.hauptlieferant),
      body: (row) => <LieferantCell lieferant={row.hauptlieferant} asLink />,
      decorator
    },
    {
      off: props.isPIMModel,
      field: 'betriebsTyp',
      header: 'B-Typ',
      body: (row) => <ArtikelBetriebstypCell value={row.betriebsTyp} />,
      valueGetter: (row) => `${getArtikelBetriebstypLabel(props.getBetriebstyp(row.betriebsTyp))}`,
      decorator
    },
    {
      off: !props.isPIMModel,
      field: 'kontexte',
      header: 'Kontexte',
      body: (row) => <NeonKontextChips value={row.kontexte} />,
      valueGetter: (row) => row.kontexte?.map((l) => l.substring(2))
    },
    {
      field: 'eanIgnoriert',
      header: 'EAN ignoriert',
      hidden: true,
      decorator
    }
  ]

  return computeColumns<T>({ defaultColumns, ...props })
}

export const artikelColumnDecorator = (
  row: ArtikelAboArtikelDisplayJson,
  body: React.ReactNode
) => {
  if (row.typ === SuchArtikelTyp.DEZENTRAL) {
    return <>{body}</>
  }
  return (
    <div
      title="Aus Listung"
      style={{
        backgroundColor: 'rgb(3 136 69 / 80%)',
        borderRadius: '4px',
        color: 'white',
        paddingLeft: '4px',
        paddingRight: '4px'
      }}
    >
      {body}
    </div>
  )
}
