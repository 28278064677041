import { Grid } from '@mui/material'
import { LieferantCell } from '@one/components/common/LieferantCell'
import { formatLieferant, formatMengeneinheit } from '@one/components/common/formatters'
import { HkmEnum } from '@one/enums/HkmEnum'
import { ArtikelDatenpoolUebernahmeJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { Medal } from '@utils/ui/fields/Medal'
import { singleFromSet } from '@utils/utils'
import { useMemo } from 'react'
import { UebernahmeEigenlistungDetails } from './UebernahmeEigenlistungDetails'

export interface UebernahmeEigenlistungTableProps {
  eintraege?: ArtikelDatenpoolUebernahmeJson[]
  selected: Set<ArtikelDatenpoolUebernahmeJson>
  setSelected: React.Dispatch<React.SetStateAction<Set<ArtikelDatenpoolUebernahmeJson>>>
}

export const UebernahmeEigenlistungTable = ({
  eintraege,
  selected,
  setSelected
}: UebernahmeEigenlistungTableProps) => {
  const { et } = useEnums()

  const columns = useMemo<Column<ArtikelDatenpoolUebernahmeJson>[]>(
    () => [
      {
        field: 'status',
        header: 'Status',
        valueGetter: (row) => et(HkmEnum.ArtikelDatenpoolUebernahmeStatus, row.status),
        body: (row) => <Medal text={et(HkmEnum.ArtikelDatenpoolUebernahmeStatus, row.status)} />
      },
      {
        field: 'dpArtikel.hageNummer',
        header: 'hage-Nr.'
        // align: 'right'
      },
      {
        field: 'dpArtikel.gtin',
        header: 'EAN'
        // align: 'right'
      },
      {
        field: 'dpArtikel.lan',
        header: 'IAN'
        // align: 'right'
      },
      {
        field: 'dpArtikel.lieferantArtikelBez',
        header: 'Bezeichung'
      },
      {
        field: 'dpArtikel.werkslistenPreis',
        header: 'Werkslistenpreis',
        type: 'money2'
      },
      {
        field: 'dpArtikel.einkaufMeName',
        header: 'Einkauf-ME (DP)',
        headerTip: 'Einkaufsmengeneinheit Datenpool'
      },
      {
        field: 'dpArtikel.einkaufME',
        header: 'Einkauf-ME (ERP)',
        headerTip: 'Einkaufsmengeneinheit ERP',
        valueGetter: (row) => formatMengeneinheit(row.einkaufME)
      },
      {
        field: 'dpArtikel.lieferantWarenGruppe',
        header: 'Warengruppe'
      },
      {
        field: 'dpArtikel.lieferant',
        header: 'Lieferant',
        body: (row) => <LieferantCell lieferant={row.lieferant} asLink />,
        valueGetter: (row) => formatLieferant(row.lieferant)
      }
    ],
    [et]
  )

  const single = singleFromSet(selected)
  return (
    <Grid container direction="row" height="100%" spacing={1}>
      <Grid item flexGrow={1}>
        <DataTableCard
          name="EigenlistungUebersichtTable"
          height="100%"
          title="Eigenlistung"
          value={eintraege}
          columns={columns}
          selectCol
          selectMode="multi"
          selected={selected}
          onSelect={setSelected}
          dense
          initialOrderBy="name"
          filterMode="both"
          hover
          localStateName="EigenlistungUebersichtTable"
          emptyMessage={eintraege?.length === 0 ? 'Keine Artikel gefunden' : null}
          borderless
        />
      </Grid>
      {single && single.fehler ? (
        <Grid item lg={4} md={12} sm={12} xs={12}>
          <UebernahmeEigenlistungDetails artikel={single} />
        </Grid>
      ) : null}
    </Grid>
  )
}
