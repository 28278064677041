import { Grid } from '@mui/material'
import { PimArtikelVersionComplianceJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { StaticField } from '@utils/ui/fields/StaticField'

export interface ArtikelPimComplianceProps {
  compliance: PimArtikelVersionComplianceJson
}

export const ArtikelPimCompliance = ({ compliance }: ArtikelPimComplianceProps) => {
  return (
    <CardEx title="Compliance">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="Nummer" value={compliance.nummer} name="nummer" empty />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Gefahrgutfaktor"
            value={compliance.gefahrgutfaktor}
            name="gefahrgutfaktor"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Gefahrgut Menge"
            value={compliance.gefahrgutMenge}
            name="gefahrgutMenge"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Gefahrgut Menge-Volumen"
            value={compliance.gefahrgutMengeVolumen}
            name="gefahrgutMengeVolumen"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Tunnelbeschränkungscode"
            value={compliance.tunnelbeschraenkungscode}
            name="tunnelbeschraenkungscode"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Gefahrgut Verpackungsgruppe"
            value={compliance.gefahrgutVerpackungsgruppe}
            name="gefahrgutVerpackungsgruppe"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Gefahrgut UN-Nummer"
            value={compliance.gefahrgutUNNummer}
            name="gefahrgutUNNummer"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Wassergefährdungsklasse"
            value={compliance.wassergefaehrdungsklasse}
            name="wassergefaehrdungsklasse"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="Signalwort" value={compliance.signalwort} name="signalwort" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Energieeffizienzklasse"
            value={compliance.energieeffizienzklasse}
            name="energieeffizienzklasse"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Gefahrgut ADR-Nummer"
            value={compliance.gefahrgutADRNummer}
            name="gefahrgutADRNummer"
            empty
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Inverkehrbringer Name"
            value={compliance.inverkehrbringerName}
            name="inverkehrbringerName"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Inverkehrbringer Straße/Postfach"
            value={compliance.inverkehrbringerStrassePostfach}
            name="inverkehrbringerStrassePostfach"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Inverkehrbringer Hausnummer/Postfachnummer"
            value={compliance.inverkehrbringerHausnummerPostfachnummer}
            name="inverkehrbringerHausnummerPostfachnummer"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Inverkehrbringer PLZ"
            value={compliance.inverkehrbringerPLZ}
            name="inverkehrbringerPLZ"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Inverkehrbringer Land"
            value={compliance.inverkehrbringerLand}
            name="inverkehrbringerLand"
            empty
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="Scip-Nummer" value={compliance.scipNummer} name="scipNummer" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Gefahrnummer"
            value={compliance.gefahrnummer}
            name="gefahrnummer"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Nettoexplosivstoffmasse"
            value={compliance.nettoexplosivstoffmasse}
            name="nettoexplosivstoffmasse"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="ADR Klasse" value={compliance.adrKlasse} name="adrKlasse" empty />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Gefahrzettel"
            value={compliance.gefahrzettel}
            name="gefahrzettel"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Lagerklasse"
            value={compliance.lagerklasse}
            name="lagerklasse"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Produktidentifikatoren"
            value={compliance.produktidentifikatoren}
            name="produktidentifikatoren"
            empty
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="CE Kennzeichen"
            value={compliance.ceKennzeichen}
            name="ceKennzeichen"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Netzspannung"
            value={compliance.netzspannung}
            name="netzspannung"
            empty
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="EnVKV Modellbezeichnung"
            value={compliance.enVKVModellbezeichnung}
            name="enVKVModellbezeichnung"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="EnVKV-Skala Energieeffizienzklasse"
            value={compliance.enVKVSkalaEnergieeffizienzklasse}
            name="enVKVSkalaEnergieeffizienzklasse"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Gefahrgut Sondervorschrift"
            value={compliance.gefahrgutSondervorschrift}
            name="gefahrgutSondervorschrift"
            empty
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Prod-SG Herstelleradresse"
            value={compliance.prodSGHerstelleradresse}
            name="prodSGHerstelleradresse"
            empty
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Umweltgefährdungsklasse"
            value={compliance.umweltgefaehrdungsklasse}
            name="umweltgefaehrdungsklasse"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Gefahrstoffeinstufung"
            value={compliance.gefahrstoffeinstufung}
            name="gefahrstoffeinstufung"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Zulaessige Gesamtmenge je Beförderungseinheit"
            value={compliance.zulaessigeGesamtmengeJeBefoerderungseinheit}
            name="zulaessigeGesamtmengeJeBefoerderungseinheit"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Meldepflichtiger Ausgangstoff maximale Abgabemenge"
            value={compliance.meldepflichtigerAusgangstoffMaximaleAbgabemenge}
            name="meldepflichtigerAusgangstoffMaximaleAbgabemenge"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Meldepflichtiger Ausgangstoff Mengeneinheit"
            value={compliance.meldepflichtigerAusgangstoffMengeneinheit}
            name="meldepflichtigerAusgangstoffMengeneinheit"
            empty
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Textilkomponente Bezeichnung"
            value={compliance.textilkomponenteBezeichnung}
            name="textilkomponenteBezeichnung"
            empty
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Textilkomponente Anteil"
            value={compliance.textilkomponenteAnteil}
            name="textilkomponenteAnteil"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="ADR Punktzahl" value={compliance.adrPunktzahl} name="adrPunktzahl" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Beförderungskategorie"
            value={compliance.befoerderungskategorie}
            name="befoerderungskategorie"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Zusätzliche Angaben Beförderungspapiere"
            value={compliance.zusaetzlicheAngabenBefoerderungspapiere}
            name="zusaetzlicheAngabenBefoerderungspapiere"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Gefahrzettel Nebengefahren"
            value={compliance.gefahrzettelNebengefahren.join(', ')}
            name="gefahrzettelNebengefahren"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Abfallschlüssel"
            value={compliance.abfallschluessel.join(', ')}
            name="abfallschluessel"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Gefahrenhinweis"
            value={compliance.gefahrenhinweis.join(', ')}
            name="gefahrenhinweis"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Gefahrenpiktogramm"
            value={compliance.gefahrenpiktogramm.join(', ')}
            name="gefahrenpiktogramm"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Klassifizierungscode ADR"
            value={compliance.klassifizierungscodeADR.join(', ')}
            name="klassifizierungscodeADR"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Konsistenz"
            value={compliance.konsistenz.join(', ')}
            name="konsistenz"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="NetzanschlussTyp"
            value={compliance.netzanschlussTyp.join(', ')}
            name="netzanschlussTyp"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Prod-SG Produktkennzeichnung"
            value={compliance.prodSGProduktkennzeichnung.join(', ')}
            name="prodSGProduktkennzeichnung"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Prod-SG Warnhinweis"
            value={compliance.prodSGWarnhinweis.join(', ')}
            name="prodSGWarnhinweis"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Sicherheitshinweis"
            value={compliance.sicherheitshinweis.join(', ')}
            name="sicherheitshinweis"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Verpackungsart"
            value={compliance.verpackungsart.join(', ')}
            name="verpackungsart"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="WeeeKategorie"
            value={compliance.weeeKategorie.join(', ')}
            name="weeeKategorie"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="MHD-Relevant"
            checked={compliance.mhdRelevant}
            name="mhdRelevant"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Gefahrstoff"
            checked={compliance.gefahrstoff}
            name="gefahrstoff"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Gefahrgut"
            checked={compliance.gefahrgut}
            name="gefahrgut"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Biozid"
            checked={compliance.biozid}
            name="biozid"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Lithiumhaltige Batterie"
            checked={compliance.lithiumhaltigeBatterie}
            name="lithiumhaltigeBatterie"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Frostgefährdet"
            checked={compliance.frostgefaehrdet}
            name="frostgefaehrdet"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Verpackung: Mehrwegkennzeichnung"
            checked={compliance.verpackungMehrwegkennzeichnung}
            name="verpackungMehrwegkennzeichnung"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Gefahrenkennzeichnungsetikett"
            checked={compliance.gefahrenkennzeichnungsetikett}
            name="gefahrenkennzeichnungsetikett"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Besonders besorgniserregender Stoff"
            checked={compliance.besondersBesorgniserregenderStoff}
            name="besondersBesorgniserregenderStoff"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Limited Quantity"
            checked={compliance.limitedQuantity}
            name="limitedQuantity"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Weee Relevanz"
            checked={compliance.weeeRelevanz}
            name="weeeRelevanz"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="CE Relevanz"
            checked={compliance.ceRelevanz}
            name="ceRelevanz"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="EnVkv Relevanz"
            checked={compliance.enVkvRelevanz}
            name="enVkvRelevanz"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Ecodesign 2022"
            checked={compliance.ecodesign2022}
            name="ecodesign2022"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Prod-SG Relevanz"
            checked={compliance.prodSGRelevanz}
            name="prodSGRelevanz"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Meldepflichtiger Ausgangstoff"
            checked={compliance.meldepflichtigerAusgangstoff}
            name="meldepflichtigerAusgangstoff"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Beschränkter Ausgangsstoff"
            checked={compliance.beschraenkterAusgangsstoff}
            name="beschraenkterAusgangsstoff"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Nationale CO2-Abgabe AT-Relevanz"
            checked={compliance.nationaleCO2AbgabeATRelevanz}
            name="nationaleCO2AbgabeATRelevanz"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="EuNorm Relevanz"
            checked={compliance.euNormRelevanz}
            name="euNormRelevanz"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Druckgasverordnung Relervanz"
            checked={compliance.druckgasverordnungRelervanz}
            name="druckgasverordnungRelervanz"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Alterspruefung Relevanz"
            checked={compliance.alterspruefungRelevanz}
            name="alterspruefungRelevanz"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Pflanzenschutzmittel Relevanz"
            checked={compliance.pflanzenschutzmittelRelevanz}
            name="pflanzenschutzmittelRelevanz"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Textil KVO-Relevanz"
            checked={compliance.textilKvoRelevanz}
            name="textilKvoRelevanz"
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
