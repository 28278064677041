import { Grid } from '@mui/material'
import { PimArtikelJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { DateField } from '@utils/ui/fields/DateField'
import { TextField } from '@utils/ui/fields/TextField'
import React from 'react'

export interface PimArtikelSperrenProps {
  pimArtikel: PimArtikelJson
  onChange: ValueChangeFn<SeArtikelJson>
  errors: any
}

export const PimArtikelSperren = ({ pimArtikel, onChange, errors }: PimArtikelSperrenProps) => {
  return (
    <CardEx title="VK-Sperre">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <Checkbox
            label="VK-Sperre"
            name="pimArtikel.version.vkSperre"
            checked={pimArtikel.version.vkSperre}
            onChange={onChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
          <DateField
            label="VK-Sperre ab"
            name="pimArtikel.versionKontext.vkSperreAb"
            value={pimArtikel.versionKontext?.vkSperreAb}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
          <DateField
            label="VK-Sperre bis"
            name="pimArtikel.versionKontext.vkSperreBis"
            value={pimArtikel.versionKontext?.vkSperreBis}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={1}>
          <TextField
            label="VK-Sperre Grund"
            name="pimArtikel.versionKontext.vkSperreGrund"
            value={pimArtikel.versionKontext?.vkSperreGrund}
            onChange={onChange}
            fullWidth
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelSperrenMemo = React.memo(PimArtikelSperren)
