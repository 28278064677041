import { MessageID } from '@one/MessageIDs'
import {
  AboArtikelEanGruppeStatus,
  ArtikelBetriebstyp,
  ArtikelSelektionBearbeitenJson,
  ArtikelSelektionJson,
  EanZuordnung,
  MessageJson,
  SparteDisplayJson,
  WarengruppeDisplayJson
} from '@one/typings/apiTypings'
import { ModelAction } from '@utils/modelmgr'
import { getValueComparator, stableSort } from '@utils/ui/DataTable/DataTableUtils'
import { asNumber } from '@utils/utils'
import {
  ArtikelAbonniertStatusEx,
  ArtikelSelektionEdit,
  ArtikelSelektionEintragChangeEdit,
  ArtikelSelektionEintragEdit,
  buildArtikelAbonniertStatusChange,
  buildArtikelAbonniertStatusEx
} from './ArtikelSelektionTypes'

export const getStatusSort = (type: ArtikelAbonniertStatusEx) => {
  switch (type) {
    case ArtikelAbonniertStatusEx.NEU:
      return 1
    case ArtikelAbonniertStatusEx.GEAENDERT:
      return 2
    case ArtikelAbonniertStatusEx.KONFLIKT_DZ:
      return 3
    case ArtikelAbonniertStatusEx.UNTERBROCHEN:
      return 4
    case ArtikelAbonniertStatusEx.UNBESTIMMT:
      return 5
    case ArtikelAbonniertStatusEx.AUSGELISTET:
      return 6
    case ArtikelAbonniertStatusEx.AUSGESCHL:
      return 7
    case ArtikelAbonniertStatusEx.EINGESCHL:
    case ArtikelAbonniertStatusEx.BESTAETIGT:
      return 8
    default:
      return null
  }
}

const setErzeugePreisVorgang = (
  state: ArtikelSelektionEdit,
  checked: boolean
): ArtikelSelektionEdit => {
  return {
    ...state,
    ekPreislisteErzeugen: !!checked
  }
}

const setEintragStatus = (
  state: ArtikelSelektionEdit,
  selected: Set<ArtikelSelektionEintragEdit>,
  statusEx: ArtikelAbonniertStatusEx
) => {
  const next = new Map(state.changesMap)
  selected.forEach((row) => {
    const chg = next.get(row.id)
    const hsId = chg?.hauptSelektionId || row.hauptSelektionId
    if (
      (!row.artikel.archiviert ||
        statusEx === ArtikelAbonniertStatusEx.AUSGESCHL ||
        statusEx === row.statusEx) &&
      (state.id === hsId || hsId == null || row.statusEx === ArtikelAbonniertStatusEx.AUSGESCHL)
    ) {
      const statusChanged = statusEx !== row.statusEx
      const nxt = {
        ...chg,
        statusEx,
        hauptSelektionId: statusChanged ? state.id : chg.hauptSelektionIdOld,
        uebernehmeBetriebsTyp: statusChanged
          ? statusEx === ArtikelAbonniertStatusEx.EINGESCHL &&
            (chg.uebernehmeBetriebsTypSwitch ||
              (row.betriebsTypErmittelt != null &&
                row.artikel.betriebsTyp === ArtikelBetriebstyp.UNBEKANNT))
          : false,
        uebernehmeBetriebsTypSwitch: statusChanged ? chg.uebernehmeBetriebsTypSwitch : false
      }
      next.set(row.id, nxt)
    }
  })
  return {
    ...state,
    changesMap: next
  }
}

const evalNextStatus = (
  status: ArtikelAbonniertStatusEx,
  oldStatus: ArtikelAbonniertStatusEx,
  erpAusgelistet: boolean
): ArtikelAbonniertStatusEx => {
  if (oldStatus === ArtikelAbonniertStatusEx.AUSLISTUNG) {
    if (status != ArtikelAbonniertStatusEx.BESTAETIGT) {
      return ArtikelAbonniertStatusEx.BESTAETIGT
    }
    return oldStatus
  }

  if (erpAusgelistet) {
    if (oldStatus == status) {
      return ArtikelAbonniertStatusEx.AUSGESCHL
    }
    return oldStatus
  }

  const aenderung =
    oldStatus === ArtikelAbonniertStatusEx.GEAENDERT ||
    oldStatus === ArtikelAbonniertStatusEx.KONFLIKT_DZ

  switch (status) {
    case ArtikelAbonniertStatusEx.BESTAETIGT:
    case ArtikelAbonniertStatusEx.EINGESCHL:
      return ArtikelAbonniertStatusEx.AUSGESCHL

    case ArtikelAbonniertStatusEx.AUSGESCHL:
      if (oldStatus == ArtikelAbonniertStatusEx.AUSGESCHL) {
        return aenderung ? ArtikelAbonniertStatusEx.BESTAETIGT : ArtikelAbonniertStatusEx.EINGESCHL
      }
      return oldStatus

    default:
      return aenderung ? ArtikelAbonniertStatusEx.BESTAETIGT : ArtikelAbonniertStatusEx.EINGESCHL
  }
}

const toggleEintragStatus = (
  state: ArtikelSelektionEdit,
  selected: Set<ArtikelSelektionEintragEdit>,
  row: ArtikelSelektionEintragEdit
) => {
  if (row == null) {
    return state
  }
  const next = evalNextStatus(
    state.changesMap.get(row.id).statusEx,
    row.statusEx,
    row.erpAusgelistet
  )

  if (selected.has(row)) {
    return setEintragStatus(state, selected, next)
  }
  return setEintragStatus(state, new Set([row]), next)
}

const setMessageAction = (
  state: ArtikelSelektionEdit,
  eintragId: number,
  message: MessageJson,
  checked: boolean,
  art: EanZuordnung,
  name?: string
) => {
  const next = new Map(state.changesMap)

  const chg = { ...next.get(eintragId) }

  switch (message?.messageId?.id) {
    case MessageID.IDE_DZ_EAN_KONFLIKT:
      chg.eanZuordnung = checked ? art : null
      if (checked) {
        chg.statusEx = ArtikelAbonniertStatusEx.EINGESCHL
      } else {
        chg.statusEx = chg.statusExOld
      }
      break

    case MessageID.IDE_DZ_BT_ANDERER_ERMITTELT:
      chg.uebernehmeBetriebsTypSwitch = checked ? true : false
      if (checked) {
        chg.statusEx = ArtikelAbonniertStatusEx.EINGESCHL
      } else {
        chg.statusEx = chg.statusExOld
      }
      break

    case MessageID.IDE_DZ_MEHRERE_WURZEL_MES:
      chg[name ?? 'ergaenzeMEs'] = checked ? true : false
      if (chg['ergaenzeMEs']) {
        chg.statusEx = ArtikelAbonniertStatusEx.EINGESCHL
      } else {
        chg.statusEx = chg.statusExOld
      }
      break

    case MessageID.IDE_DZ_ABGLEICH_ANDERE_EANGRUPPE:
      chg.uebernehmeEanSwitch = checked ? true : false
      if (checked) {
        chg.verbindungMitSeArtikelId = asNumber(message.hint)
        chg.verbindungAlsNeuerArtikel = false
        chg.statusEx = ArtikelAbonniertStatusEx.EINGESCHL
      } else {
        chg.verbindungMitSeArtikelId = null
        chg.verbindungAlsNeuerArtikel = false
        chg.statusEx = chg.statusExOld
      }
      break

    case MessageID.IDE_ZE_ABGLEICH_MEHRDEUTIG:
      chg[name ?? 'uebernehmeMehrdeutigAusZE'] = checked ? true : false
      if (chg['uebernehmeMehrdeutigAusZE']) {
        chg.statusEx = ArtikelAbonniertStatusEx.EINGESCHL
      } else {
        chg.statusEx = chg.statusExOld
      }
      break

    default:
      return state
  }

  next.set(eintragId, chg)

  return {
    ...state,
    changesMap: next
  }
}

const setArSST = (
  state: ArtikelSelektionEdit,
  arsstId: number,
  row: ArtikelSelektionEintragEdit
) => {
  const next = new Map(state.changesMap)

  const chg = { ...next.get(row.id) }
  chg.arsstId = arsstId
  next.set(row.id, chg)

  return {
    ...state,
    changesMap: next
  }
}

const eintragUebernehmen = (
  state: ArtikelSelektionEdit,
  selected: Set<ArtikelSelektionEintragEdit>
) => {
  const next = new Map(state.changesMap)
  selected.forEach((row) => {
    if (!row.artikel.archiviert && state.id !== row.hauptSelektionId) {
      let chg = next.get(row.id)
      const nxt = {
        ...chg,
        hauptSelektionId: state.id
      }
      next.set(row.id, nxt)
    }
  })
  return {
    ...state,
    changesMap: next
  }
}

export const isArtikelSelektionEintragChanged = (c: ArtikelSelektionEintragChangeEdit) => {
  return (
    c != null &&
    (c.statusExOld !== c.statusEx ||
      c.eanZuordnung != null ||
      c.uebernehmeBetriebsTyp !== false ||
      c.uebernehmeBetriebsTypSwitch !== false ||
      c.arsstId !== c.arsstIdOld ||
      c.hauptSelektionId !== c.hauptSelektionIdOld ||
      c.eanGruppeStatus !== c.eanGruppeStatusOld ||
      c.verbindungAlsNeuerArtikel != null ||
      c.verbindungMitSeArtikelId != null ||
      c.seWarengruppeId != null ||
      c.seSparteId != null)
  )
}

const setEanGruppeStatus = (
  state: ArtikelSelektionEdit,
  selected: Set<ArtikelSelektionEintragEdit>,
  status: AboArtikelEanGruppeStatus
) => {
  const next = new Map(state.changesMap)
  selected.forEach((row) => {
    let chg = next.get(row.id)
    const value = chg?.eanGruppeStatus || row.eanGruppeStatus
    if (!row.artikel.archiviert && value !== status) {
      const nxt = {
        ...chg,
        eanGruppeStatus: status
      } as ArtikelSelektionEintragChangeEdit
      next.set(row.id, nxt)
    }
  })
  return {
    ...state,
    changesMap: next
  }
}

const resetVerbindungen = (state: ArtikelSelektionEdit, selected: ArtikelSelektionEintragEdit) => {
  const next = new Map(state.changesMap)
  let chg = next.get(selected.id)
  if (
    chg == null &&
    chg.verbindungAlsNeuerArtikel == null &&
    chg.verbindungMitSeArtikelId == null
  ) {
    return state
  }
  const nxt = {
    ...chg,
    verbindungAlsNeuerArtikel: null,
    verbindungMitSeArtikelId: null
  } as ArtikelSelektionEintragChangeEdit
  next.set(selected.id, nxt)
  return {
    ...state,
    changesMap: next
  }
}

const verbindungAlsNeuerArtikel = (
  state: ArtikelSelektionEdit,
  selected: ArtikelSelektionEintragEdit
) => {
  const next = new Map(state.changesMap)
  let chg = next.get(selected.id)
  const nxt = {
    ...chg,
    verbindungAlsNeuerArtikel: true,
    verbindungMitSeArtikelId: null
  } as ArtikelSelektionEintragChangeEdit
  next.set(selected.id, nxt)
  return {
    ...state,
    changesMap: next
  }
}

const verbindungMitArtikel = (
  state: ArtikelSelektionEdit,
  selected: ArtikelSelektionEintragEdit,
  seArtikelId: number
) => {
  const next = new Map(state.changesMap)
  let chg = next.get(selected.id)
  const nxt = {
    ...chg,
    verbindungAlsNeuerArtikel: null,
    verbindungMitSeArtikelId: seArtikelId
  } as ArtikelSelektionEintragChangeEdit
  next.set(selected.id, nxt)
  return {
    ...state,
    changesMap: next
  }
}

const setDefaults = (
  state: ArtikelSelektionEdit,
  selected: Set<ArtikelSelektionEintragEdit>,
  wg: WarengruppeDisplayJson,
  sparte: SparteDisplayJson
): ArtikelSelektionEdit => {
  const next = new Map(state.changesMap)
  selected.forEach((row) => {
    const chg = next.get(row.id)
    next.set(row.id, {
      ...chg,
      seWarengruppeId: wg?.seId,
      seSparteId: sparte?.id
    })
  })
  return {
    ...state,
    ergebnisse: state.ergebnisse.map((e) => {
      if (selected.has(e)) {
        return {
          ...e,
          artikel: {
            ...e.artikel,
            warengruppe: wg,
            sparte: sparte
          }
        }
      }
      return e
    }),
    changesMap: next
  }
}

const setWarnungGeprueft = (
  state: ArtikelSelektionEdit,
  selected: Set<ArtikelSelektionEintragEdit>,
  toggle: boolean
) => {
  const next = new Map(state.changesMap)
  selected.forEach((row) => {
    if (row.attributWarnungen) {
      const chg = next.get(row.id)
      next.set(row.id, {
        ...chg,
        attributWarnungenGeprueft: toggle
      })
    }
  })
  return {
    ...state,
    changesMap: next
  }
}

export const ArtikelAboSelektionUsecase = {
  SETERZEUGEPREISVORGANG: 'SETERZEUGEPREISVORGANG',
  SETEINTRAGSTATUS: 'SETEINTRAGSTATUS',
  TOGGLEEINTRAGSTATUS: 'TOGGLEEINTRAGSTATUS',
  SETMESSAGEACTION: 'SETMESSAGEACTION',
  SETARSST: 'SETARSST',
  EINTRAGUEBERNEHMEN: 'EINTRAGUEBERNEHMEN',
  SETEANGRUPPESTATUS: 'SETEANGRUPPESTATUS',
  RESETVERBINDUNG: 'RESETVERBINDUNG',
  VERBINDUNGALSNEUERARTIKEL: 'VERBINDUNGALSNEUERARTIKEL',
  VERBINDUNGMITARTIKEL: 'VERBINDUNGMITARTIKEL',
  SETDEFAULTS: 'SETDEFAULTS',
  SETWARNUNGGEPRUEFT: 'SETWARNUNGGEPRUEFT',

  reducer: (state: ArtikelSelektionEdit, action: ModelAction) => {
    switch (action.type) {
      case ArtikelAboSelektionUsecase.SETERZEUGEPREISVORGANG:
        return setErzeugePreisVorgang(state, action.value)
      case ArtikelAboSelektionUsecase.SETEINTRAGSTATUS:
        return setEintragStatus(state, action.selected, action.status)
      case ArtikelAboSelektionUsecase.TOGGLEEINTRAGSTATUS:
        return toggleEintragStatus(state, action.selected, action.row)
      case ArtikelAboSelektionUsecase.SETMESSAGEACTION:
        return setMessageAction(
          state,
          action.eintragId,
          action.message,
          action.checked,
          action.mode,
          action.name
        )
      case ArtikelAboSelektionUsecase.SETARSST:
        return setArSST(state, action.value, action.row)
      case ArtikelAboSelektionUsecase.EINTRAGUEBERNEHMEN:
        return eintragUebernehmen(state, action.selected)
      case ArtikelAboSelektionUsecase.SETEANGRUPPESTATUS:
        return setEanGruppeStatus(state, action.selected, action.status)
      case ArtikelAboSelektionUsecase.RESETVERBINDUNG:
        return resetVerbindungen(state, action.selected)
      case ArtikelAboSelektionUsecase.VERBINDUNGALSNEUERARTIKEL:
        return verbindungAlsNeuerArtikel(state, action.selected)
      case ArtikelAboSelektionUsecase.VERBINDUNGMITARTIKEL:
        return verbindungMitArtikel(state, action.selected, action.seArtikelId)
      case ArtikelAboSelektionUsecase.SETDEFAULTS:
        return setDefaults(state, action.selected, action.warengruppe, action.sparte)
      case ArtikelAboSelektionUsecase.SETWARNUNGGEPRUEFT:
        return setWarnungGeprueft(state, action.selected, action.toggle)
      default:
        return state
    }
  },

  validate: (model: any) => {
    const validationErrors = {} as any
    if (!model.name) {
      validationErrors.name = 'Name ist ein Pflichtfeld'
    }
    return validationErrors
  },

  editMutator: (
    state: ArtikelSelektionJson,
    last: ArtikelSelektionJson,
    envelope: ArtikelSelektionBearbeitenJson
  ) => {
    const col1 = getValueComparator('asc', (o: ArtikelSelektionEintragEdit) =>
      getStatusSort(o.statusEx)
    )
    const col2 = getValueComparator('asc', (o: ArtikelSelektionEintragEdit) => o.artikel?.btNummer)
    const comp = (a: ArtikelSelektionEintragEdit, b: ArtikelSelektionEintragEdit): number => {
      const r1 = col1(a, b)
      if (r1 != 0) {
        return r1
      }
      return col2(a, b)
    }

    const aggr = state.ergebnisse.reduce((a, v) => {
      const copy = a
      v.aenderungenIds?.forEach((v1) => {
        copy[v1] = a && a[v1] != null ? a[v1] + 1 : 1
      })
      return copy
    }, {})

    const aenderungenIdx = new Map<number, number>(
      Object.keys(aggr).map((k) => [asNumber(k), aggr[k]])
    )

    const zubestaetigenHints: Map<number, String> = envelope.changeWarningHints
      ? new Map(
          Object.keys(envelope.changeWarningHints).map((k) => [
            asNumber(k),
            envelope.changeWarningHints[k]
          ])
        )
      : new Map()

    let ergebnisse = state.ergebnisse.map((e) => ({
      ...e,
      statusEx: buildArtikelAbonniertStatusEx(e.status, e.aenderung?.aenderungsTyp),
      attributWarnungen: e.aenderungenIds?.find((v) => zubestaetigenHints.get(v) != null) != null
    }))

    ergebnisse = stableSort(ergebnisse, comp)

    return {
      ...state,
      aenderungenIdx,
      zubestaetigenHints,
      ergebnisse,
      artikelSelektionenMap: new Map(envelope.selektionen.map((s) => [s.id, s])),
      changesMap: new Map(
        ergebnisse.map((e) => [
          e.id,
          {
            id: e.id,
            statusEx: e.statusEx,
            statusExOld: e.statusEx,
            hauptSelektionId: e.hauptSelektionId,
            hauptSelektionIdOld: e.hauptSelektionId,
            arsstId: e.arsstId,
            arsstIdOld: e.arsstId,
            eanZuordnung: null,
            uebernehmeBetriebsTypSwitch: false,
            uebernehmeBetriebsTyp: false,
            eanGruppeStatus: e.eanGruppeStatus,
            eanGruppeStatusOld: e.eanGruppeStatus
          } as ArtikelSelektionEintragChangeEdit
        ])
      )
    } as ArtikelSelektionEdit
  },

  saveMutator: (state: ArtikelSelektionEdit) => {
    return {
      id: state.id,
      name: state.name,
      version: state.version,
      ergebnisseChanges: Array.from(state.changesMap.values())
        .filter(isArtikelSelektionEintragChanged)
        .map((e) => ({
          id: e.id,
          status:
            e.statusEx !== e.statusExOld ? buildArtikelAbonniertStatusChange(e.statusEx) : null,
          arsstId: e.arsstId,
          hauptSelektionId: e.hauptSelektionId,
          eanGruppeStatus: e.eanGruppeStatus !== e.eanGruppeStatusOld ? e.eanGruppeStatus : null,
          eanZuordnung: e.eanZuordnung,
          ergaenzeMEs: e.ergaenzeMEs,
          verbergeMEs: e.verbergeMEs,
          uebernehmeMehrdeutigAusZE: e.uebernehmeMehrdeutigAusZE,
          uebernehmeBetriebsTyp: e.uebernehmeBetriebsTyp,
          changeEanGruppenStatus: e.changeEanGruppenStatus,
          verbindungAlsNeuerArtikel: e.verbindungAlsNeuerArtikel,
          verbindungMitSeArtikelId: e.verbindungMitSeArtikelId,
          attributWarnungenGeprueft: e.attributWarnungenGeprueft,
          seWarengruppeId: e.seWarengruppeId,
          seSparteId: e.seSparteId
        })),
      ekPreislisteErzeugen: state.ekPreislisteErzeugen,
      aenderungenAutomatischUebernehmen: state.aenderungenAutomatischUebernehmen,
      preisaenderungenGetrennt: state.preisaenderungenGetrennt,
      arsstId: state.arsstId,
      ausgelisteteArtikelAnbieten: state.ausgelisteteArtikelAnbieten
    } as ArtikelSelektionJson
  }
}
