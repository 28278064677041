import { api } from '@one/api'
import { EkKonditionenDefinitionEx } from '@one/components/EkPreis/EKPreisPflege/model/EkPreiseTypes'
import { enrichKondiDefinition } from '@one/components/EkPreis/EKPreisPflege/model/EkPreiseUsecase'
import { MessageID } from '@one/MessageIDs'
import {
  ActionInfoJson,
  ArtikelBetriebstyp,
  EkKonditionenRabattgruppenViewJson,
  EkPreiseMasseBearbeitenEintragJson,
  EkPreiseMasseBearbeitenKopfJson,
  NeonKontext
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { useModelMgr } from '@utils/modelmgr'
import { arrayMerge, buildMap, compareStrings, safeArray } from '@utils/utils'
import { useCallback, useEffect, useRef } from 'react'
import isEqual from 'react-fast-compare'
import { PreisEbeneDisplayJson } from './../../../../typings/apiTypings'

const buildKondi = (
  i: number,
  kondiDefinition: EkKonditionenDefinitionEx,
  rabatt: string,
  typ: 'abs' | 'proz'
) => {
  const name = `${rabatt}${i}`
  return {
    field: name,
    typ,
    kurz: `${kondiDefinition[`${name}Label`]}`,
    bez: kondiDefinition[`${name}Tip`],
    aktiv: !!kondiDefinition[name]
  } as EkKondiRabattField
}

const kondiOrder = [
  'zuschlAbs1',
  'zuschlAbs2',
  'rabattProz1',
  'rabattProz2',
  'rabattProz3',
  'rabattProz4',
  'rabattProz5',
  'zuschlAbs3',
  'zuschlAbs4',
  'zuschlAbs5'
]

const empty = {
  kondiDefinition: {},
  artikelListe: []
}

const empty2 = {
  artikelListe: []
}

export interface EkKondiRabattField {
  field: string
  typ: 'abs' | 'proz'
  kurz: string
  bez: any
  aktiv: boolean
}

export interface EkPreiseMasseBearbeitenEintragExJson extends EkPreiseMasseBearbeitenEintragJson {
  preisebene?: PreisEbeneDisplayJson
}

export interface EkPreisMassenPflegeModel {
  id?: number
  name?: string
  bemerkung?: string
  preisEbeneId?: number
  kontext?: NeonKontext
  betriebstyp?: ArtikelBetriebstyp
  artikelCount?: number
  preisEbenen?: PreisEbeneDisplayJson[]
  ekRabattgruppen?: EkKonditionenRabattgruppenViewJson[]
  kondiDefinition?: EkKonditionenDefinitionEx
  kondiRabattFields?: EkKondiRabattField[]
  actionAbschliessen?: ActionInfoJson
  actionFreigeben?: ActionInfoJson
  freigegeben?: boolean
  freigegebenUm?: string
  artikelListe: EkPreiseMasseBearbeitenEintragExJson[]
  preisEbenenMap: Map<number, PreisEbeneDisplayJson>
}

const compareNames = (
  a: EkKonditionenRabattgruppenViewJson,
  b: EkKonditionenRabattgruppenViewJson
) => compareStrings(a?.kopf?.name, b?.kopf?.name)

export const useEkPreisMassenPflegeModel = (preislisteId: number) => {
  const [apiCall, apiBusy] = useApiCaller(api)
  const preisEbeneIdRef = useRef(null)

  const { model, errors, uiLock, wait, updModel, reload, load } = useModelMgr<
    EkPreiseMasseBearbeitenKopfJson,
    EkPreisMassenPflegeModel
  >({
    noid: true,
    api,
    title: 'Öffnen Massenpflege',
    rest: 'ekpreisliste/openmassenpflege',
    restEdit: '',
    restps: {
      id: preislisteId
    },
    init: empty,
    retryMessageId: MessageID.IDE_ARTIKEL_PREISE_MUESSEN_NOCH_GEHOLT_WERDEN,
    editMutator: (data: EkPreiseMasseBearbeitenKopfJson) => {
      const ekRabattgruppen = safeArray(data.ekRabattgruppen).sort(compareNames)
      const preisEbenen = safeArray(data.preisEbenen).sort((a, b) => compareStrings(a.nr, b.nr)) // a.nr - b.nr)
      const preisEbenenMap = buildMap(preisEbenen, (b) => b.id)
      // const preisEbene0Id = (preisEbenen.find((pe) => pe.defaultStandort) || {}).id
      const kondiDefinition = enrichKondiDefinition(data.kondiDefinition, data.allianzModus)
      const artikelListe = safeArray(data.artikelListe) as EkPreiseMasseBearbeitenEintragExJson[]
      artikelListe.forEach(
        (a) =>
          (a.preisebene =
            a.preisEbeneId == null
              ? preisEbenenMap.get(preisEbeneIdRef.current)
              : preisEbenenMap.get(a.preisEbeneId))
      )
      const idx = [1, 2, 3, 4, 5]
      const rabattProz = idx.map((i) => buildKondi(i, kondiDefinition, 'rabattProz', 'proz'))
      const zuschlAbs = idx.map((i) => buildKondi(i, kondiDefinition, 'zuschlAbs', 'abs'))
      const kondiRabattFields = rabattProz
        .concat(zuschlAbs)
        .sort((a, b) => kondiOrder.indexOf(a.field) - kondiOrder.indexOf(b.field))

      const usedPEs = new Set(data.usedPreisEbenenIds)
      const preisEbenenEx = (preisEbenen || []).map((s) => ({ ...s, hatPreis: usedPEs.has(s.id) }))

      return {
        id: preislisteId,
        name: data.name,
        bemerkung: data.bemerkung,
        preisEbeneId: preisEbeneIdRef.current,
        kontext: data.kontext,
        betriebsTyp: data.betriebsTyp,
        artikelListe,
        artikelCount: artikelListe.length,
        preisEbenen: preisEbenenEx,
        preisEbenenMap,
        ekRabattgruppen,
        kondiDefinition,
        kondiRabattFields,
        actionAbschliessen: data.actionAbschliessen,
        actionFreigeben: data.actionFreigeben,
        freigegeben: data.freigegeben,
        freigegebenUm: data.freigegebenUm
      } as EkPreisMassenPflegeModel
    }
  })

  const setModel = useCallback(
    (nextModel: Partial<EkPreisMassenPflegeModel>) => {
      updModel((prev: EkPreisMassenPflegeModel) => ({
        ...prev,
        ...nextModel
      }))
    },
    [updModel]
  )

  const updateArtikelliste = useCallback(
    (al: EkPreiseMasseBearbeitenEintragJson[]) => {
      const alEx = al?.map(
        (a) =>
          ({
            ...a,
            preisebene:
              a.preisEbeneId == null
                ? model.preisEbenenMap.get(preisEbeneIdRef.current)
                : model.preisEbenenMap.get(a.preisEbeneId)
          }) as EkPreiseMasseBearbeitenEintragExJson
      )
      setModel({
        artikelListe: arrayMerge(model.artikelListe, alEx, (a) => a.id)
      })
    },
    [setModel, model]
  )

  const updateModel = useCallback(
    (next) => {
      setModel({
        artikelListe: arrayMerge(model.artikelListe, next.artikelListe, (a) => a.id),
        actionFreigeben: next?.actionFreigeben || model.actionFreigeben,
        actionAbschliessen: next?.actionAbschliessen || model.actionAbschliessen
      })
    },
    [setModel, model]
  )

  const reloadModel = useCallback(
    // auch null erlaubt
    (nextPreisEbeneId = null) => {
      preisEbeneIdRef.current = nextPreisEbeneId
      load({
        id: preislisteId,
        standortId: nextPreisEbeneId
      })
    },
    [load, preislisteId]
  )

  const remove = useCallback(
    ({ onRemoved }) => {
      apiCall({
        method: 'POST',
        rest: '/ekpreisliste/delete',
        params: { id: preislisteId },
        onSuccess: (data) => {
          if (onRemoved) {
            onRemoved(data)
          }
        },
        onErrorMsg: `Löschen der Preisliste mit ID ${preislisteId} gescheitert.`
      })
    },
    [preislisteId, apiCall]
  )

  const removeSelected = useCallback(
    ({ selected }) => {
      apiCall({
        method: 'POST',
        rest: '/ekpreisliste/deleteEintraege',
        data: selected,
        onSuccess: () => {
          reload()
        },
        onErrorMsg: `Löschen der ausgewählten Artikel gescheitert.`
      })
    },
    [apiCall, reload]
  )

  useEffect(() => {
    if (model.id != null && !isEqual(preislisteId, model.id)) {
      reload()
    }
  }, [model.id, model.preisEbeneId, preislisteId, reload])

  return {
    model,
    errors,
    uiLock,
    updateArtikelliste,
    updateModel,
    reload: reloadModel,
    remove,
    removeSelected,
    apiCall,
    apiBusy: apiBusy || wait
  }
}
