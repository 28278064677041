import { FormControlLabel, RadioGroup as MuiRadioGroup, Radio, RadioProps } from '@mui/material'
import { asNumber, safeArray } from '@utils/utils'

export type RadioGroupItem<T = any> = {
  label: string
  value?: T
}

export type RadioGroupProps = {
  type: 'string' | 'number' | 'boolean'
  size?: RadioProps['size']
  name?: string
  value?: any
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  options?: RadioGroupItem[]
  disabled?: boolean
  row?: boolean
  defaultValue?: any
}

export const RadioGroup = ({
  type,
  name,
  value,
  defaultValue,
  onChange,
  disabled,
  row,
  size,
  options
}: RadioGroupProps) => {
  const onChangeInt = (event: React.ChangeEvent<HTMLInputElement>) => {
    const converted = (): any => {
      switch (type) {
        case 'number':
          return asNumber(event.target.value)

        case 'boolean':
          return event.target.value === 'true'

        default:
          return event.target.value
      }
    }

    if (onChange) {
      onChange({ ...event, target: { ...event.target, value: converted(), name } })
    }
  }
  return (
    <MuiRadioGroup value={value} row={row} onChange={onChangeInt} defaultValue={defaultValue}>
      {safeArray(options).map((opt) => (
        <FormControlLabel
          key={opt.value}
          disabled={disabled}
          label={opt.label}
          control={<Radio name={name} value={opt.value} size={size} />}
        />
      ))}
    </MuiRadioGroup>
  )
}
