import { Grid } from '@mui/material'
import { PimArtikelAnzeigenJson } from '@one/typings/apiTypings'
import { AppContext } from '@utils/ui/App/AppContext'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'
import { useContext } from 'react'

export interface ArtikelPimERPStatusProps {
  pimData: PimArtikelAnzeigenJson
}

export const ArtikelPimERPStatus = ({ pimData }: ArtikelPimERPStatusProps) => {
  const { isWithErpImport, isWithEkPreis, isWithVkPreis } = useContext(AppContext)

  const artikel = pimData?.artikel

  if (artikel?.version == null || !isWithErpImport) {
    return null
  }

  return (
    <CardEx title="ERP Importstatus" name="erpstatus">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Importfehler"
            name="importfehler"
            value={!!pimData.display?.importFehlerData}
            yesno
          />
        </Grid>
        {isWithEkPreis && (
          <Grid item xs={12} sm={6} md={4}>
            <StaticField
              label="Importfehler (Preise - EK)"
              name="importfehlerPreiseEk"
              value={!!pimData.display?.importFehlerPreiseEk}
              yesno
            />
          </Grid>
        )}
        {isWithVkPreis && (
          <Grid item xs={12} sm={6} md={4}>
            <StaticField
              label="Importfehler (Preise - VK)"
              name="importfehlerPreiseVk"
              value={!!pimData.display?.importFehlerPreiseVk}
              yesno
            />
          </Grid>
        )}
      </Grid>
    </CardEx>
  )
}
