import { Grid } from '@mui/material'
import { PimArtikelJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { NumberField } from '@utils/ui/fields/NumberField'
import { TextField } from '@utils/ui/fields/TextField'
import React from 'react'

export interface PimArtikelHagebauProps {
  pimArtikel: PimArtikelJson
  onChange: ValueChangeFn<SeArtikelJson>
  errors: any
}

export const PimArtikelHagebau = ({ pimArtikel, onChange, errors }: PimArtikelHagebauProps) => {
  return (
    <CardEx title="Hagebau Logistik">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Einkaufsmengeneinheit"
            name="pimArtikel.version.hagebauLogistikEinkaufMengeneinheit"
            value={pimArtikel.version.hagebauLogistikEinkaufMengeneinheit}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Feinkommissionierung"
            name="pimArtikel.version.hagebauLogistikFeinkommissionierung"
            value={pimArtikel.version.hagebauLogistikFeinkommissionierung}
            fraction={0}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Lagerbereich"
            name="pimArtikel.version.hagebauLogistikLagerbereich"
            value={pimArtikel.version.hagebauLogistikLagerbereich}
            fraction={0}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Lagerkosten Mengeneinheit"
            name="pimArtikel.version.hagebauLogistikLagerkostenMengeneinheit"
            value={pimArtikel.version.hagebauLogistikLagerkostenMengeneinheit}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Produktmanager"
            name="pimArtikel.version.hagebauLogistikProduktmanager"
            value={pimArtikel.version.hagebauLogistikProduktmanager}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Regalfaehigkeit"
            name="pimArtikel.version.hagebauLogistikRegalfaehigkeit"
            value={pimArtikel.version.hagebauLogistikRegalfaehigkeit}
            fraction={0}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Stapelhoehe"
            name="pimArtikel.version.hagebauLogistikStapelhoehe"
            value={pimArtikel.version.hagebauLogistikStapelhoehe}
            fraction={0}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Warenausgang Handlingskategorie"
            name="pimArtikel.version.hagebauLogistikWarenausgangHandlingskategorie"
            value={pimArtikel.version.hagebauLogistikWarenausgangHandlingskategorie}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Warenausgang Mengeneinheit"
            name="pimArtikel.version.hagebauLogistikWarenausgangMengeneinheit"
            value={pimArtikel.version.hagebauLogistikWarenausgangMengeneinheit}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Wareneingang Handlingskategorie"
            name="pimArtikel.version.hagebauLogistikWareneingangHandlingskategorie"
            value={pimArtikel.version.hagebauLogistikWareneingangHandlingskategorie}
            onChange={onChange}
            fullWidth
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelHagebauMemo = React.memo(PimArtikelHagebau)
