import { Grid, Switch, Typography } from '@mui/material'
import { MessageID } from '@one/MessageIDs'
import { api } from '@one/api'
import { CompositeAttributeChangeTreeView } from '@one/components/common/CompositeAttributeChangeTreeView'
import { EkPreisChangeTreeView } from '@one/components/common/EkPreisChangeTreeView'
import { SeverityCell } from '@one/components/common/SeverityCell'
import { VkPreisChangeTreeView } from '@one/components/common/VkPreisChangeTreeView'
import { cleanUpCompositeAttributeChange } from '@one/components/common/utils'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  ArtikelSelektionDetailsJson,
  ArtikelSelektionEintragChangeJson,
  EanZuordnung,
  EkKonditionenDefinitionJson,
  MessageJson
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { ModelAction } from '@utils/modelmgr'
import { useResolveCache } from '@utils/resolveCache'
import { CardEx } from '@utils/ui/CardEx'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { WaitPlane } from '@utils/ui/planes/WaitPlane'
import { isEmpty } from '@utils/utils'
import { useMemo } from 'react'
import { ArtikelSelektionEintragChangeEdit } from './model/ArtikelSelektionTypes'
import { ArtikelAboSelektionUsecase } from './model/ArtikelSelektionUsecase'

const errorAction = (
  row: MessageJson,
  changes: ArtikelSelektionEintragChangeEdit,
  onChange: (checked: boolean, type?: EanZuordnung, name?: string) => void
) => {
  switch (row.messageId?.id) {
    case MessageID.IDE_DZ_EAN_KONFLIKT:
      return (
        <Grid item container width="100%" justifyContent="end" marginTop={1} direction="column">
          <Grid item>
            <Switch
              size="small"
              name="aboean"
              checked={changes?.eanZuordnung === EanZuordnung.ZUGEORDNET}
              onChange={(e, checked) => onChange(checked, EanZuordnung.ZUGEORDNET)}
            />{' '}
            EAN übernehmen und abonnieren
          </Grid>
          <Grid item>
            <Switch
              size="small"
              name="aboeannicht"
              checked={changes?.eanZuordnung === EanZuordnung.IGNORIERT}
              onChange={(e, checked) => onChange(checked, EanZuordnung.IGNORIERT)}
            />{' '}
            EAN NICHT übernehmen und abonnieren
          </Grid>
        </Grid>
      )
    case MessageID.IDE_DZ_BT_ANDERER_ERMITTELT:
      return (
        <Grid item container width="100%" justifyContent="end" marginTop={1} direction="column">
          <Grid item>
            <Switch
              size="small"
              name="uebernehmeBetriebsTypSwitch"
              checked={changes?.uebernehmeBetriebsTypSwitch}
              onChange={(e, checked) => onChange(checked)}
            />{' '}
            In Betriebstyp des Abos übernehmen und abonnieren
          </Grid>
        </Grid>
      )
    case MessageID.IDE_DZ_ABGLEICH_ANDERE_EANGRUPPE:
      return (
        <Grid item container width="100%" justifyContent="end" marginTop={1} direction="column">
          <Grid item>
            <Switch
              size="small"
              name="uebernehmeEanSwitch"
              checked={changes?.uebernehmeEanSwitch}
              onChange={(e, checked) => onChange(checked)}
            />{' '}
            Artikel verbinden
          </Grid>
        </Grid>
      )
    case MessageID.IDE_DZ_MEHRERE_WURZEL_MES:
      return (
        <Grid item container width="100%" justifyContent="end" marginTop={1} direction="column">
          <Grid item>
            <Switch
              size="small"
              name="ergaenzeMEs"
              checked={changes?.ergaenzeMEs}
              onChange={(e, checked) => {
                if (!checked) {
                  onChange(false, null, 'verbergeMEs')
                }
                onChange(checked, null, 'ergaenzeMEs')
              }}
            />{' '}
            Fehlende ME im ERP Artikel ergänzen
          </Grid>
          <Grid item>
            <Switch
              size="small"
              name="verbergeMEs"
              checked={changes?.verbergeMEs}
              onChange={(e, checked) => {
                if (checked) {
                  onChange(true, null, 'ergaenzeMEs')
                }
                onChange(checked, null, 'verbergeMEs')
              }}
            />{' '}
            Fehlende ME nicht ins ERP übernehmen
          </Grid>
        </Grid>
      )
    case MessageID.IDE_ZE_ABGLEICH_MEHRDEUTIG:
      return (
        <Grid item container width="100%" justifyContent="end" marginTop={1} direction="column">
          <Grid item>
            <Switch
              size="small"
              name="uebernehmeMehrdeutigAusZE"
              checked={changes?.uebernehmeMehrdeutigAusZE}
              onChange={(e, checked) => onChange(checked)}
            />{' '}
            Mehrdeutigen Listungsartikel zuordnen
          </Grid>
        </Grid>
      )
    default:
      return null
  }
}

export interface ArtikelAboSelektionDetailsProps {
  eintragId?: number
  dispatch: (changes: ModelAction) => void
  ergebnisseChanges?: Map<number, ArtikelSelektionEintragChangeJson>
  resetToggle?: any
  konditionDefinition?: EkKonditionenDefinitionJson
  zubestaetigenHints?: Map<number, string>
}

export const ArtikelAboSelektionDetails = ({
  eintragId,
  dispatch,
  ergebnisseChanges,
  resetToggle = null,
  konditionDefinition,
  zubestaetigenHints
}: ArtikelAboSelektionDetailsProps) => {
  const { et } = useEnums()

  // const { allianzMitglied } = useContext(AppContext)

  const [artikelDetails, resolving, error] = useResolveCache<ArtikelSelektionDetailsJson>({
    api,
    resetToggle,
    dataId: eintragId,
    paramName: 'artikelSelektionEintragId',
    rest: '/artikelselektion/artikelDetails'
  })

  // const konflikt = artikelDetails?.aenderungsTyp === ArtikelAenderungTyp.KONFLIKT_DZ
  // const labelAlt = /*(konflikt && 'ERP:') ||*/ (allianzMitglied && 'ALZ:') || 'LST:'

  const loadError = useMemo(
    () =>
      error && (
        <Grid item flex={1} width="100%">
          <CardEx title="Fehler" height="100%">
            <Typography>{error.message}</Typography>
          </CardEx>
        </Grid>
      ),
    [error]
  )

  const messages = useMemo(() => {
    const errorColumns = [
      {
        field: 'severity',
        header: 'Schweregrad',
        valueGetter: (row) => et(HkmEnum.MessageSeverity, row.severity),
        body: (row) => <SeverityCell value={row.severity} />
      },
      {
        field: 'message',
        header: 'Meldung',
        body: (row) => (
          <>
            <Grid container direction="column">
              <Grid item>{row.message}</Grid>
              {errorAction(
                row,
                ergebnisseChanges.get(eintragId),
                (checked: boolean, type: EanZuordnung = null, name: string) =>
                  dispatch({
                    type: ArtikelAboSelektionUsecase.SETMESSAGEACTION,
                    eintragId,
                    message: row,
                    mode: type,
                    checked,
                    name
                  })
              )}
            </Grid>
          </>
        )
      }
    ] as Column[]

    return error || isEmpty(artikelDetails?.messages) ? null : (
      <Grid item flex={1} width="100%">
        <DataTableCard
          title="Aktualisierungsfehler"
          // height="100%"
          name="AktualisierungsfehlerTable"
          columns={errorColumns}
          value={artikelDetails?.messages}
          wrapMode="normal"
          emptyMessage="Keine Fehler gefunden"
          dense
        />
      </Grid>
    )
  }, [artikelDetails?.messages, dispatch, eintragId, ergebnisseChanges, error, et])

  const konflikte = useMemo(() => {
    const konflikte = cleanUpCompositeAttributeChange(artikelDetails?.konflikte, false)
    if (!error && konflikte) {
      return (
        <Grid item flex={1} width="100%">
          <CardEx title="Attributänderungen" height="100%">
            <CompositeAttributeChangeTreeView
              composite={konflikte}
              labelAlt="LST:"
              labelNeu="ERP:"
            />
          </CardEx>
        </Grid>
      )
    }
    return null
  }, [artikelDetails?.konflikte, error])

  const konflikteArrstFolgestufe = useMemo(() => {
    const konflikte = cleanUpCompositeAttributeChange(artikelDetails?.konflikte, true)
    if (!error && konflikte) {
      return (
        <Grid item flex={1} width="100%">
          <CardEx title="Attributänderungen in ArSST Folgestufe" height="100%">
            <CompositeAttributeChangeTreeView
              composite={konflikte}
              zusatz
              labelAlt="LST:"
              labelNeu="ERP:"
            />
          </CardEx>
        </Grid>
      )
    }
    return null
  }, [artikelDetails?.konflikte, error])

  const listenpreise = useMemo(() => {
    if (!error && !isEmpty(artikelDetails?.listenpreisChanges)) {
      return (
        <Grid item flex={1} width="100%">
          <CardEx title="Listenpreisänderungen" height="100%">
            <EkPreisChangeTreeView
              konditionDefinition={konditionDefinition}
              preisChanges={artikelDetails?.listenpreisChanges}
            />
          </CardEx>
        </Grid>
      )
    }
    return null
  }, [artikelDetails?.listenpreisChanges, error, konditionDefinition])

  const ekPreise = useMemo(() => {
    if (!error && !isEmpty(artikelDetails?.ekPreisChanges)) {
      return (
        <Grid item flex={1} width="100%">
          <CardEx title="EK-Preisänderungen" height="100%">
            <EkPreisChangeTreeView
              konditionDefinition={konditionDefinition}
              preisChanges={artikelDetails?.ekPreisChanges}
            />
          </CardEx>
        </Grid>
      )
    }
    return null
  }, [artikelDetails?.ekPreisChanges, error, konditionDefinition])

  const vkPreise = useMemo(() => {
    if (
      !error &&
      !isEmpty(artikelDetails?.vkPreisChanges) &&
      !isEmpty(artikelDetails?.preisGruppen)
    ) {
      return (
        <Grid item flex={1} width="100%">
          <CardEx title="VK-Preisänderungen" height="100%">
            <VkPreisChangeTreeView
              preisGruppen={artikelDetails.preisGruppen}
              preisChanges={artikelDetails.vkPreisChanges}
            />
          </CardEx>
        </Grid>
      )
    }
    return null
  }, [artikelDetails?.preisGruppen, artikelDetails?.vkPreisChanges, error])

  const empty = useMemo(() => {
    if (resolving) {
      return null
    }
    if (loadError == null && messages == null && konflikte == null && listenpreise == null) {
      return (
        <Grid item flex={1} width="100%">
          <CardEx title="Artikeldetails" height="100%">
            <Typography variant="subtitle1">Keine Details verfügbar.</Typography>
          </CardEx>
        </Grid>
      )
    }
    return null
  }, [messages, konflikte, listenpreise, loadError, resolving])

  return (
    <ScrollPanel height="100%">
      <WaitPlane wait={resolving}>
        <Grid container height="100%" spacing={1} direction="column" flexWrap="nowrap">
          {loadError}
          {messages}
          {konflikte}
          {konflikteArrstFolgestufe}
          {listenpreise}
          {ekPreise}
          {vkPreise}
          {empty}
        </Grid>
      </WaitPlane>
    </ScrollPanel>
  )
}
