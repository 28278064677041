import { Grid } from '@mui/material'
import { PimArtikelVersionComplianceJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { NumberField } from '@utils/ui/fields/NumberField'
import { StringArrayField } from '@utils/ui/fields/StringArrayField'
import { TextField } from '@utils/ui/fields/TextField'
import React from 'react'

export interface PimArtikelComplianceProps {
  compliance: PimArtikelVersionComplianceJson
  onChange: ValueChangeFn<SeArtikelJson>
  errors: any
}

export const PimArtikelCompliance = ({
  compliance,
  onChange,
  errors
}: PimArtikelComplianceProps) => {
  return (
    <CardEx title="Compliance">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <NumberField
            label="Gefahrgutfaktor"
            value={compliance.gefahrgutfaktor}
            name="pimArtikel.version.compliance.gefahrgutfaktor"
            fraction={4}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <NumberField
            label="Gefahrgut Menge"
            value={compliance.gefahrgutMenge}
            name="pimArtikel.version.compliance.gefahrgutMenge"
            fraction={4}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <NumberField
            label="Gefahrgut Menge-Volumen"
            value={compliance.gefahrgutMengeVolumen}
            name="pimArtikel.version.compliance.gefahrgutMengeVolumen"
            fraction={4}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Tunnelbeschränkungscode"
            value={compliance.tunnelbeschraenkungscode}
            name="pimArtikel.version.compliance.tunnelbeschraenkungscode"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Gefahrgut Verpackungsgruppe"
            value={compliance.gefahrgutVerpackungsgruppe}
            name="pimArtikel.version.compliance.gefahrgutVerpackungsgruppe"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Gefahrgut UN-Nummer"
            value={compliance.gefahrgutUNNummer}
            name="pimArtikel.version.compliance.gefahrgutUNNummer"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Wassergefährdungsklasse"
            value={compliance.wassergefaehrdungsklasse}
            name="pimArtikel.version.compliance.wassergefaehrdungsklasse"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Signalwort"
            value={compliance.signalwort}
            name="pimArtikel.version.compliance.signalwort"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Energieeffizienzklasse"
            value={compliance.energieeffizienzklasse}
            name="pimArtikel.version.compliance.energieeffizienzklasse"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Gefahrgut ADR-Nummer"
            value={compliance.gefahrgutADRNummer}
            name="pimArtikel.version.compliance.gefahrgutADRNummer"
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Inverkehrbringer Name"
            value={compliance.inverkehrbringerName}
            name="pimArtikel.version.compliance.inverkehrbringerName"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Inverkehrbringer Straße/Postfach"
            value={compliance.inverkehrbringerStrassePostfach}
            name="pimArtikel.version.compliance.inverkehrbringerStrassePostfach"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Inverkehrbringer Hausnummer/Postfachnummer"
            value={compliance.inverkehrbringerHausnummerPostfachnummer}
            name="pimArtikel.version.compliance.inverkehrbringerHausnummerPostfachnummer"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Inverkehrbringer PLZ"
            value={compliance.inverkehrbringerPLZ}
            name="pimArtikel.version.compliance.inverkehrbringerPLZ"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Inverkehrbringer Land"
            value={compliance.inverkehrbringerLand}
            name="pimArtikel.version.compliance.inverkehrbringerLand"
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Scip-Nummer"
            value={compliance.scipNummer}
            name="pimArtikel.version.compliance.scipNummer"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Gefahrnummer"
            value={compliance.gefahrnummer}
            name="pimArtikel.version.compliance.gefahrnummer"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <NumberField
            label="Nettoexplosivstoffmasse"
            value={compliance.nettoexplosivstoffmasse}
            name="pimArtikel.version.compliance.nettoexplosivstoffmasse"
            fraction={4}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="ADR Klasse"
            value={compliance.adrKlasse}
            name="pimArtikel.version.compliance.adrKlasse"
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Gefahrzettel"
            value={compliance.gefahrzettel}
            name="pimArtikel.version.compliance.gefahrzettel"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Lagerklasse"
            value={compliance.lagerklasse}
            name="pimArtikel.version.compliance.lagerklasse"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Produktidentifikatoren"
            value={compliance.produktidentifikatoren}
            name="pimArtikel.version.compliance.produktidentifikatoren"
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="CE Kennzeichen"
            value={compliance.ceKennzeichen}
            name="pimArtikel.version.compliance.ceKennzeichen"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <NumberField
            label="Netzspannung"
            value={compliance.netzspannung}
            name="pimArtikel.version.compliance.netzspannung"
            fraction={0}
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="EnVKV Modellbezeichnung"
            value={compliance.enVKVModellbezeichnung}
            name="pimArtikel.version.compliance.enVKVModellbezeichnung"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="EnVKV-Skala Energieeffizienzklasse"
            value={compliance.enVKVSkalaEnergieeffizienzklasse}
            name="pimArtikel.version.compliance.enVKVSkalaEnergieeffizienzklasse"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Gefahrgut Sondervorschrift"
            value={compliance.gefahrgutSondervorschrift}
            name="pimArtikel.version.compliance.gefahrgutSondervorschrift"
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Prod-SG Herstelleradresse"
            value={compliance.prodSGHerstelleradresse}
            name="pimArtikel.version.compliance.prodSGHerstelleradresse"
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Umweltgefährdungsklasse"
            value={compliance.umweltgefaehrdungsklasse}
            name="pimArtikel.version.compliance.umweltgefaehrdungsklasse"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Gefahrstoffeinstufung"
            value={compliance.gefahrstoffeinstufung}
            name="pimArtikel.version.compliance.gefahrstoffeinstufung"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Zulaessige Gesamtmenge je Beförderungseinheit"
            value={compliance.zulaessigeGesamtmengeJeBefoerderungseinheit}
            name="pimArtikel.version.compliance.zulaessigeGesamtmengeJeBefoerderungseinheit"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <NumberField
            label="Meldepflichtiger Ausgangstoff maximale Abgabemenge"
            value={compliance.meldepflichtigerAusgangstoffMaximaleAbgabemenge}
            name="pimArtikel.version.compliance.meldepflichtigerAusgangstoffMaximaleAbgabemenge"
            fraction={4}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <TextField
            label="Meldepflichtiger Ausgangstoff Mengeneinheit"
            value={compliance.meldepflichtigerAusgangstoffMengeneinheit}
            name="pimArtikel.version.compliance.meldepflichtigerAusgangstoffMengeneinheit"
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2} xl={3}>
          <TextField
            label="Textilkomponente Bezeichnung"
            value={compliance.textilkomponenteBezeichnung}
            name="pimArtikel.version.compliance.textilkomponenteBezeichnung"
            onChange={onChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2} xl={3}>
          <NumberField
            label="Textilkomponente Anteil"
            value={compliance.textilkomponenteAnteil}
            name="pimArtikel.version.compliance.textilkomponenteAnteil"
            fraction={4}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={3}>
          <NumberField
            label="ADR Punktzahl"
            value={compliance.adrPunktzahl}
            name="pimArtikel.version.compliance.adrPunktzahl"
            fraction={0}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TextField
            label="Beförderungskategorie"
            value={compliance.befoerderungskategorie}
            name="pimArtikel.version.compliance.befoerderungskategorie"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TextField
            label="Zusätzliche Angaben Beförderungspapiere"
            value={compliance.zusaetzlicheAngabenBefoerderungspapiere}
            name="pimArtikel.version.compliance.zusaetzlicheAngabenBefoerderungspapiere"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <StringArrayField
            label="Gefahrzettel Nebengefahren"
            value={compliance.gefahrzettelNebengefahren}
            name="pimArtikel.version.compliance.gefahrzettelNebengefahren"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <StringArrayField
            label="Abfallschlüssel"
            value={compliance.abfallschluessel}
            name="pimArtikel.version.compliance.abfallschluessel"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <StringArrayField
            label="Gefahrenhinweis"
            value={compliance.gefahrenhinweis}
            name="pimArtikel.version.compliance.gefahrenhinweis"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <StringArrayField
            label="Gefahrenpiktogramm"
            value={compliance.gefahrenpiktogramm}
            name="pimArtikel.version.compliance.gefahrenpiktogramm"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <StringArrayField
            label="Klassifizierungscode ADR"
            value={compliance.klassifizierungscodeADR}
            name="pimArtikel.version.compliance.klassifizierungscodeADR"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <StringArrayField
            label="Konsistenz"
            value={compliance.konsistenz}
            name="pimArtikel.version.compliance.konsistenz"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <StringArrayField
            label="NetzanschlussTyp"
            value={compliance.netzanschlussTyp}
            name="pimArtikel.version.compliance.netzanschlussTyp"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <StringArrayField
            label="Prod-SG Produktkennzeichnung"
            value={compliance.prodSGProduktkennzeichnung}
            name="pimArtikel.version.compliance.prodSGProduktkennzeichnung"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <StringArrayField
            label="Prod-SG Warnhinweis"
            value={compliance.prodSGWarnhinweis}
            name="pimArtikel.version.compliance.prodSGWarnhinweis"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <StringArrayField
            label="Sicherheitshinweis"
            value={compliance.sicherheitshinweis}
            name="pimArtikel.version.compliance.sicherheitshinweis"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <StringArrayField
            label="Verpackungsart"
            value={compliance.verpackungsart}
            name="pimArtikel.version.compliance.verpackungsart"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <StringArrayField
            label="WeeeKategorie"
            value={compliance.weeeKategorie}
            name="pimArtikel.version.compliance.weeeKategorie"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="MHD-Relevant"
            checked={compliance.mhdRelevant}
            name="pimArtikel.version.compliance.mhdRelevant"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Gefahrstoff"
            checked={compliance.gefahrstoff}
            name="pimArtikel.version.compliance.gefahrstoff"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Gefahrgut"
            checked={compliance.gefahrgut}
            name="pimArtikel.version.compliance.gefahrgut"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Biozid"
            checked={compliance.biozid}
            name="pimArtikel.version.compliance.biozid"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Lithiumhaltige Batterie"
            checked={compliance.lithiumhaltigeBatterie}
            name="pimArtikel.version.compliance.lithiumhaltigeBatterie"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Frostgefährdet"
            checked={compliance.frostgefaehrdet}
            name="pimArtikel.version.compliance.frostgefaehrdet"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Verpackung: Mehrwegkennzeichnung"
            checked={compliance.verpackungMehrwegkennzeichnung}
            name="pimArtikel.version.compliance.verpackungMehrwegkennzeichnung"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Gefahrenkennzeichnungsetikett"
            checked={compliance.gefahrenkennzeichnungsetikett}
            name="pimArtikel.version.compliance.gefahrenkennzeichnungsetikett"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Besonders besorgniserregender Stoff"
            checked={compliance.besondersBesorgniserregenderStoff}
            name="pimArtikel.version.compliance.besondersBesorgniserregenderStoff"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Limited Quantity"
            checked={compliance.limitedQuantity}
            name="pimArtikel.version.compliance.limitedQuantity"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Weee Relevanz"
            checked={compliance.weeeRelevanz}
            name="pimArtikel.version.compliance.weeeRelevanz"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="CE Relevanz"
            checked={compliance.ceRelevanz}
            name="pimArtikel.version.compliance.ceRelevanz"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="EnVkv Relevanz"
            checked={compliance.enVkvRelevanz}
            name="pimArtikel.version.compliance.enVkvRelevanz"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Ecodesign 2022"
            checked={compliance.ecodesign2022}
            name="pimArtikel.version.compliance.ecodesign2022"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Prod-SG Relevanz"
            checked={compliance.prodSGRelevanz}
            name="pimArtikel.version.compliance.prodSGRelevanz"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Meldepflichtiger Ausgangstoff"
            checked={compliance.meldepflichtigerAusgangstoff}
            name="pimArtikel.version.compliance.meldepflichtigerAusgangstoff"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Beschränkter Ausgangsstoff"
            checked={compliance.beschraenkterAusgangsstoff}
            name="pimArtikel.version.compliance.beschraenkterAusgangsstoff"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Nationale CO2-Abgabe AT-Relevanz"
            checked={compliance.nationaleCO2AbgabeATRelevanz}
            name="pimArtikel.version.compliance.nationaleCO2AbgabeATRelevanz"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="EuNorm Relevanz"
            checked={compliance.euNormRelevanz}
            name="pimArtikel.version.compliance.euNormRelevanz"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Druckgasverordnung Relervanz"
            checked={compliance.druckgasverordnungRelervanz}
            name="pimArtikel.version.compliance.druckgasverordnungRelervanz"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Alterspruefung Relevanz"
            checked={compliance.alterspruefungRelevanz}
            name="pimArtikel.version.compliance.alterspruefungRelevanz"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Pflanzenschutzmittel Relevanz"
            checked={compliance.pflanzenschutzmittelRelevanz}
            name="pimArtikel.version.compliance.pflanzenschutzmittelRelevanz"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
          <Checkbox
            size="small"
            labelNotDisabled
            indeterminate
            label="Textil KVO-Relevanz"
            checked={compliance.textilKvoRelevanz}
            name="pimArtikel.version.compliance.textilKvoRelevanz"
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelComplianceMemo = React.memo(PimArtikelCompliance)
