/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import {
  Autocomplete,
  Chip,
  InputAdornment,
  LinearProgress,
  TextField,
  TextFieldProps
} from '@mui/material'
import { Actions, buildActionButtons } from '@utils/ui/Action'
import { IconButton } from '@utils/ui/Buttons/IconButton'
import { getFieldValue } from '@utils/ui/DataTable/DataTableUtils'
import { FieldCtrlType } from '@utils/ui/FieldCtrlType'
import { autoCompleteOff, stopPropagation } from '@utils/ui/uiutils'
import { ensureArray, ifString, isFunction, isString, safeArray, safeObject } from '@utils/utils'
import { useCallback, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'

export interface AutocompleteBaseProps<T = any, V = any> {
  id?: string
  name?: string
  required?: boolean
  value?: V[] | V | null
  label?: string
  fieldCtrl?: FieldCtrlType
  error?: string | boolean
  helperText?: string
  disabled?: boolean
  fullWidth?: boolean
  onChange?: (e: any) => void
  emptyText?: string
  renderOption?: any
  renderItem?: (opt: T) => React.ReactNode
  filterItem?: (opt: T) => boolean
  renderNullItem?: boolean
  optionLabel?: string | ((opt: T) => string) | null
  optionValue?: string | ((opt: T) => V) | null
  multiple?: boolean
  limitTags?: number
  isOptionEqualToValue?: (opt: T, val: V) => boolean
  actions?: Actions
  startAdornments?: React.ReactNode
  endAdornments?: React.ReactNode
  groupBy?: (opt: T) => string
  className?: string
  freeSolo?: boolean
  chipType?: 'square' | 'round'
}

export interface AutocompleteExProps<T = any, V = any> extends AutocompleteBaseProps<T, V> {
  loading?: boolean
  options?: any
  filterOptions?: any
  loadingText?: any
  onInputChange?: any
  noOptionsText?: any
  variant?: TextFieldProps['variant']
  open?: boolean
  withButtons?: false | true | 'reverse'
  noClear?: boolean
}

const useStyles = makeStyles()({
  popper: {
    minWidth: 'fit-content!important'
  }
})

export const AutocompleteEx = <T = any, V = any>({
  id,
  name,
  required,
  value,
  label,
  fieldCtrl,
  error,
  helperText,
  disabled,
  fullWidth,
  onChange,
  loading,
  emptyText,
  renderOption,
  renderItem,
  renderNullItem,
  optionLabel,
  optionValue,
  options,
  filterOptions,
  loadingText,
  multiple,
  limitTags = -1,
  isOptionEqualToValue,
  actions,
  startAdornments,
  endAdornments,
  onInputChange,
  noOptionsText,
  groupBy,
  className,
  variant = 'standard',
  filterItem,
  open,
  withButtons,
  noClear,
  freeSolo,
  chipType
}: AutocompleteExProps<T, V>) => {
  const { classes } = useStyles()

  const placeholder = emptyText || (required && 'zu wählen') || 'Keine'

  const getOptionValue = useCallback(
    (opt: T) => {
      if (optionValue == null) {
        return opt
      }
      if (opt == null) {
        return null
      }
      if (typeof optionValue === 'function') {
        return optionValue(opt)
      }
      if (isString(optionValue)) {
        return opt[optionValue]
      }
      return opt
    },
    [optionValue]
  )

  const isOptionEqualToValueInt = useCallback(
    (opt, val) => {
      if (val == null && opt == null) {
        return true
      }
      if (isOptionEqualToValue) {
        return isOptionEqualToValue(opt, val)
      }
      const oval = getOptionValue(opt)
      return oval === val
    },
    [getOptionValue, isOptionEqualToValue]
  )

  const optionsInt = useMemo(() => {
    let arr = options || []
    if (filterItem) {
      arr = arr.filter(filterItem)
    }
    if (placeholder && !required) {
      arr = [null, ...arr]
    } else {
      arr = [...arr]
    }
    if (value != null && (!multiple || safeArray(value).length > 0)) {
      ensureArray(value).forEach((v) => {
        if (
          v != null &&
          arr.filter(Boolean).find((opt) => isOptionEqualToValueInt(opt, v)) == null
        ) {
          arr.push(v) // TODO wenn nur id...
        }
      })
    }
    return arr
  }, [filterItem, isOptionEqualToValueInt, multiple, options, placeholder, required, value])

  const getOptionLabel = useCallback(
    (opt) => {
      if (opt == null) {
        return ''
      }
      if (isFunction(optionLabel)) {
        // @ts-ignore
        return optionLabel(opt) || ''
      }
      if (isString(optionLabel)) {
        // @ts-ignore
        return getFieldValue(opt, optionLabel) || ''
      }
      // eslint-disable-next-line no-console
      console.error('Error: optionLabel required')
      return '?'
    },
    [optionLabel]
  )

  const onSelect = (e, opt: T[]) => {
    const text = `Maximal ${limitTags} Elemente erlaubt`
    if (multiple && limitTags > 0 && opt.length > limitTags) {
      helperText = text
      return
    }
    if (helperText === text) {
      helperText = ''
    }

    let val =
      opt && (multiple ? opt.map((o) => getOptionValue(o)) : getOptionValue(safeObject(opt)))
    if ((multiple && val?.length === 0) || (val?.length === 1 && val[0] == null)) {
      val = null
    }
    onChange({
      ...e,
      target: {
        name,
        value: val
      }
    })
  }

  const renderTags = (value, getTagProps) =>
    value.map((option, index) => {
      const label = getOptionLabel(option)
      return (
        <Chip
          key={label}
          variant="outlined"
          label={label}
          size="small"
          style={{ borderRadius: chipType === 'square' ? 6 : 16 }}
          {...getTagProps({ index })}
        />
      )
    })

  const pos =
    !withButtons || value == null ? null : optionsInt.findIndex((s) => getOptionValue(s) === value)

  const handleChevron = useCallback(
    (inc: number) => {
      if (onChange) {
        const npos = pos == null ? 0 : pos + inc
        if (npos >= 0 && npos < optionsInt.length) {
          const next = optionsInt[npos]
          // @ts-ignore
          onChange({ target: { name, value: getOptionValue(next, npos) } })
        } else if (npos === -1 && !required) {
          // @ts-ignore
          onChange({ target: { name, value: null } })
        }
      }
    },
    [getOptionValue, name, onChange, optionsInt, pos, required]
  )

  const startAdornment = useMemo(() => {
    if (startAdornments) {
      return <InputAdornment position="start">{startAdornments}</InputAdornment>
    }

    if (withButtons && !disabled) {
      const reverse = withButtons === 'reverse'
      const disabledLeft = (pos == null && value == null) || pos === 0 || disabled
      const disabledRight = pos + 1 >= optionsInt.length || disabled
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          onClick={(e) => {
            stopPropagation(e)
          }}
          onKeyDown={(e) => {
            stopPropagation(e)
          }}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <IconButton
            icon={<ChevronLeft />}
            size="small"
            disabled={reverse ? disabledRight : disabledLeft}
            onClick={() => handleChevron(reverse ? +1 : -1)}
            style={{ marginLeft: -2, marginRight: -2, padding: 2 }}
          />
          <IconButton
            icon={<ChevronRight />}
            size="small"
            disabled={reverse ? disabledLeft : disabledRight}
            onClick={() => handleChevron(reverse ? -1 : +1)}
            style={{ marginLeft: -2, marginRight: -2, padding: 2 }}
          />
        </div>
      )
    }
    return null
  }, [disabled, handleChevron, optionsInt.length, pos, startAdornments, value, withButtons])

  const renderInput = useCallback(
    (params) => (
      <>
        <TextField
          {...params}
          label={label}
          variant={variant ?? 'standard'}
          placeholder={multiple && safeArray(value)?.length > 0 ? '' : placeholder}
          autoComplete={autoCompleteOff}
          InputProps={{
            ...params.InputProps,
            style: {
              paddingRight: 0,
              paddingTop: variant === 'standard' ? undefined : 0,
              paddingBottom: variant === 'standard' ? undefined : 0
            },
            required,
            autoComplete: autoCompleteOff,
            startAdornment: multiple ? params.InputProps.startAdornment : startAdornment,
            endAdornment: (
              <InputAdornment position="end">
                {endAdornments}
                {buildActionButtons({ actions, tiny: true })}
                {params.InputProps?.endAdornment?.props?.children}
              </InputAdornment>
            )
          }}
          inputProps={{
            ...params.inputProps,
            'data-name': label
          }}
          InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
          error={!!error || fieldCtrl?.error}
          helperText={ifString(error) || fieldCtrl?.helperText || helperText}
          required={required || fieldCtrl?.required}
          disabled={disabled || fieldCtrl?.disabled}
          fullWidth={fullWidth}
        />
        {loading ? <LinearProgress /> : null}
      </>
    ),
    [
      label,
      variant,
      multiple,
      value,
      placeholder,
      required,
      startAdornment,
      endAdornments,
      actions,
      error,
      fieldCtrl?.error,
      fieldCtrl?.helperText,
      fieldCtrl?.required,
      fieldCtrl?.disabled,
      helperText,
      disabled,
      fullWidth,
      loading
    ]
  )

  const renderOptionInt = (props, o) => {
    props.key = props.id // bugfix mui key="" ??
    if (o == null) {
      return <li {...props}>{renderNullItem ? renderItem(null) : <small>{placeholder}</small>}</li>
    }
    if (renderOption) {
      return renderOption(props, o)
    }
    if (renderItem) {
      return <li {...props}>{renderItem(o)}</li>
    }
    return <li {...props}>{getOptionLabel(o)}</li>
  }

  const valueInt = useMemo(() => {
    if (multiple) {
      return safeArray(value)
        .filter(Boolean)
        .map((v) => {
          const opt = optionsInt.filter(Boolean).find((o) => isOptionEqualToValueInt(o, v))
          if (opt == null) {
            console.error('Value not found in Options', v, optionsInt)
          }
          return opt
        })
        .filter(Boolean)
    }
    if (value == null) {
      return null
    }
    const opt = optionsInt.filter(Boolean).find((o) => isOptionEqualToValueInt(o, value))
    if (opt == null) {
      console.error('Value not found in Options', value, optionsInt)
    }
    return opt
  }, [isOptionEqualToValueInt, multiple, optionsInt, value])

  const props = {} as any // keep defaults...
  if (filterOptions)
    props.filterOptions = (ignore, state) => {
      let filtered = filterOptions(options, state)
      if (placeholder && !required) {
        filtered = [null, ...filtered]
      }
      return filtered
    }

  if (onInputChange) {
    props.onInputChange = onInputChange
  }

  return (
    <Autocomplete
      {...props}
      id={id}
      name={name}
      multiple={multiple}
      fullWidth={fullWidth}
      autoComplete
      renderTags={renderTags}
      renderInput={renderInput}
      renderOption={renderOptionInt}
      getOptionLabel={getOptionLabel}
      clearIcon={required || noClear ? null : undefined}
      loading={loading}
      loadingText={loadingText}
      options={optionsInt}
      noOptionsText={noOptionsText || 'Keine Treffer'}
      value={valueInt}
      onChange={onSelect}
      disabled={disabled || fieldCtrl?.disabled}
      required={required || fieldCtrl?.required}
      // variant={variant}
      groupBy={groupBy}
      className={className}
      classes={classes}
      open={open}
      autoSelect
      autoHighlight
      freeSolo={freeSolo}
    />
  )
}
