import { Grid } from '@mui/material'
import { PimArtikelVersionJson, PimArtikelVersionMengeneinheitJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { StaticField } from '@utils/ui/fields/StaticField'
import { useMemo } from 'react'

export interface ArtikelPimMengeneinheitenProps {
  model: PimArtikelVersionJson
}

export const ArtikelPimMengeneinheiten = ({ model }: ArtikelPimMengeneinheitenProps) => {
  const mengeneinheiten = model?.mengeneinheiten

  const column = useMemo(
    () =>
      [
        {
          field: 'gtin',
          header: 'EAN'
        },
        {
          field: 'pimMengeneinheit.name',
          header: 'Name',
          tooltip: (row) => row.pimMengeneinheit?.bez
        },
        {
          field: 'mengeBasismengeneinheit.faktor',
          header: 'Basismengeneinheit',
          align: 'right'
        },
        {
          field: 'pimBasisMengeneinheit.name',
          header: 'Basismengeneinheit-Name',
          tooltip: (row) => row.pimBasisMengeneinheit?.bez
        },
        {
          field: 'laenge',
          header: 'Länge',
          align: 'right'
        },
        {
          field: 'breite',
          header: 'Breite',
          align: 'right'
        },
        {
          field: 'hoehe',
          header: 'Höhe',
          align: 'right'
        },
        {
          field: 'gewichtBrutto',
          header: 'Gewicht-Brutto',
          align: 'right'
        },
        {
          field: 'gewichtNetto',
          header: 'Gewicht-Netto',
          align: 'right'
        },
        {
          field: 'volumne',
          header: 'Volumen',
          align: 'right'
        },
        {
          field: 'stapelfaktor',
          header: 'Stapelfaktor',
          align: 'right'
        },
        {
          field: 'invertiert',
          header: 'Invertiert'
        },
        {
          field: 'eans',
          header: 'EANs',
          valueGetter: (row) =>
            row.eans.map((ean) => `${ean.gtin}${ean.geloescht ? ' - gelöscht' : ''}`)
        }
      ] as Column<PimArtikelVersionMengeneinheitJson>[],
    []
  )

  const details = (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <StaticField
          label="Verkaufsverpackungmengeneinheit"
          value={model?.verkaufsverpackungMengeneinheit?.name}
          name="verkaufsverpackungMengeneinheit"
          empty
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StaticField
          label="Einzelmengeneinheit"
          value={model?.einzelMengeneinheit?.name}
          name="einzelMengeneinheit"
          empty
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StaticField
          label="Grundpreismenge"
          value={model.grundpreismenge}
          name="grundpreismenge"
          empty
        />
      </Grid>
    </Grid>
  )

  return (
    <CardEx
      title="Mengeneinheiten und EANs"
      height="100%"
      name="mengeneinheiten"
      topContent={details}
    >
      <ScrollPanel autoHeight autoHeightMax="600px">
        <DataTable
          name="PimMengenheitenTabelle"
          value={mengeneinheiten}
          columns={column}
          emptyMessage="Keine Daten"
          dense
        />
      </ScrollPanel>
    </CardEx>
  )
}
