import { Add, Delete } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { PimArtikelVersionPflanzeJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ModelAction, ValueChangeFn } from '@utils/modelmgr'
import { Button } from '@utils/ui/Buttons/Button'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { NumberField } from '@utils/ui/fields/NumberField'
import { TextField } from '@utils/ui/fields/TextField'
import React from 'react'
import { EigenlistungsArtikelUseCase } from './model/EigenlistungsArtikelUseCase'

export interface PimArtikelPflanzenProps {
  pflanze: PimArtikelVersionPflanzeJson
  onChange: ValueChangeFn<SeArtikelJson>
  dispatch: (action: ModelAction) => void
  errors: any
}

export const PimArtikelPflanzen = ({
  pflanze,
  onChange,
  dispatch,
  errors
}: PimArtikelPflanzenProps) => {
  const onAddPflanze = () => {
    dispatch({
      type: EigenlistungsArtikelUseCase.ADDPFLANZE
    })
  }
  const onRemovePflanze = () => {
    dispatch({
      type: EigenlistungsArtikelUseCase.REMOVEPFLANZE
    })
  }
  if (pflanze == null) {
    return (
      <Button
        label="Pflanze hinzufügen"
        variant="contained"
        StartIcon={Add}
        onClickVoid={onAddPflanze}
      />
    )
  }
  return (
    <CardEx
      title="Pflanze"
      name="Pflanze"
      topActions={
        <Button
          label="Entfernen"
          StartIcon={Delete}
          onClickVoid={onRemovePflanze}
          color="warning"
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={1}>
          <NumberField
            label="Pflanzen pro Tray"
            value={pflanze.pflanzenProTray}
            name="pflanzenProTray"
            fraction={0}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={1}>
          <NumberField
            label="Trays pro Lage"
            value={pflanze.pflanzenTraysProLage}
            name="pflanzenTraysProLage"
            fraction={0}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={1}>
          <NumberField
            label="Lagen pro CC"
            value={pflanze.pflanzenLagenProCc}
            name="pflanzenLagenProCc"
            fraction={0}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <TextField
            label="Blütenfarbe"
            value={pflanze.bluetenfarbe}
            name="bluetenfarbe"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <TextField
            label="Blütenmerkmal"
            value={pflanze.bluetenmerkmal}
            name="bluetenmerkmal"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <TextField
            label="Blattfarbe"
            value={pflanze.blattfarbe}
            name="blattfarbe"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <TextField
            label="Ausführung"
            value={pflanze.ausfuehrung}
            name="ausfuehrung"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <TextField
            label="Art"
            value={pflanze.botanischeBezeichnungArt}
            name="botanischeBezeichnungArt"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <TextField
            label="Sorte"
            value={pflanze.botanischeBezeichnungSorte}
            name="botanischeBezeichnungSorte"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <TextField
            label="Gattung"
            value={pflanze.botanischeBezeichnungGattung}
            name="botanischeBezeichnungGattung"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <NumberField
            label="Höhe min"
            value={pflanze.hoeheMin}
            name="hoeheMin"
            fraction={4}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <NumberField
            label="Höhe max"
            value={pflanze.hoeheMax}
            name="hoeheMax"
            fraction={4}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <TextField
            label="Bezeichnung"
            value={pflanze.deutscheBezeichnung}
            name="deutscheBezeichnung"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <TextField
            label="Lebenszyklus"
            value={pflanze.lebenszyklus}
            name="lebenszyklus"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <TextField
            label="Qualität"
            value={pflanze.qualitaet}
            name="qualitaet"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <TextField
            label="Wuchsform"
            value={pflanze.wuchsform}
            name="wuchsform"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <TextField
            label="Standort"
            value={pflanze.standort}
            name="standort"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <TextField
            label="Topfgrösse"
            value={pflanze.topfgroesse}
            name="topfgroesse"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <Checkbox
            indeterminate
            label="Blüte"
            checked={pflanze.bluete}
            name="bluete"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <Checkbox
            indeterminate
            label="Duft"
            checked={pflanze.duft}
            name="duft"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <Checkbox
            indeterminate
            label="Platzierung hängend"
            checked={pflanze.platzierungHaengend}
            name="platzierungHaengend"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <Checkbox
            indeterminate
            label="Platzierung stellbar"
            checked={pflanze.platzierungStellbar}
            name="platzierungStellbar"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
          <Checkbox
            indeterminate
            label="Winterhart"
            checked={pflanze.winterhart}
            name="winterhart"
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelPflanzenMemo = React.memo(PimArtikelPflanzen)
