import { NeonKontext } from '@one/typings/apiTypings'
import { ChipContainer } from '@utils/ui/fields/ChipContainer'

export const NeonKontextChips = ({ value }: { value: NeonKontext[] }) => {
  return (
    <ChipContainer
      value={value?.sort((a, b) => a.localeCompare(b))}
      getValueLabel={(label) => label.substr(2)}
      compact
    />
  )
}
