import { Delete } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import { api } from '@one/api'
import { AppPaths } from '@one/AppPaths'
import { formatArtikel, formatLieferantLang } from '@one/components/common/formatters'
import { PimMengeneinheitField } from '@one/components/common/PimMengeneinheitField'
import { getLieferantLink } from '@one/components/Lieferant/LieferantUtils'
import {
  ArtikelDisplayJson,
  NeonKontext,
  PimArtikelJson,
  PimArtikelVersionLieferantJson,
  PimLandDisplayJson,
  PimMengeneinheitDisplayJson,
  SuchArtikelLieferantListeJson
} from '@one/typings/apiTypings'
import { ApiExclusive, useApiCaller } from '@utils/apicaller'
import { ModelAction, ValueChangeFn } from '@utils/modelmgr'
import { Button } from '@utils/ui/Buttons/Button'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { DateField } from '@utils/ui/fields/DateField'
import { NumberField } from '@utils/ui/fields/NumberField'
import { StaticField } from '@utils/ui/fields/StaticField'
import { TextField } from '@utils/ui/fields/TextField'
import { Link } from '@utils/ui/Navigation/Link'
import { aidOf, restartTimer, trimStringToNull } from '@utils/utils'
import React, { useEffect, useRef, useState } from 'react'
import { EigenlistungsArtikelUseCase } from './model/EigenlistungsArtikelUseCase'
import { PimArtikelLieferantFrachkosten } from './PimArtikelLieferantFrachkosten'

type PimArtikelLieferantProps = {
  hageNummer: string
  pimArtikelVersionLieferant: PimArtikelVersionLieferantJson
  mengeneinheiten: PimMengeneinheitDisplayJson[]
  laender: PimLandDisplayJson[]
  kontext: NeonKontext
  onChange: ValueChangeFn<PimArtikelJson>
  dispatch: (action: ModelAction) => void
  errors: any
  idx: number
}

export const PimArtikelLieferant = ({
  hageNummer,
  pimArtikelVersionLieferant,
  mengeneinheiten,
  laender,
  kontext,
  onChange,
  dispatch,
  errors,
  idx
}: PimArtikelLieferantProps) => {
  const [apiCall, apiBusy] = useApiCaller(api)

  const [ianError, setIanError] = useState<string | null>(null)
  const [ianArtikel, setIanArtikel] = useState<ArtikelDisplayJson | null>(null)

  const timerRef = useRef<number | null>(null)

  const lieferant = pimArtikelVersionLieferant.lieferant

  const fieldPath = (field: string) =>
    `pimArtikel.version.lieferanten.[__aid=${aidOf(pimArtikelVersionLieferant)}].${field}`

  const fieldPath2 = (field: string) => `pimArtikel.version.lieferanten.[${idx}].${field}`

  const onRemoveLieferant = () => {
    dispatch({ type: EigenlistungsArtikelUseCase.REMOVELIEFERANT, pimArtikelVersionLieferant })
  }

  const onChangeIAN = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e)
    const ian = trimStringToNull(e.target.value)
    if (ian != null) {
      restartTimer(
        timerRef,
        () => {
          apiCall<SuchArtikelLieferantListeJson>({
            rest: '/artikel/searchByIAN',
            method: 'GET',
            params: {
              lieferantId: lieferant.id,
              kontext,
              ian
            },
            exclusive: ApiExclusive.CANCEL,
            onSuccess: (data) => {
              const length = data.items?.length ?? 0
              if (length > 1 || (length == 1 && data.items[0].artikel.hageNummer !== hageNummer)) {
                setIanError('IAN wird bereits verwendet.')
                setIanArtikel(data.items[0].artikel)
              } else {
                setIanError(null)
                setIanArtikel(null)
              }
            }
          })
        },
        250
      )
    }
  }

  useEffect(() => {
    setIanError(null)
    setIanArtikel(null)
  }, [laender]) // als trigger bei save/load...

  return (
    <CardEx
      title={
        <div>
          <span>Lieferant </span>
          <Link to={getLieferantLink(lieferant.typ, lieferant.id)}>
            {formatLieferantLang(pimArtikelVersionLieferant.lieferant)}
          </Link>
        </div>
      }
      topActions={
        <Button
          StartIcon={Delete}
          label="Entfernen"
          color="warning"
          onClickVoid={onRemoveLieferant}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="IAN"
              value={pimArtikelVersionLieferant.ian}
              name={fieldPath('ian')}
              error={ianError}
              onChange={onChangeIAN}
              fullWidth
            />
          </Grid>
          {ianArtikel && (
            <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
              <StaticField label="IAN verwendet in Artikel" error>
                <Link to={AppPaths.ArtikelFn(ianArtikel.id)}>
                  <Typography color="error">{formatArtikel(ianArtikel)}</Typography>
                </Link>
              </StaticField>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Warengruppe"
              value={pimArtikelVersionLieferant.warengruppe}
              name={fieldPath('warengruppe')}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Unterwarengruppe"
              value={pimArtikelVersionLieferant.unterWarengruppe}
              name={fieldPath('unterWarengruppe')}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <NumberField
              label="Einkäufergruppe"
              value={pimArtikelVersionLieferant.einkaeufergruppe}
              name={fieldPath('einkaeufergruppe')}
              onChange={onChange}
              fullWidth
              fraction={0}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Teilsortiment"
              value={pimArtikelVersionLieferant.teilsortiment}
              name={fieldPath('teilsortiment')}
              onChange={onChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="FSC-Claim"
              value={pimArtikelVersionLieferant.fscClaim}
              name={fieldPath('fscClaim')}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <Checkbox
              label="FSC-Zertifiziert"
              checked={pimArtikelVersionLieferant.fscZertifiziert}
              name={fieldPath('fscZertifiziert')}
              onChange={onChange}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="PEFC-Claim"
              value={pimArtikelVersionLieferant.pefcClaim}
              name={fieldPath('pefcClaim')}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <Checkbox
              label="PEFC-Zertifiziert"
              checked={pimArtikelVersionLieferant.pefcZertifiziert}
              name={fieldPath('pefcZertifiziert')}
              onChange={onChange}
              size="small"
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <NumberField
              label="Lieferzeit zur Hagebau Logistik"
              value={pimArtikelVersionLieferant.lieferzeitZurHagebauLogistik}
              name={fieldPath('lieferzeitZurHagebauLogistik')}
              onChange={onChange}
              fraction={0}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Herstellerartikelnummer"
              value={pimArtikelVersionLieferant.herstellerartikelnummer}
              name={fieldPath('herstellerartikelnummer')}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Sourcing Office"
              value={pimArtikelVersionLieferant.sourcingOffice}
              name={fieldPath('sourcingOffice')}
              onChange={onChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <Checkbox
              label="Hauptlieferant"
              name={fieldPath('kontext.hauptlieferant')}
              checked={pimArtikelVersionLieferant.kontext?.hauptlieferant}
              onChange={onChange}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <Checkbox
              label="Ausgelistet"
              name={fieldPath('kontext.ausgelistet')}
              checked={pimArtikelVersionLieferant.kontext?.ausgelistet}
              onChange={onChange}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <Checkbox
              label="Reiner Werbeartikel"
              name={fieldPath('kontext.reinerWerbeartikel')}
              checked={pimArtikelVersionLieferant.kontext?.reinerWerbeartikel}
              onChange={onChange}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <Checkbox
              label="Import-Kennzeichen"
              checked={pimArtikelVersionLieferant.importKennzeichen}
              name={fieldPath('importKennzeichen')}
              onChange={onChange}
              size="small"
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <DateField
              label="EK-Sperre ab"
              value={pimArtikelVersionLieferant.kontext?.ekSperreAb}
              name={fieldPath('kontext.ekSperreAb')}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <DateField
              label="EK-Sperre bis"
              value={pimArtikelVersionLieferant.kontext?.ekSperreBis}
              name={fieldPath('kontext.ekSperreBis')}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="EK-Sperre Grund"
              value={pimArtikelVersionLieferant.kontext?.ekSperreGrund}
              name={fieldPath('kontext.ekSperreGrund')}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
              <PimMengeneinheitField
                label="BME"
                name={fieldPath('kontext.bme')}
                values={mengeneinheiten}
                value={pimArtikelVersionLieferant.kontext?.bme}
                onChange={onChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
              <PimMengeneinheitField
                label="LME1"
                name={fieldPath('kontext.lme1')}
                value={pimArtikelVersionLieferant.kontext?.lme1}
                onChange={onChange}
                values={mengeneinheiten}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
              <PimMengeneinheitField
                label="LME2"
                name={fieldPath('kontext.lme2')}
                value={pimArtikelVersionLieferant.kontext?.lme2}
                onChange={onChange}
                values={mengeneinheiten}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
              <PimMengeneinheitField
                label="LME3"
                name={fieldPath('kontext.lme3')}
                value={pimArtikelVersionLieferant.kontext?.lme3}
                onChange={onChange}
                values={mengeneinheiten}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
              <PimMengeneinheitField
                label="LME4"
                name={fieldPath('kontext.lme4')}
                value={pimArtikelVersionLieferant.kontext?.lme4}
                onChange={onChange}
                values={mengeneinheiten}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
              <PimMengeneinheitField
                label="LME5"
                name={fieldPath('kontext.lme5')}
                value={pimArtikelVersionLieferant.kontext?.lme5}
                onChange={onChange}
                values={mengeneinheiten}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Alternative Artikel Nummer"
              name={fieldPath('kontext.alternativeArtikelNummer')}
              value={pimArtikelVersionLieferant.kontext?.alternativeArtikelNummer}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <NumberField
              label="Bereitstellungszeit"
              name={fieldPath('kontext.bereitstellungszeit')}
              value={pimArtikelVersionLieferant.kontext?.bereitstellungszeit}
              fraction={0}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Biozid Zulassungsnummer"
              name={fieldPath('kontext.biozidZulassungsnummer')}
              value={pimArtikelVersionLieferant.kontext?.biozidZulassungsnummer}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Biozid Zulassungsnummer Zulassungsende"
              name={fieldPath('kontext.biozidZulassungsnummerZulassungsende')}
              value={pimArtikelVersionLieferant.kontext?.biozidZulassungsnummerZulassungsende}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Freigabe Änderungsdienst"
              name={fieldPath('kontext.freigabeAenderungsdienst')}
              value={pimArtikelVersionLieferant.kontext?.freigabeAenderungsdienst}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Incoterms"
              name={fieldPath('kontext.incoterms')}
              value={pimArtikelVersionLieferant.kontext?.incoterms}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Incoterms Abgangshafen"
              name={fieldPath('kontext.incotermsAbgangshafen')}
              value={pimArtikelVersionLieferant.kontext?.incotermsAbgangshafen}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Incoterms Empfangshafen"
              name={fieldPath('kontext.incotermsEmpfangshafen')}
              value={pimArtikelVersionLieferant.kontext?.incotermsEmpfangshafen}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <NumberField
              label="Intrastat Menge"
              name={fieldPath('kontext.intrastatMenge')}
              fraction={0}
              value={pimArtikelVersionLieferant.kontext?.intrastatMenge}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <DateField
              label="Keine Anmahnung Bestandsmeldung Bis"
              name={fieldPath('kontext.keineAnmahnungBestandsmeldungBis')}
              value={pimArtikelVersionLieferant.kontext?.keineAnmahnungBestandsmeldungBis}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <NumberField
              label="Kombityp"
              name={fieldPath('kontext.kombityp')}
              value={pimArtikelVersionLieferant.kontext?.kombityp}
              onChange={onChange}
              fraction={0}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <NumberField
              label="Lieferzeit"
              name={fieldPath('kontext.lieferzeit')}
              value={pimArtikelVersionLieferant.kontext?.lieferzeit}
              onChange={onChange}
              fraction={0}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Listungsstatus"
              name={fieldPath('kontext.listungsstatus')}
              value={pimArtikelVersionLieferant.kontext?.listungsstatus}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <DateField
              label="Listungsstatus Gültig Ab"
              name={fieldPath('kontext.listungsstatusGueltigAb')}
              value={pimArtikelVersionLieferant.kontext?.listungsstatusGueltigAb}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <DateField
              label="MAM Gültig Ab"
              name={fieldPath('kontext.mamGueltigAb')}
              value={pimArtikelVersionLieferant.kontext?.mamGueltigAb}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <DateField
              label="MAM Gültig Bis"
              name={fieldPath('kontext.mamGueltigBis')}
              value={pimArtikelVersionLieferant.kontext?.mamGueltigBis}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <NumberField
              label="Mindestbestellmenge Lieferant"
              name={fieldPath('kontext.mindestbestellmengeLieferant')}
              value={pimArtikelVersionLieferant.kontext?.mindestbestellmengeLieferant}
              onChange={onChange}
              fraction={0}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Pflanzenschutzregistrier Nummer"
              name={fieldPath('kontext.pflanzenschutzregistrierNummer')}
              value={pimArtikelVersionLieferant.kontext?.pflanzenschutzregistrierNummer}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Pflanzenschutzregistrier Nummer Zulassungsende"
              name={fieldPath('kontext.pflanzenschutzregistrierNummerZulassungsende')}
              value={
                pimArtikelVersionLieferant.kontext?.pflanzenschutzregistrierNummerZulassungsende
              }
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Retouren Wiederverwertungskennzeichen"
              name={fieldPath('kontext.retourenWiederverwertungskennzeichen')}
              value={pimArtikelVersionLieferant.kontext?.retourenWiederverwertungskennzeichen}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Retourenziel"
              name={fieldPath('kontext.retourenziel')}
              value={pimArtikelVersionLieferant.kontext?.retourenziel}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Sortiments Kennzeichen Hagebau Logistik"
              name={fieldPath('kontext.sortimentsKennzeichenHagebauLogistik')}
              value={pimArtikelVersionLieferant.kontext?.sortimentsKennzeichenHagebauLogistik}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Steuerung Transport Dienstleister"
              name={fieldPath('kontext.steuerungTransportDienstleister')}
              value={pimArtikelVersionLieferant.kontext?.steuerungTransportDienstleister}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Versandsystem Endkunde"
              name={fieldPath('kontext.versandsystemEndkunde')}
              value={pimArtikelVersionLieferant.kontext?.versandsystemEndkunde}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Versandweg Endkunde"
              name={fieldPath('kontext.versandwegEndkunde')}
              value={pimArtikelVersionLieferant.kontext?.versandwegEndkunde}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
            <TextField
              label="Warenrücksendung Lieferantenretoure"
              name={fieldPath('kontext.warenruecksendungLieferantenretoure')}
              value={pimArtikelVersionLieferant.kontext?.warenruecksendungLieferantenretoure}
              onChange={onChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PimArtikelLieferantFrachkosten
            laender={laender}
            pimArtikelVersionLieferant={pimArtikelVersionLieferant}
            onChange={onChange}
            dispatch={dispatch}
            errors={errors}
            idx={idx}
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelLieferantMemo = React.memo(PimArtikelLieferant)
