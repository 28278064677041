import { Add, Delete } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { PimArtikelVersionFlieseJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ModelAction, ValueChangeFn } from '@utils/modelmgr'
import { Button } from '@utils/ui/Buttons/Button'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { NumberField } from '@utils/ui/fields/NumberField'
import { TextField } from '@utils/ui/fields/TextField'
import React from 'react'
import { EigenlistungsArtikelUseCase } from './model/EigenlistungsArtikelUseCase'

export interface PimArtikelFlieseProps {
  fliese: PimArtikelVersionFlieseJson
  onChange: ValueChangeFn<SeArtikelJson>
  dispatch: (action: ModelAction) => void
  errors: any
}

export const PimArtikelFliese = ({ fliese, onChange, dispatch, errors }: PimArtikelFlieseProps) => {
  const onAddFliese = () => {
    dispatch({
      type: EigenlistungsArtikelUseCase.ADDFLIESE
    })
  }
  const onRemoveFliese = () => {
    dispatch({
      type: EigenlistungsArtikelUseCase.REMOVEFLIESE
    })
  }
  if (fliese == null) {
    return (
      <Button
        label="Fliese hinzufügen"
        variant="contained"
        StartIcon={Add}
        onClickVoid={onAddFliese}
      />
    )
  }
  return (
    <CardEx
      title="Fliese"
      name="fliese"
      topActions={
        <Button label="Entfernen" StartIcon={Delete} onClickVoid={onRemoveFliese} color="warning" />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Kategorie"
            value={fliese.kategorie}
            name="pimArtikel.version.fliese.kategorie"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Serienname"
            value={fliese.serienname}
            name="pimArtikel.version.fliese.serienname"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Angebotstext 1"
            value={fliese.angebotstext1}
            name="pimArtikel.version.fliese.angebotstext1"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Angebotstext 2"
            value={fliese.angebotstext2}
            name="pimArtikel.version.fliese.angebotstext2"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}></Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Produktbreite"
            value={fliese.produktBreite}
            name="pimArtikel.version.fliese.produktBreite"
            onChange={onChange}
            fraction={0}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Produktlänge"
            value={fliese.produktLaenge}
            name="pimArtikel.version.fliese.produktLaenge"
            onChange={onChange}
            fraction={0}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Produkthöhe"
            value={fliese.produktHoehe}
            name="pimArtikel.version.fliese.produktHoehe"
            fraction={0}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Nennbreite"
            value={fliese.nennBreite}
            name="pimArtikel.version.fliese.nennBreite"
            fraction={0}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Nennlänge"
            value={fliese.nennLaenge}
            name="pimArtikel.version.fliese.nennLaenge"
            fraction={0}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Verdrängungsraum"
            value={fliese.verdraengungsraum}
            name="pimArtikel.version.fliese.verdraengungsraum"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Farbe"
            value={fliese.farbe}
            name="pimArtikel.version.fliese.farbe"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Farbfamilie"
            value={fliese.farbfamilie}
            name="pimArtikel.version.fliese.farbfamilie"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Material"
            value={fliese.material}
            name="pimArtikel.version.fliese.material"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Oberfläche"
            value={fliese.oberflaeche}
            name="pimArtikel.version.fliese.oberflaeche"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Design"
            value={fliese.design}
            name="pimArtikel.version.fliese.design"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Einsatzbereich"
            value={fliese.einsatzbereich}
            name="pimArtikel.version.fliese.einsatzbereich"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Nassbereich"
            value={fliese.nassbereich}
            name="pimArtikel.version.fliese.nassbereich"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Rutschfestigkeit"
            value={fliese.rutschfestigkeit}
            name="pimArtikel.version.fliese.rutschfestigkeit"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Abriebgrupppe"
            value={fliese.abriebgruppe}
            name="pimArtikel.version.fliese.abriebgruppe"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Formatgruppe"
            value={fliese.formatgruppe}
            name="pimArtikel.version.fliese.formatgruppe"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Gewicht pro Quadratmeter"
            value={fliese.gewichtProM2}
            name="pimArtikel.version.fliese.gewichtProM2"
            fraction={4}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Inhalt pro Palette"
            value={fliese.inhaltProPaletteM2}
            name="pimArtikel.version.fliese.inhaltProPaletteM2"
            fraction={4}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Inhalt pro Karton"
            value={fliese.inhaltProKartonM2}
            name="pimArtikel.version.fliese.inhaltProKartonM2"
            fraction={4}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Kantenausbildung"
            value={fliese.kantenausbildung}
            name="pimArtikel.version.fliese.kantenausbildung"
            fraction={0}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Oberflächenveredelung"
            value={fliese.oberflaechenveredelung}
            name="pimArtikel.version.fliese.oberflaechenveredelung"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            indeterminate
            label="Glasierung"
            checked={fliese.glasierung}
            name="pimArtikel.version.fliese.glasierung"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            indeterminate
            label="Frostsicher"
            checked={fliese.frostsicher}
            name="pimArtikel.version.fliese.frostsicher"
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelFlieseMemo = React.memo(PimArtikelFliese)
