import { Box, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { grey } from '@mui/material/colors'
import { StandortField } from '@one/components/common/StandortField'
import { formatDate, formatDateRange } from '@utils/dateutils'
import { useFormState } from '@utils/formstate'
import { useLocalState } from '@utils/localState'
import { CardEx } from '@utils/ui/CardEx'
import { ScrollPanel } from '@utils/ui/ScrollPanel'
import { ThemeContext } from '@utils/ui/Theme'
import { TooltipEx, TooltipWrapper } from '@utils/ui/TooltipWrapper'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { FormField } from '@utils/ui/fields/FormField'
import { SelectField } from '@utils/ui/fields/SelectField'
import { StaticField } from '@utils/ui/fields/StaticField'
import { asNumber, compareStrings, formatMoney2, safeArray } from '@utils/utils'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { EkPreisKonditionTable } from './EkPreisKonditionTable'
import { RabattgruppeField } from './fields/RabattgruppeField'
import { RabattverwendungField } from './fields/RabattverwendungField'
import { StaffelField } from './fields/StaffelField'
import { StaffelgruppenField } from './fields/StaffelgruppenField'
import {
  Ansicht,
  EkKonditionenEdit,
  EkKonditionenWertEdit,
  EkPreiseBearbeitenEdit
} from './model/EkPreiseTypes'
import { EkPreiseUsecase, checkStaffelwerte, pruefeAbschliessbar } from './model/EkPreiseUsecase'

const colors = [
  [
    // darkMode
    ['#6d6418', '#5f5929'],
    ['#425863', '#394e58']
  ],
  [
    // light
    ['#f9fbe7', '#f0f3d5'],
    ['#eceff1', '#dee3e6']
  ]
]

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()((theme: any) => ({
  view: {
    marginTop: 16,
    '& button': {
      paddingTop: 4,
      paddingBottom: 4
    }
  },
  pager: {
    justifyContent: 'flex-end',
    flexGrow: 1,
    display: 'flex'
  },
  abgeschlossen: {
    marginTop: '-12px',
    '& .MuiTypography-root': {
      fontSize: '92%'
    }
  }
}))

type FormProps = {
  ansicht: Ansicht
  standortEk?: boolean
  standortId?: any
  staffelWert: number
}
export interface EkPreisPflegeBodyProps {
  dispatch: (action: any) => void
  model: EkPreiseBearbeitenEdit
  errors?: object
}
export const EkPreisPflegeBody = ({ dispatch, model, errors }: EkPreisPflegeBodyProps) => {
  const { classes } = useStyles()

  const { darkMode } = useContext(ThemeContext)

  const [filterState, onChange] = useFormState<FormProps>({
    ansicht: Ansicht.STANDORT,
    standortEk: true,
    standortId: null,
    staffelWert: 0
  })

  const [allianzAllowZuschl, setAllianzAllowZuschl] = useLocalState('allianzAllowZuschl', false)

  const [gueltigPos, setGueltigPos] = useState(0)

  useEffect(() => {
    onChange((old) => ({ ...old, standortId: model.standort0?.id }))
    setGueltigPos(0)
  }, [model.standort0?.id, onChange])

  const [gueltigkeiten, kondi, last] = useMemo(() => {
    const combined = [model.neueKondi, ...safeArray(model.vergangeneListe)]
    const gueltigkeiten = combined.map((k, idx) => ({
      ...k,
      rf: idx === 0 ? 0 : combined.length - idx
    }))
    return [
      gueltigkeiten,
      gueltigkeiten[gueltigPos],
      gueltigPos < gueltigkeiten.length - 1
        ? gueltigkeiten[gueltigPos + 1]
        : ({ konditionsEbenen: [] } as EkKonditionenEdit)
    ]
  }, [model.neueKondi, model.vergangeneListe, gueltigPos])

  const listenPreisInfo = useMemo(() => {
    const preismenge =
      kondi.preismenge == null ? null : (
        <span>
          &nbsp;- Preismenge: <span style={{ fontSize: '120%' }}>{kondi.preismenge}</span>
          {kondi.mengenEinheit}
        </span>
      )

    if (kondi.listenpreisAusAlterKondition != null) {
      const tooltip =
        (kondi.listenpreis != null && kondi.listenpreis !== kondi.listenpreisAusAlterKondition && (
          <span>
            Zentraler Listenpreis {formatMoney2(kondi.listenpreis)}
            {model.kondiDefinition.waehrung}
            {kondi.listenpreisGueltigVon != null
              ? ' vom ' + formatDate(kondi.listenpreisGueltigVon)
              : null}{' '}
            durch Vorgängerpreisblatt überschrieben
          </span>
        )) ||
        (kondi.listenpreis != null &&
          kondi.listenpreis === kondi.listenpreisAusAlterKondition &&
          'Listenpreis identisch mit dem aus Zentrale.') ||
        null
      return (
        <TooltipWrapper arrow title={tooltip}>
          Listenpreis:
          <span style={{ fontSize: '120%' }}>
            {' '}
            {formatMoney2(kondi.listenpreisAusAlterKondition)}
            {model.kondiDefinition.waehrung}
          </span>
          {preismenge}
        </TooltipWrapper>
      )
    } else if (kondi.listenpreis != null) {
      return (
        <TooltipWrapper arrow title="Listenpreis stammt aus Zentrale.">
          Listenpreis
          {kondi.listenpreisGueltigVon != null
            ? ' ab ' + formatDate(kondi.listenpreisGueltigVon)
            : null}
          {': '}
          <span style={{ fontSize: '120%' }}>
            {' '}
            {formatMoney2(kondi.listenpreis)}
            {model.kondiDefinition.waehrung}
          </span>
          {preismenge}
        </TooltipWrapper>
      )
    } else {
      return (
        <TooltipEx
          arrow
          style={{ cursor: 'help' }}
          title="Listenpreis ist weder aus Zentrale noch aus Vorgängerpreisblatt vorhanden."
        >
          <span>Kein Listenpreis vorhanden{preismenge}</span>
        </TooltipEx>
      )
    }
  }, [
    kondi.listenpreis,
    kondi.listenpreisAusAlterKondition,
    kondi.listenpreisGueltigVon,
    kondi.mengenEinheit,
    kondi.preismenge,
    model.kondiDefinition.waehrung
  ])

  const readonly =
    model.freigegeben ||
    model.invalid ||
    model.neueKondi == null ||
    model.neueKondi.abgeschlossen ||
    gueltigPos > 0

  const aktKondiEbene =
    filterState.standortId != null
      ? kondi.konditionsEbenen?.find((ke) => ke.id === filterState.standortId)
      : null

  const standorteEx = useMemo(() => {
    const idx = new Set(
      [kondi, last]
        .filter(Boolean)
        .reduce((a, c) => {
          c?.konditionsEbenen?.forEach((ke) => (a = a.concat(ke.werte)))
          return a
        }, [])
        .filter((w) => (readonly ? w.standortEkOrg : w.standortEk))
        .map((w) => w.standortId)
        .filter((w, i, s) => s.indexOf(w) === i)
    )
    return (model.preisEbenen || []).map((s) => ({ ...s, hatPreis: idx.has(s.id) }))
  }, [kondi, last, model.preisEbenen, readonly])

  const staffelwerte = useMemo(() => {
    const werte =
      filterState.ansicht === Ansicht.STAFFEL
        ? aktKondiEbene?.werte
        : kondi.konditionsEbenen
            ?.filter((ke) => ke.werte.find((w) => w.standortEk) != null)
            .reduce((a, ke) => a.concat(ke.werte), [])
    return werte
      ? werte
          .filter((w) => (readonly ? w.standortEkOrg : w.standortEk))
          .map((w) => w.staffelmenge)
          .filter((w, i, s) => s.indexOf(w) === i)
          .sort((a, b) => a - b) || []
      : []
  }, [aktKondiEbene?.werte, filterState.ansicht, kondi.konditionsEbenen, readonly])

  useEffect(() => {
    if (staffelwerte == null || staffelwerte.find((w) => w === filterState.staffelWert) == null) {
      onChange({ name: 'staffelWert', value: 0 })
    }
  }, [filterState.staffelWert, staffelwerte, onChange])

  const sortKondi = useCallback(
    (a: EkKonditionenWertEdit, b: EkKonditionenWertEdit): number => {
      if (filterState.ansicht === 'STANDORT') {
        const r1 =
          a.preisEbene?.reihe != null && b.preisEbene?.reihe != null
            ? a.preisEbene?.reihe - b.preisEbene?.reihe
            : 0
        if (r1 !== 0) {
          return r1
        }
        const r2 = compareStrings(a.preisEbene?.nr, b.preisEbene?.nr)
        if (r2 !== 0) {
          return r2
        }
      }
      if (a.staffelmenge < b.staffelmenge) return -1
      if (a.staffelmenge > b.staffelmenge) return +1
      if (a.level === b.level) return 0
      if (a.level === 0) return +1
      if (b.level === 0) return -1
      if (a.level < b.level) return -1
      if (a.level > b.level) return +1
      return 0
    },
    [filterState.ansicht]
  )

  //     if (used.length > 0) {
  //       askToConfirm({
  //         title: 'Einige Staffelmengen haben Werte, trotzdem die Staffelgruppe anwenden?',
  //         severity: MessageDialogSeverity.SEVERE,
  //         message: 'Die erfassten Werte gehen verloren.',
  //         confirmLabel: 'Staffelgruppe anwenden',
  //         onConfirm: modify
  //       })
  //     } else {
  //       modify()
  //     }

  /**
   * Suche alle Konditionen für die aktuelle Ansicht zusammen
   * a) Alle Staffelwerte zu gewähltem Standort
   * b) Alle Standorte zu gewählter Staffelmenge
   */
  const konditionen = useMemo<EkKonditionenWertEdit[]>(() => {
    const findStandortWerte = (ek: EkKonditionenEdit) =>
      (
        ek?.konditionsEbenen?.find((it) => it.id === filterState.standortId) || {
          werte: []
        }
      ).werte

    const findStaffelWerte = (ek: EkKonditionenEdit) =>
      ek?.konditionsEbenen
        ?.map((it) => it.werte.find((w) => w.staffelmenge === filterState.staffelWert))
        .filter(Boolean) || []

    let result = null
    if (filterState.ansicht === Ansicht.STAFFEL) {
      const kew = findStandortWerte(kondi)
      const lkew = findStandortWerte(last)
      result = kew.concat(lkew)
    } else {
      const kew = findStaffelWerte(kondi)
      const lkew = findStaffelWerte(last)
      const combined = kew.concat(lkew)
      if (filterState.standortEk || readonly) {
        result = combined.filter((w) => w.standortEk || w.standortEkOrg)
      } else {
        result = combined
      }
    }
    const sorted = result.sort(sortKondi)
    return sorted
  }, [
    filterState.ansicht,
    filterState.standortId,
    filterState.staffelWert,
    filterState.standortEk,
    sortKondi,
    kondi,
    last,
    readonly
  ])

  const colorMapStaffelansicht = useMemo(() => {
    const m = new Map()
    konditionen
      .map((k) => k.staffelmenge)
      .filter((w, i, s) => s.indexOf(w) === i)
      .forEach((staffelmenge, idx) => {
        m.set(staffelmenge, colors[darkMode ? 0 : 1][idx % colors.length])
      })
    return m
  }, [darkMode, konditionen])

  const colorMapStandortsicht = useMemo(() => {
    const m = new Map()
    konditionen
      .map((k) => k.standortId)
      .filter((w, i, s) => s.indexOf(w) === i)
      .forEach((standortId, idx) => {
        m.set(standortId, colors[darkMode ? 0 : 1][idx % colors.length])
      })
    return m
  }, [darkMode, konditionen])

  const getDataColor = useCallback(
    (row: EkKonditionenWertEdit): [string, string | undefined] | null => {
      const c =
        filterState.ansicht === Ansicht.STANDORT
          ? colorMapStandortsicht.get(row.standortId)
          : colorMapStaffelansicht.get(row.staffelmenge)
      const odd = row.level == kondi.rf
      return [c == null ? null : c[odd ? 1 : 0], odd ? undefined : grey[500]]
    },
    [colorMapStaffelansicht, colorMapStandortsicht, filterState.ansicht, kondi.rf]
  )

  const header = useMemo(() => {
    return (
      <Grid container direction="column">
        <Grid item width="100%">
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Grid container direction="row" spacing={1}>
                <Grid item>
                  <SelectField<EkKonditionenEdit>
                    options={gueltigkeiten}
                    label="Preise gültig ab"
                    renderItem={(opt) => (
                      <span>
                        <small style={{ fontSize: '80%' }}>
                          {opt.rf > 0 ? `Version ${opt.rf}: ` : ''}
                        </small>
                        <span>{formatDateRange(opt.gueltigVon, opt.gueltigBis)}</span>
                      </span>
                    )}
                    value={gueltigPos}
                    optionValue={(_opt, idx) => idx}
                    onChange={(e) => setGueltigPos(asNumber(e.target.value))}
                    notNull
                    withButtons="reverse"
                  />
                </Grid>
                {last?.gueltigVon && (
                  <Grid item>
                    <StaticField
                      label={`Gültigkeit Version ${last.rf}`}
                      value={formatDateRange(last.gueltigVon, last.gueltigBis)}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Box>{listenPreisInfo}</Box>
            </Grid>
            <Grid item>
              {model.allianzModus ? (
                <Checkbox
                  checked={allianzAllowZuschl}
                  label="Absolute Rabatte"
                  onChange={() => setAllianzAllowZuschl(!allianzAllowZuschl)}
                />
              ) : (
                <Checkbox
                  label="S-Preis rabattierbar"
                  disabled={gueltigPos !== 0 || model.freigegeben}
                  checked={kondi.sonderpreisRabattierbar}
                  name="sonderpreisRabattierbar"
                  onChange={(e) =>
                    dispatch({
                      type: EkPreiseUsecase.SETSONDERPREISRABATTIERBAR,
                      value: !kondi.sonderpreisRabattierbar
                    })
                  }
                />
              )}
              <Checkbox
                label="Abgeschlossen"
                disabled={gueltigPos !== 0 || model.freigegeben || !pruefeAbschliessbar(kondi)}
                checked={kondi.abgeschlossen}
                name="abgeschlossen"
                onChange={(e) =>
                  dispatch({
                    type: EkPreiseUsecase.SETEBENEABGESCHLOSSEN,
                    value: !kondi.abgeschlossen
                  })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item width="100%">
          <Grid
            container
            direction="row"
            spacing={2}
            paddingTop={0}
            alignItems="center"
            height="auto"
          >
            <Grid item>
              <FormField label="Ansicht">
                <ToggleButtonGroup
                  size="small"
                  exclusive
                  value={filterState.ansicht}
                  onChange={onChange}
                  className={classes.view}
                >
                  <ToggleButton value="STANDORT" size="small" name="ansicht">
                    {model.kondiDefinition.standortLabel}
                  </ToggleButton>
                  <ToggleButton value="STAFFEL" size="small" name="ansicht">
                    Staffel
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormField>
            </Grid>

            {!readonly && filterState.ansicht === Ansicht.STANDORT && (
              <Grid item marginTop="8px">
                <Checkbox
                  label="Nur Aktive"
                  checked={filterState.standortEk}
                  name="standortEk"
                  onChange={onChange}
                  color="default"
                />
              </Grid>
            )}

            {filterState.ansicht !== Ansicht.STANDORT && (
              <>
                <Grid item xs={4} sm={2}>
                  <StandortField
                    label={model.kondiDefinition?.standortLabel || 'Standort'}
                    options={standorteEx}
                    value={filterState.standortId}
                    name="standortId"
                    onChange={onChange}
                    fullWidth
                    withButtons
                    required
                  />
                </Grid>
                <Grid item xs={4} sm={2}>
                  <RabattgruppeField
                    label="EK-Rabattgruppe"
                    options={model.ekRabattgruppen}
                    value={aktKondiEbene?.effektiveRabattgruppeId}
                    artikelRabattgruppeId={model.neueKondi.artikelRabattgruppeId}
                    referencePreisEbeneId={aktKondiEbene?.preisEbene?.id}
                    allianzModus={model.allianzModus}
                    onChange={(value) =>
                      dispatch({
                        type: EkPreiseUsecase.SETEKRABATTGRUPPE,
                        kondiEbeneId: aktKondiEbene?.id,
                        value
                      })
                    }
                    readonly={readonly}
                  />
                </Grid>
                <Grid item xs={4} sm={2}>
                  <RabattverwendungField
                    label="Rabattverwendung"
                    value={aktKondiEbene?.rabattVerwendung}
                    onChange={(value) =>
                      dispatch({
                        type: EkPreiseUsecase.SETEKRABATTVERWENDUNG,
                        kondiEbeneId: aktKondiEbene?.id,
                        value
                      })
                    }
                    withText
                    required={!!aktKondiEbene?.effektiveRabattgruppeId}
                    readonly={readonly || aktKondiEbene?.effektiveRabattgruppeId == null}
                  />
                </Grid>
                <Grid item xs={4} sm={2}>
                  <StaffelgruppenField
                    label="Staffelgruppe"
                    options={model.staffelgruppen}
                    value={aktKondiEbene?.effektiveStaffelgruppeId}
                    onChange={(e) =>
                      dispatch({
                        type: EkPreiseUsecase.SETSTAFFELGRUPPE,
                        kondiEbeneId: aktKondiEbene?.id,
                        value: e.target.value
                      })
                    }
                    readonly={readonly}
                  />
                </Grid>
              </>
            )}

            <Grid item alignSelf="end">
              <StaffelField
                buttonMode={filterState.ansicht !== Ansicht.STANDORT}
                wert={filterState.staffelWert}
                name="staffelWert"
                setWert={onChange}
                werte={staffelwerte}
                onWerteChange={(value) => {
                  dispatch({
                    type: EkPreiseUsecase.SETSTAFFELWERTE,
                    kondiEbeneId: aktKondiEbene?.id,
                    value
                  })
                }}
                checkStaffelwerte={() => checkStaffelwerte(aktKondiEbene)}
                readonly={
                  readonly ||
                  filterState.ansicht === Ansicht.STANDORT ||
                  staffelwerte == null ||
                  staffelwerte.length === 0 ||
                  (model.staffelgruppeManuellId == null &&
                    aktKondiEbene?.staffelgruppeId == null) ||
                  (aktKondiEbene?.staffelgruppeId != null && !aktKondiEbene?.staffelgruppeFlex)
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }, [
    gueltigkeiten,
    gueltigPos,
    last,
    listenPreisInfo,
    model.allianzModus,
    model.freigegeben,
    model.kondiDefinition.standortLabel,
    model.ekRabattgruppen,
    model.neueKondi.artikelRabattgruppeId,
    model.staffelgruppen,
    model.staffelgruppeManuellId,
    allianzAllowZuschl,
    kondi,
    filterState.ansicht,
    filterState.standortEk,
    filterState.standortId,
    filterState.staffelWert,
    onChange,
    classes.view,
    readonly,
    standorteEx,
    aktKondiEbene,
    staffelwerte,
    setAllianzAllowZuschl,
    dispatch
  ])

  return (
    <CardEx header={header} height="100%">
      <ScrollPanel>
        <EkPreisKonditionTable
          dispatch={dispatch}
          ansicht={filterState.ansicht}
          ansichtStaffelWert={filterState.staffelWert}
          konditionen={konditionen}
          kondiDefinition={model.kondiDefinition}
          ekRabattgruppen={model.ekRabattgruppen}
          artikelRabattgruppeId={model.neueKondi.artikelRabattgruppeId}
          getDataColor={getDataColor}
          readonly={readonly}
          staffelgruppen={model.staffelgruppen}
          sonderpreisRabattierbar={kondi.sonderpreisRabattierbar}
          allianzModus={model.allianzModus}
          allianzAllowZuschl={allianzAllowZuschl}
          errors={errors}
        />
      </ScrollPanel>
    </CardEx>
  )
}
