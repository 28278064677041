export enum MessageID {
  ID_OK = 'ID_OK',
  ID_OPTLOCK = 'ID_OPTLOCK',
  ID_EXCEPTION = 'ID_EXCEPTION',
  ID_UNAUTHORIZED = 'ID_UNAUTHORIZED',
  ID_NOTFOUND = 'ID_NOTFOUND',
  ID_REF_NOTFOUND = 'ID_REF_NOTFOUND',
  ID_VALIDATION_FAILDED = 'ID_VALIDATION_FAILDED',
  ID_FIELD_MANDATORY = 'ID_FIELD_MANDATORY',

  ID_DUPLICATE = 'ID_DUPLICATE',
  ID_SERVICE_UNREACHABLE = 'ID_SERVICE_UNREACHABLE',

  ID_DELTA = 'ID_DELTA',
  ID_NEED_CREATE = 'ID_NEED_CREATE',

  IDE_ARTIKEL_PREISE_MUESSEN_NOCH_GEHOLT_WERDEN = 'IDE_ARTIKEL_PREISE_MUESSEN_NOCH_GEHOLT_WERDEN',
  IDE_DZ_EAN_KONFLIKT = 'IDE_DZ_EAN_KONFLIKT',
  IDE_DZ_BT_ANDERER_ERMITTELT = 'IDE_DZ_BT_ANDERER_ERMITTELT',
  IDE_DZ_ABGLEICH_ANDERE_EANGRUPPE = 'IDE_DZ_ABGLEICH_ANDERE_EANGRUPPE',
  IDE_DZ_MEHRERE_WURZEL_MES = 'IDE_DZ_MEHRERE_WURZEL_MES',
  IDE_DZ_ABGLEICH_MEHRDEUTIG = 'IDE_DZ_ABGLEICH_MEHRDEUTIG',
  IDE_ZE_ABGLEICH_MEHRDEUTIG = 'IDE_ZE_ABGLEICH_MEHRDEUTIG',

  IDE_ABO_SELEKTION_DELETE_HAUPT_SELEKTION_DIFFERENT = 'IDE_ABO_SELEKTION_DELETE_HAUPT_SELEKTION_DIFFERENT'
}
