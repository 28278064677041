import {
  AllianzDatenValidierenType,
  ArtikelAenderungTyp,
  ArtikelBetriebstyp,
  DzMapperEinstellungTyp,
  NeonKontext
} from '@one/typings/apiTypings'
import { generatePathSafe, generatePathSafeArr } from '@utils/ui/uiutils'
import { makeQueryParams, safeArray } from '@utils/utils'
import { generatePath } from 'react-router'

export const VirtualArtikelListIds = {
  HauptlieferantArtikelListe: '-1',
  GesperrteHauptlieferantArtikelListe: '-2',
  EkArtikelListe: '-3',
  VkArtikelListe: '-4',
  VkPreispflege: '-5',
  NichtBeforzugterHauptlieferant: '-10'
}

export type IDType = number | 'neu'

export type AboSelektionParms = {
  btNummer?: string
  filter?: ArtikelAenderungTyp
}

export const AppPaths = {
  // ------------------------------------------------------------------------
  Dashboard: '/',
  DashboardQueries: {
    _key: 'filter',
    error: 'error'
  },
  DashboardFilterFn: (filter: string) => `/dashboard?${AppPaths.DashboardQueries._key}=${filter}`,

  // ------------------------------------------------------------------------
  AdminView: '/admin',
  LogView: '/log',
  DataView: '/data',

  // ------------------------------------------------------------------------
  ProtokollDetailsView: '/protokolldetails/:id',
  ProtokollDetailsViewFn: (id) => generatePathSafe(AppPaths.ProtokollDetailsView, { id }),

  // ------------------------------------------------------------------------
  Gesellschafter: '/gesellschafter',
  GesellschafterAllianzView: ['/gesellschafter/:id/:gsId', '/gesellschafter/:id'],
  GesellschafterAllianzViewFn: ({ id, gsId }: { id?: number; gsId?: number }) =>
    generatePathSafeArr(AppPaths.GesellschafterAllianzView, {
      id: id ?? 'neu',
      gsId: gsId ?? null
    }),

  OrganisationView: '/gesellschafter/organisation/:id/',
  OrganisationViewFn: (id: IDType) => generatePathSafe(AppPaths.OrganisationView, { id }),

  FirmaView: ['/gesellschafter/firma/:id', '/gesellschafter/firma/:id/:organisationsId'],
  FirmaViewFn: (id: IDType, organisationsId: number = null) => {
    if (organisationsId) {
      return generatePathSafe(AppPaths.FirmaView[1], { id, organisationsId })
    }
    if (id !== 'neu') {
      return generatePathSafe(AppPaths.FirmaView[0], { id })
    }
    return null
  },

  PreisEbenenPflege: '/preisebenen',

  ExportToErpUebersicht: '/export/erp',

  ErpExportsUebersicht: '/export/erpjobs/uebersicht',

  ExportExc: '/export/artikel/exportieren',

  AllianzVerteilerMatrixEditor: '/allianz/verteilermatrix',

  // ------------------------------------------------------------------------
  ArtikelAboUebersicht: '/artikelabo/uebersicht',
  ArtikelAboPflege: '/artikelabo/:id',
  ArtikelAboPflegeFn: (id: IDType) => generatePathSafe(AppPaths.ArtikelAboPflege, { id }),

  // ------------------------------------------------------------------------
  ArtikelAboSelektionPflege: '/ArtikelSelektion/:id',

  ArtikelAboSelektionPflegeFn: (id: IDType, params?: AboSelektionParms) => {
    const base = generatePathSafe(AppPaths.ArtikelAboSelektionPflege, { id })
    return params ? base + '?' + makeQueryParams(params) : base
  },

  // ------------------------------------------------------------------------
  ArtikelEtikettenDruck: '/artikeletiketten/:standortId',
  ArtikelEtikettenDruckFn: (standortId: number) =>
    generatePathSafe(AppPaths.ArtikelEtikettenDruck, { standortId }),

  // ------------------------------------------------------------------------
  ExportArtikelFehler: '/export/artikelfehler/uebersicht',
  ExportLieferantFehler: '/export/lieferantfehler/uebersicht',
  ExportEkPreisFehler: '/export/ekpreisfehler/uebersicht',
  ExportVkPreisFehler: '/export/vkpreisfehler/uebersicht',
  ExportVkSperreFehler: '/export/vksperre/uebersicht',
  ExportEkKonditionenFehler: '/export/ekkonditionenfehler/uebersicht',
  ExportStandortArtikelFehler: '/export/standortartikelfehler/uebersicht',

  ImportArtikelFehler: '/import/artikelfehler/uebersicht',
  ImportLieferantFehler: '/import/lieferantfehler/uebersicht',
  ImportEkPreisFehler: '/import/ekpreisfehler/uebersicht',
  ImportVkPreisFehler: '/import/vkpreisfehler/uebersicht',
  ImportEkKonditionenFehler: '/import/ekkonditionenfehler/uebersicht',

  ImportFehlerQuery: {
    showIgnored: 'showIgnored'
  },

  // ------------------------------------------------------------------------
  HauptlieferantPflege: ['/hauptlieferant/:id/:kontext?', '/hauptlieferant/:id/bt/:bt?'],

  HauptlieferantPflegeFn: (id: any, kontext?: NeonKontext, bt?: ArtikelBetriebstyp) =>
    kontext
      ? generatePathSafe(AppPaths.HauptlieferantPflege[0], { id, kontext })
      : generatePathSafe(AppPaths.HauptlieferantPflege[1], { id, bt }),

  // ------------------------------------------------------------------------
  ArtikelUebersicht: '/artikel/uebersicht',
  Artikel: ['/artikel/:id/:revision?', '/artikel/:id/kontext/:kontext/:revision?'],

  ArtikelFn: (id: IDType) => generatePathSafe(AppPaths.Artikel[0], { id, revision: null }),
  ArtikelExFn: (id: IDType, revision: number = null, kontext: NeonKontext = null) =>
    kontext
      ? generatePathSafe(AppPaths.Artikel[1], { id, kontext, revision })
      : generatePathSafe(AppPaths.Artikel[0], { id, revision }),

  // ------------------------------------------------------------------------
  EigenlistungsArtikelPflege: '/eigenlistungsartikel/:id',
  EigenlistungsArtikelPflegeFn: (id: IDType) =>
    generatePathSafe(AppPaths.EigenlistungsArtikelPflege, { id }),

  // ------------------------------------------------------------------------
  LieferantUebersicht: '/lieferant/uebersicht',
  Lieferant: ['/lieferant/:id/:revision', '/lieferant/:id'],
  LieferantListung: ['/listungslieferant/:id/:revision', '/listungslieferant/:id'],
  LieferantAllianz: ['/allianzlieferant/:id/:revision', '/allianzlieferant/:id'],
  LieferantFn: (id, revision = null) => generatePathSafeArr(AppPaths.Lieferant, { id, revision }),
  LieferantListungFn: (id, revision = null) =>
    generatePathSafeArr(AppPaths.LieferantListung, { id, revision }),
  LieferantAllianzFn: (id, revision = null) =>
    generatePathSafeArr(AppPaths.LieferantAllianz, { id, revision }),

  LieferantenMengenMapping: '/lieferanten/mengeneinheiten/mapping',
  LieferantenMengenMappingFn: (lieferantenNr?: any) =>
    lieferantenNr
      ? `${AppPaths.LieferantenMengenMapping}?lieferantenNr=${lieferantenNr}`
      : AppPaths.LieferantenMengenMapping,

  // ------------------------------------------------------------------------
  EigenlistungsLieferantPflege: '/eigenlistungslieferant/:id',
  EigenlistungsLieferantPflegeFn: (id: IDType) =>
    generatePathSafe(AppPaths.EigenlistungsLieferantPflege, { id }),

  // ------------------------------------------------------------------------
  Preisaenderungen: [
    '/preisaenderungen/:artikelListeRef',
    '/preisaenderungen/:artikelListeRef/bt/:betriebstyp',
    '/preisaenderungen/:artikelListeRef/kontext/:kontext'
  ],
  PreisaenderungenFn: ({ artikelListeRef, kontext = null, betriebstyp = null }) => {
    if (betriebstyp) {
      return generatePathSafe(AppPaths.Preisaenderungen[1], {
        artikelListeRef,
        betriebstyp
      })
    }
    if (kontext) {
      return generatePathSafe(AppPaths.Preisaenderungen[2], {
        artikelListeRef,
        kontext
      })
    }
    return generatePath(AppPaths.Preisaenderungen[0], { artikelListeRef })
  },

  // ------------------------------------------------------------------------
  EkPreisUebersicht: '/ekpreis/uebersicht',

  EkPreisAnlage: [
    /* 0 */ '/ekpreis/anlage/manuell/:kontext?',
    /* 1 */ '/ekpreis/anlage/lieferant/:lieferantId/:kontext?',
    /* 2 */ '/ekpreis/anlage/artikelliste/:artikelListeRef/:kontext?',
    /* 3 */ '/ekpreis/anlage/liefartlist/:lieferantId/:artikelListeRef/:kontext?',
    /* 4 */ '/ekpreis/anlage/manuell/bt/:bt',
    /* 5 */ '/ekpreis/anlage/lieferant/:lieferantId/bt/:bt',
    /* 6 */ '/ekpreis/anlage/artikelliste/:artikelListeRef/bt/:bt',
    /* 7 */ '/ekpreis/anlage/liefartlist/:lieferantId/:artikelListeRef/bt/:bt'
  ],

  EkPreisAnlageFn: ({ lieferantId = null, artikelListeRef = null, kontext = null, bt = null }) => {
    if (bt) {
      if (lieferantId && artikelListeRef) {
        return generatePathSafe(AppPaths.EkPreisAnlage[7], {
          lieferantId,
          artikelListeRef,
          bt
        })
      }

      if (lieferantId) {
        return generatePathSafe(AppPaths.EkPreisAnlage[5], { lieferantId, bt })
      }

      if (artikelListeRef) {
        return generatePathSafe(AppPaths.EkPreisAnlage[6], { artikelListeRef, bt })
      }

      return generatePathSafe(AppPaths.EkPreisAnlage[4], { bt })
    } else {
      if (lieferantId && artikelListeRef) {
        return generatePathSafe(AppPaths.EkPreisAnlage[3], {
          lieferantId,
          artikelListeRef,
          kontext
        })
      }
      if (lieferantId) {
        return generatePathSafe(AppPaths.EkPreisAnlage[1], { lieferantId, kontext })
      }
      if (artikelListeRef) {
        return generatePathSafe(AppPaths.EkPreisAnlage[2], { artikelListeRef, kontext })
      }
      if (kontext != null) {
        return generatePathSafe(AppPaths.EkPreisAnlage[0], { kontext })
      }
    }
    return generatePath(AppPaths.EkPreisAnlage[0], {})
  },

  EkPreisPflege: ['/ekpreis/pflege/:id', '/ekpreis/pflege/:id/:eintragId'],

  EkPreisPflegeFn: (id: IDType, artId: number = null) =>
    `/ekpreis/pflege/${id == null ? 'neu' : id}${artId ? '/' : ''}${artId || ''}`,

  EkPreisMassenPflege: '/ekpreis/massenpflege/:id',
  EkPreisMassenPflegeFn: (id: IDType) => `/ekpreis/massenpflege/${id == null ? 'neu' : id}`,

  EkPreisImportUbersicht: '/ekpreis/import/uebersicht',
  EkPreisImportPflege: '/ekpreis/import/:id',
  EkPreisImportPflegeFn: (id: string | number = 'neu') =>
    generatePathSafe(AppPaths.EkPreisImportPflege, { id }),

  EkPreisImportKonfiguration: '/ekpreis/import/konfiguration',

  EkPreisKalkulationsschema: '/ekpreis/kalkulationsschema',

  // ------------------------------------------------------------------------
  VkPreisUebersicht: ['/vkpreis/uebersicht', '/vkpreis/listen/:preisListenIds'],

  VkPreisUebersichtFn: (preisListenIds: number[]) =>
    generatePathSafe(AppPaths.VkPreisUebersicht[1], {
      preisListenIds: safeArray(preisListenIds).join(',')
    }),

  VkPreisPflege: ['/vkpreis/pflege/:id', '/vkpreis/pflege/:id/:eintragId'],

  VkPreisPflegeFn: (id: IDType, eintragId: number = null) =>
    `/vkpreis/pflege/${id == null ? 'neu' : id}${eintragId ? '/' : ''}${eintragId || ''}`,

  // ------------------------------------------------------------------------
  VkPreisAnlage: [
    '/vkpreis/anlage/manuell/:kontext?',
    '/vkpreis/anlage/lieferant/:lieferantId/:kontext?',
    '/vkpreis/anlage/artikelliste/:artikelListeRef/:kontext?',
    '/vkpreis/anlage/liefartref/:lieferantId/:artikelListeRef/:kontext?',
    //
    '/vkpreis/anlage/manuell/bt/:bt',
    '/vkpreis/anlage/lieferant/:lieferantId/bt/:bt',
    '/vkpreis/anlage/artikelliste/:artikelListeRef/bt/:bt',
    '/vkpreis/anlage/liefartref/:lieferantId/:artikelListeRef/bt/:bt'
  ],

  VkPreisAnlageFn: ({ lieferantId = null, artikelListeRef = null, kontext = null, bt = null }) => {
    if (bt) {
      if (lieferantId && artikelListeRef) {
        return generatePathSafe(AppPaths.VkPreisAnlage[7], {
          lieferantId,
          artikelListeRef,
          bt
        })
      }

      if (lieferantId) {
        return generatePathSafe(AppPaths.VkPreisAnlage[5], { lieferantId, bt })
      }

      if (artikelListeRef) {
        return generatePathSafe(AppPaths.VkPreisAnlage[6], { artikelListeRef, bt })
      }

      return generatePathSafe(AppPaths.VkPreisAnlage[4], { bt })
    } else {
      if (lieferantId && artikelListeRef) {
        return generatePathSafe(AppPaths.VkPreisAnlage[3], {
          lieferantId,
          artikelListeRef,
          kontext
        })
      }

      if (lieferantId) {
        return generatePathSafe(AppPaths.VkPreisAnlage[1], { lieferantId, kontext })
      }

      if (artikelListeRef) {
        return generatePathSafe(AppPaths.VkPreisAnlage[2], { artikelListeRef, kontext })
      }

      if (kontext) {
        return generatePathSafe(AppPaths.VkPreisAnlage[0], { kontext })
      }
    }

    return generatePath(AppPaths.VkPreisAnlage[0], {})
  },

  VkPreisAnlageClone: '/vkpreis/anlage/clone/:preisListeId',

  VkPreisAnlageCloneFn: (preisListeId: number) =>
    preisListeId && generatePathSafe(AppPaths.VkPreisAnlageClone, { preisListeId }),

  VkPreisMassenPflege: '/vkpreis/massenpflege/:id',

  VkPreisMassenPflegeFn: (id: IDType) => `/vkpreis/massenpflege/${id == null ? 'neu' : id}`,

  VkPreisgruppenRegeln: [
    '/vkpreis/preisgruppenregeln/gesellschafter',
    '/vkpreis/preisgruppenregeln/standortgruppe/:standortgruppeId',
    '/vkpreis/preisgruppenregeln/standort/:standortId'
  ],
  VkPreisgruppenRegelnByGesellschafterFn: () => AppPaths.VkPreisgruppenRegeln[0],
  VkStandardPreisgruppenByStandortgruppeFn: (standortgruppeId: number) =>
    generatePathSafe(AppPaths.VkPreisgruppenRegeln[1], { standortgruppeId }),
  VkPreisgruppenRegelnByStandortFn: (standortId: number) =>
    generatePathSafe(AppPaths.VkPreisgruppenRegeln[2], { standortId }),

  // ------------------------------------------------------------------------
  // -- LieferantAbo
  LieferantAboUebersicht: '/lieferantabo/uebersicht',
  LieferantAboUebersichtFn: (aenderungsTyp) =>
    `/lieferantabo/uebersicht?${AppPaths.LieferantAboUebersichtQueries.aenderungsTyp}=${aenderungsTyp}`,
  LieferantAboUebersichtQueries: {
    aenderungsTyp: 'aenderungsTyp'
  },

  LieferantAboPflege: ['/lieferantabo/:id/:lieferantId', '/lieferantabo/:id'],
  LieferantAboPflegeFn: (id: string | number = 'neu', lieferantId?: number) =>
    generatePathSafeArr(AppPaths.LieferantAboPflege, { id, lieferantId }),
  // ------------------------------------------------------------------------
  // -- Synchronisation
  synchronisation: {
    ArtikelLieferantSyncTemplateUebersicht: '/ArtikelLieferantSyncTemplate/uebersicht',
    ArtikelSyncTemplateUebersicht: '/ArSSTSyncTemplate/uebersicht',
    ArtikelLieferantSyncTemplatePflege: [
      '/ArtikelLieferantSyncTemplate/:typ/:id',
      '/ArtikelLieferantSyncTemplate/:typ/:id/:cloneId'
    ],
    ArtikelLieferantSyncTemplatePflegeFn: (
      typ: DzMapperEinstellungTyp,
      id: IDType = 'neu',
      cloneId?: IDType
    ) =>
      generatePathSafe(AppPaths.synchronisation.ArtikelLieferantSyncTemplatePflege[0], {
        typ,
        id
      }),
    ArtikelLieferantSyncTemplateCloneFn: (cloneId?: IDType) =>
      generatePathSafe(AppPaths.synchronisation.ArtikelLieferantSyncTemplatePflege[1], {
        typ: DzMapperEinstellungTyp.ARSST,
        id: 'neu',
        cloneId
      })
  },

  Umsatzsteuer: {
    UmsatzsteuerOverview: '/umsatzsteuer',
    UmsatzsteuerEdit: '/umsatzsteuer/:id',
    UmsatzsteuerFn: (id: IDType) => {
      return generatePathSafe(AppPaths.Umsatzsteuer.UmsatzsteuerEdit, { id })
    }
  },

  ArtikelArt: '/artikelArtEinstellungen',

  // ------------------------------------------------------------------------
  artikelliste: {
    ArtikelListeUebersicht: '/artikelListe/uebersicht',
    ArtikelListePflege: ['/artikelListe/:id', '/artikelListe/:id/:kontext'],
    ArtikelListePflegeFn: (id: IDType, kontext?: NeonKontext) =>
      kontext
        ? generatePathSafe(AppPaths.artikelliste.ArtikelListePflege[1], { id, kontext })
        : generatePathSafe(AppPaths.artikelliste.ArtikelListePflege[0], { id })
  },

  // ------------------------------------------------------------------------
  artikelverteiler: {
    ArtikelVerteilerUebersicht: '/ArtikelVerteiler/uebersicht',
    ArtikelVerteilerPflege: '/ArtikelVerteiler/:id',
    ArtikelVerteilerPflegeFn: (id: IDType) =>
      generatePathSafe(AppPaths.artikelverteiler.ArtikelVerteilerPflege, {
        id
      })
  },
  // ------------------------------------------------------------------------
  lieferanteliste: {
    LieferantenListeUebersicht: '/LieferantenListe/uebersicht',
    LieferantenListePflege: '/LieferantenListe/:id',
    LieferantenListePflegeFn: (id: IDType) =>
      generatePathSafe(AppPaths.lieferanteliste.LieferantenListePflege, { id })
  },
  // ------------------------------------------------------------------------
  lieferantenverteiler: {
    LieferantenVerteilerUebersicht: '/LieferantenVerteiler/uebersicht',
    LieferantenVerteilerPflege: '/LieferantenVerteiler/:id',
    LieferantenVerteilerPflegeFn: (id: IDType) =>
      generatePathSafe(AppPaths.lieferantenverteiler.LieferantenVerteilerPflege, { id })
  },
  LabelListe: '/label/uebersicht',

  // ------------------------------------------------------------------------
  EkRabattgruppenUebersicht: '/ekRabattgruppen/uebersicht',

  EkRabattgruppenUebersichtFn: (lieferantenNr?: any) =>
    lieferantenNr
      ? `${AppPaths.EkRabattgruppenUebersicht}?lieferantenNr=${lieferantenNr}`
      : AppPaths.EkRabattgruppenUebersicht,

  EkRabattgruppenPflege: [
    '/ekRabattgruppe/:id',
    '/ekRabattgruppe/neu/:lieferantId',
    '/ekRabattgruppe/:id/:versionId'
  ],
  EkRabattgruppenPflegeFn: ({
    id,
    lieferantId,
    versionId
  }: {
    id?: number
    lieferantId?: number
    versionId?: number
  }) => {
    if (!id && lieferantId) {
      return generatePathSafe(AppPaths.EkRabattgruppenPflege[1], { lieferantId })
    }
    if (id && versionId) {
      return generatePathSafe(AppPaths.EkRabattgruppenPflege[2], { id, versionId })
    }
    return generatePathSafe(AppPaths.EkRabattgruppenPflege[0], { id })
  },

  EkRabattgruppenMassenPflege: '/ekRabattgruppeMassenpflege/:lieferantId',
  EkRabattgruppenMassenPflegeFn: ({ lieferantId }: { lieferantId?: number }) =>
    generatePathSafe(AppPaths.EkRabattgruppenMassenPflege, { lieferantId }),

  // ------------------------------------------------------------------------
  ArtikelDatenPoolUebersicht: '/datenpool/artikel/uebersicht',

  // ------------------------------------------------------------------------
  AllianzDatenFehlerView: '/allianzdatenfehler/:type',
  AllianzDatenFehlerViewFn: (type: AllianzDatenValidierenType) =>
    generatePathSafe(AppPaths.AllianzDatenFehlerView, { type }),

  AllianzDatenBereitstellungView: '/allianzdatenbereitstellung',
  AllianzDatenBereitstellungUebersichtView: '/allianzdatenbereitstellung/uebersicht',

  // ------------------------------------------------------------------------
  StandortePflege: '/standorte',

  StandortgruppeUebersicht: '/standortgruppe/uebersicht',
  StandortgruppePflege: '/standortgruppe/:id',
  StandortgruppePflegeFn: (id: 'neu' | number) =>
    generatePathSafe(AppPaths.StandortgruppePflege, { id }),

  SortimentPflege: [
    '/sortiment/standortgruppe/:standortGruppeId',
    '/sortiment/standort/:standortId'
  ],
  SortimentPflegeByStandortgruppeFn: (standortGruppeId: number) =>
    generatePathSafe(AppPaths.SortimentPflege[0], { standortGruppeId }),
  SortimentPflegeByStandortFn: (standortId: number) =>
    generatePathSafe(AppPaths.SortimentPflege[1], { standortId }),

  // ------------------------------------------------------------------------
  EosMigration: '/eosmigration'
}
