import { Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import {
  ArtikelAbonniertStatus,
  ErpArtikelDetailsJson,
  SuchArtikelTyp
} from '@one/typings/apiTypings'
import { formatNumber } from '@utils/numberutils'
import { CardEx } from '@utils/ui/CardEx'
import { Column } from '@utils/ui/DataTable/DataTable'
import { StaticField } from '@utils/ui/fields/StaticField'
import { ArtikelDetailsTabelle } from './ArtikelDetailsTabelle'

export const ArtikelLieferantenColumns: Column[] = [
  {
    field: 'lieferant.nummer',
    header: 'Lieferantennummer',
    sortable: true
  },
  {
    field: 'lieferant.name1',
    header: 'Lieferantenname 1',
    sortable: true,
    valueGetter: (row) => {
      return row.notanlage ? 'Notlieferant' : row.lieferant?.name1
    }
  },
  {
    field: 'lieferant.name2',
    header: 'Lieferantenname 2',
    sortable: true
  },
  {
    field: 'industrieArtikelNummer',
    header: 'IAN',
    sortable: true
  },
  {
    field: 'einkaufMengenEinheit',
    header: 'Einkaufseinheit',
    sortable: true,
    valueGetter: (row) =>
      `${formatNumber(row.einkaufPreismenge, 2) ?? ''} ${row.einkaufMengenEinheit ?? ''}`
  },
  {
    field: 'ausgelistet',
    header: 'Ausgelistet',
    type: 'boolean',
    sortable: true
  }
]

export const ArtikelLieferantenTableActions = [
  {
    icon: 'local_shipping',
    tooltip: 'Zum Lieferanten',
    getLink: (data: any) => {
      return data.lieferant.typ === SuchArtikelTyp.ZENTRAL
        ? AppPaths.LieferantListungFn(data.lieferant.id)
        : AppPaths.LieferantFn(data.lieferant.id)
    }
  }
]

const StandortColumns: Column[] = [
  {
    field: 'name',
    header: 'Standort',
    sortable: true
  },
  {
    field: 'nr',
    header: 'Standort-Nummer',
    sortable: true
  },
  {
    field: 'hauptlieferant.nummer',
    header: 'Lieferantennummer',
    sortable: true
  },
  {
    field: 'hauptlieferant.name1',
    header: 'Lieferantenname 1',
    sortable: true,
    valueGetter: (row) => (row.notanlage ? 'Notlieferant' : row.name1)
  },
  {
    field: 'hauptlieferant.name2',
    header: 'Lieferantenname 2',
    sortable: true
  }
]

const StandortTableActions = [
  {
    icon: 'local_shipping',
    tooltip: 'Zum Lieferanten',
    getLink: (data: any) => data?.hauptlieferant && AppPaths.LieferantFn(data.hauptlieferant.id)
  }
]

export interface ArtikelLieferantenSectionProps {
  details: ErpArtikelDetailsJson
  loading?: boolean
}

export const ArtikelLieferantenSection = ({
  details,
  loading = false
}: ArtikelLieferantenSectionProps) => {
  if (!details) {
    return null
  }

  const { hauptLieferant, artikelLieferanten, preisEbenenLieferanten } = details

  return (
    <CardEx title="ERP Lieferant">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StaticField label="Hauptlieferant">
            {hauptLieferant ? (
              // <Link to={AppPaths.LieferantFn(hauptlieferant.id)}>{ wg abo/zentral anzupassen....
              <>
                {hauptLieferant.nummer} {hauptLieferant.name1}
              </>
            ) : // }</Link>
            details.aboStatus === ArtikelAbonniertStatus.UNTERBROCHEN ? (
              'Abo Unterbrochen'
            ) : (
              'Kein Hauptlieferant'
            )}
          </StaticField>
        </Grid>
        {artikelLieferanten?.length > 0 && (
          <Grid item xs={12}>
            <ArtikelDetailsTabelle
              name="ArtikelLieferantenSection"
              values={artikelLieferanten}
              title="Lieferanten"
              columns={ArtikelLieferantenColumns}
              tableActions={ArtikelLieferantenTableActions}
              loading={loading}
            />
          </Grid>
        )}
        {preisEbenenLieferanten?.length > 0 && (
          <Grid item xs={12}>
            <ArtikelDetailsTabelle
              name="ArtikelStandortLieferantenSection"
              values={preisEbenenLieferanten}
              title="Standortspezifischen Lieferanten"
              columns={StandortColumns}
              tableActions={StandortTableActions}
              loading={loading}
            />
          </Grid>
        )}
      </Grid>
    </CardEx>
  )
}
