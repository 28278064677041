/* eslint-disable react/jsx-props-no-spreading */
import {FileDownload, Settings} from '@mui/icons-material'
import {Grid, Typography} from '@mui/material'
import {formatNumber} from '@utils/numberutils'
import {SearcherResultType} from '@utils/searcher'
import {Action, Actions} from '@utils/ui/Action'
import {CardEx} from '@utils/ui/CardEx'
import {useDialogAnker} from '@utils/ui/DialogAnker'
import {ScrollPanel} from '@utils/ui/ScrollPanel'
import {safeArray} from '@utils/utils'
import React, {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react'
import {makeStyles} from 'tss-react/mui'
import {ColumnFilterTrigger, DataTable, DataTableApi, DataTableProps, FilterColumn, FilterDef, FilterFieldData} from './DataTable'
import {useDataTableConfig} from './DataTableConfig'
import {DataTablePager} from './DataTablePager'
import {matchesRowExact} from './DataTableUtils'
import {AdditionalFilter, AdditionalFilterMenu} from './filter/AdditionalFilterMenu'
import {ColumnFilterDialog} from './filter/ColumnFilterDialog'
import {GlobalFilterField} from './filter/GlobalFilterField'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()((_theme) => ({
  container: {
    position: 'relative',
    maxHeight: '60vh',
    overflow: 'auto'
  },
  subtitle: {
    fontSize: '80%',
    display: 'inline-block',
    '& > span': {
      marginLeft: 12
    }
  },
  smallFormField: {
    height: 45,
    marginBottom: 0
  },
  filter: {
    '& >div>div': { marginTop: 12 }
  },
  limited: {
    display: 'block',
    fontSize: '80%',
    fontStyle: 'italic',
    fontWeight: 'bold',
    marginTop: -4
  }
}))

export interface DataTableCardProps<T = any> extends DataTableProps<T> {
  filterMode?: 'none' | 'global' | 'column' | 'both'
  title?: string
  paging?: boolean
  rowsPerPageOptions?: any
  rowsPerPageDefault?: any
  topActions?: Actions
  bottomActions?: Actions
  bottomLeft?: any
  borderless?: boolean
  nopadding?: boolean
  height?: string
  additionalFilter?: AdditionalFilter[]
  limited?: boolean
  localStateName?: string
  noScrollPanel?: boolean
  backLink?: boolean
  header?: React.ReactNode
  globalFilterInit?: string
  duration?: number
  notFound?: boolean
  result?: SearcherResultType<any>
  topContent?: React.ReactNode
  autoHeight?: boolean
  autoHeightMax?: number | string
}

export interface DataTableFilterRef {
  setColumnFilters?: (data: FilterFieldData[]) => void
}

export const DataTableCard = React.forwardRef<DataTableFilterRef, DataTableCardProps<any>>(
  (
    {
      filterMode = 'none',
      title,
      columns,
      value,
      dense,
      selectMode,
      paging,
      rowsPerPageOptions = [10, 25, 50, 100],
      rowsPerPageDefault = 100,
      actions,
      onSelect,
      selected,
      focustype = 'row',
      hover,
      loading,
      stickyHeader,
      topActions,
      bottomActions,
      bottomLeft,
      onRowDoubleClick,
      borderless,
      nopadding,
      additionalFilter,
      limited,
      notFound,
      localStateName,
      stickyColumsLeft,
      noScrollPanel,
      backLink,
      header: headerAdd,
      globalFilterInit,
      duration,
      name,
      height,
      result,
      emptyMessage,
      topContent,
      autoHeight,
      autoHeightMax,
      ...additionalTableProps
    },
    ref
  ) => {
    const { classes } = useStyles()

    const [DlgAnker, showDlg] = useDialogAnker()

    const [count, setCount] = useState(0)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageDefault)

    const [selectedX, setSelected] = useState(selected || new Set())

    const [globalFilter, setGlobalFilter] = useState<FilterDef>()

    const isColumnFilter = filterMode === 'column' || filterMode === 'both'
    const isGlobalFilter = filterMode === 'global' || filterMode === 'both'

    const tableApiRef = useRef<DataTableApi>()

    useEffect(() => {
      const gfi = {
        text: globalFilterInit,
        mode: 'I'
      } as FilterDef
      setGlobalFilter(gfi)
    }, [globalFilterInit])

    const [columnFilters, setColumnFilters] = useState<FilterColumn[]>([])

    const [tableConfig, showConfigDlg] = useDataTableConfig(localStateName, {
      columns,
      stickyColumsLeft
    })

    limited = limited ?? result?.limited
    notFound = notFound ?? result?.notFound ?? true
    value = value ?? result?.rows
    duration = duration ?? result?.duration
    loading = loading ?? result?.busy

    const topActionsEx = useMemo(() => {
      const defaultActions = [] as Action[]

      if (isGlobalFilter) {
        defaultActions.push({
          component: (
            <GlobalFilterField
              name="Filtern"
              value={globalFilter}
              onChange={(e, v) => setGlobalFilter(v)}
            />
          )
        })
      }

      defaultActions.push({
        name: 'Tabelle-default-config',
        tooltip: 'Tabelleneigenschaften',
        icon: <Settings fontSize="small" />,
        onClick: showConfigDlg
      })

      defaultActions.push({
        name: 'Tabelle-excel-export',
        tooltip: 'Excel Export',
        icon: <FileDownload fontSize="small" />,
        onClick: () => tableApiRef.current.exportExcel()
      })

      return defaultActions.concat(topActions)
    }, [globalFilter, isGlobalFilter, showConfigDlg, topActions])

    const handleChangePage = useCallback((event, newPage) => {
      setPage(newPage)
    }, [])

    const handleChangeRowsPerPage = useCallback((event) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    }, [])

    const onSelectHook = useCallback(
      (selection) => {
        setSelected(selection)
        if (onSelect) {
          onSelect(selection)
        }
      },
      [onSelect]
    )

    const onDataChangeHook = useCallback((data) => {
      setCount(data && data.length)
    }, [])

    const columnFilterTrigger = useMemo<ColumnFilterTrigger>(
      () => ({
        isFilter: (col) => {
          const pos = columnFilters.findIndex((cf) => col && cf && col.key === cf.key)
          return pos === -1 ? false : pos + 1
        },

        onFilter: (col, filterRows) => {
          const columnFilter = columnFilters.find((cf) => col && cf && col.key === cf.key)

          const setColumnFilter = (next: FilterColumn | false) => {
            setColumnFilters((old) => {
              if (next == null && columnFilter == null) {
                return old
              }
              if (next === false) {
                return []
              }
              if (old == null || old.length === 0) {
                return [next]
              }
              if (next == null) {
                return old.filter((of) => of !== columnFilter)
              }
              if (columnFilter == null) {
                return [...old, next]
              }
              return old.map((of) => (of === columnFilter ? next : of))
            })
          }

          const getRowsFiltered = () => {
            const me = columnFilters.indexOf(columnFilter)
            return filterRows(me)
          }

          showDlg((visible, onClose) => (
            <ColumnFilterDialog
              open={visible}
              onClose={onClose}
              column={col}
              getRows={getRowsFiltered}
              filterState={columnFilter}
              setFilterState={setColumnFilter}
              filterIndex={
                (columnFilter && columnFilters.indexOf(columnFilter) + 1) ||
                columnFilters.length + 1
              }
            />
          ))
        },
        updFilter: (_cols, _filterRows) => {
          // TODO Filter aktualisieren da daten geändert
          return columnFilters
        }
      }),
      [columnFilters, showDlg]
    )

    useImperativeHandle(
      ref,
      () => ({
        setColumnFilters: (filter: FilterFieldData[]) => {
          setColumnFilters(
            safeArray(filter)
              .filter((f) => f.field?.length > 0 && f.value != null)
              .map((f) => {
                const vf = typeof f.value === 'string' ? f.value.toLocaleUpperCase() : f.value
                return {
                  key: f.field,
                  type: 'exact',
                  value: f.value,
                  matches: (row, col) => matchesRowExact(row, col, vf)
                }
              })
          )
        }
      }),
      []
    )

    useEffect(() => {
      if (selected != null && selected !== selectedX) {
        setSelected(selected)
      }
    }, [selected, selectedX])

    const table = useMemo(
      () => (
        <DataTable
          {...additionalTableProps}
          {...tableConfig}
          name={name}
          columns={columns}
          value={value}
          dense={dense}
          selectMode={selectMode}
          actions={actions}
          focustype={focustype}
          hover={hover}
          loading={loading}
          setPage={paging ? setPage : null}
          page={paging ? page : null}
          rowsPerPage={rowsPerPage}
          stickyHeader={stickyHeader}
          globalFilter={globalFilter}
          columnFilter={columnFilters}
          onSelect={onSelectHook}
          selected={selected}
          onDataChange={onDataChangeHook}
          onRowDoubleClick={onRowDoubleClick}
          columnFilterTrigger={isColumnFilter ? columnFilterTrigger : null}
          apiRef={tableApiRef}
          emptyMessage={
            loading
              ? undefined
              : notFound
                ? emptyMessage || 'Das Suchergebnis ist leer!'
                : "Geben Sie Suchkriterien ein und drücken Sie 'Suchen'"
          }
        />
      ),
      [
        additionalTableProps,
        tableConfig,
        name,
        columns,
        value,
        dense,
        selectMode,
        actions,
        focustype,
        hover,
        loading,
        paging,
        page,
        rowsPerPage,
        stickyHeader,
        globalFilter,
        columnFilters,
        onSelectHook,
        selected,
        onDataChangeHook,
        onRowDoubleClick,
        isColumnFilter,
        columnFilterTrigger,
        emptyMessage,
        notFound
      ]
    )
    const header = useMemo(() => {
      const ltext = limited ? (
        <Typography className={classes.limited}>Ergebnis wurde begrenzt!</Typography>
      ) : null
      const lsign = limited ? '+' : ''

      return (
        <Grid container direction="row" spacing={1} paddingRight={1}>
          {title && (
            <Grid item>
              <Grid container direction="row" spacing={2} alignItems="top">
                <Grid item>
                  <Typography variant="h6">{title}</Typography>
                </Grid>
                {value ? (
                  count !== value.length && value.length ? (
                    <Grid item>
                      <Typography className={classes.subtitle}>
                        {`Gefiltert: ${count} von ${value.length}${lsign}`}
                      </Typography>
                      {ltext}
                    </Grid>
                  ) : (
                    value.length > 0 && (
                      <Grid item>
                        <Typography className={classes.subtitle}>
                          Anzahl: <span data-name="table-item-count">{value.length}</span>
                          {lsign}
                          {duration && (
                            <span> {`Suchdauer: ${formatNumber(duration / 1000, 1)}s`}</span>
                          )}
                        </Typography>
                        {ltext}
                      </Grid>
                    )
                  )
                ) : null}
                {selectMode === 'multi' && selectedX.size > 0 && (
                  <Grid item>
                    <Typography
                      className={classes.subtitle}
                    >{`Selektiert: ${selectedX.size}`}</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          <Grid item flexGrow={1}>
            {headerAdd && headerAdd}
          </Grid>
          <Grid item>
            <AdditionalFilterMenu filter={additionalFilter} />
          </Grid>
          {paging && (
            <Grid item>
              {/* <FormField label="Blättern"> */}
              <DataTablePager
                rowsPerPageOptions={rowsPerPageOptions}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {/* </FormField> */}
            </Grid>
          )}
        </Grid>
      )
    }, [
      limited,
      classes.limited,
      classes.subtitle,
      title,
      value,
      count,
      duration,
      selectMode,
      selectedX.size,
      headerAdd,
      additionalFilter,
      paging,
      rowsPerPageOptions,
      rowsPerPage,
      page,
      handleChangePage,
      handleChangeRowsPerPage
    ])

    return (
      <CardEx
        header={header}
        titleSize="1em"
        height={height ?? '100%'}
        contentStyle={{ height: '100%' }}
        topActions={topActionsEx}
        bottomActions={bottomActions}
        bottomLeft={bottomLeft}
        borderless={borderless}
        nopadding={nopadding}
        backLink={backLink}
        topContent={topContent}
      >
        {noScrollPanel ? (
          table
        ) : (
          <ScrollPanel autoHeight={autoHeight} autoHeightMax={autoHeightMax}>
            {table}
          </ScrollPanel>
        )}
        <DlgAnker />
      </CardEx>
    )
  }
)

DataTableCard.displayName = 'DataTableCard'
