import { Typography } from '@mui/material'
import { orange, red } from '@mui/material/colors'
import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import { LabelChipContainer } from '@one/components/Label/LabelChipContainer'
import { useArtikelLabelEdit } from '@one/components/Label/useArtikelLabelEdit'
import {
  ArtikelBetriebstypCell,
  getArtikelBetriebstypLabel
} from '@one/components/common/ArtikelBetriebstypCell'
import { getArchiviertRowStyle } from '@one/components/common/TableUtils'
import { WarengruppeNameCell, WarengruppeNrCell } from '@one/components/common/WarengruppeCell'
import { useArtikelBetriebstypCache } from '@one/datacaches/useArtikelBetriebsTypeCache'
import { useArtikelLabelCache } from '@one/datacaches/useArtikelLabelCache'
import { HkmEnum } from '@one/enums/HkmEnum'
import { VkPreiseMasseBearbeitenEintragJson, ZufuhrMultiDisplayJson } from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { AppContext } from '@utils/ui/App/AppContext'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { Permission } from '@utils/ui/Permission'
import { ThemeContext } from '@utils/ui/Theme'
import { Medal } from '@utils/ui/fields/Medal'
import { useCallback, useContext, useMemo } from 'react'

export interface VkPreisMassenPflegeTableProps {
  actions: any
  selected: Set<VkPreiseMasseBearbeitenEintragJson>
  preisgruppen: any
  reload: any
  preislisteId?: any
  onSelect: (selected: Set<VkPreiseMasseBearbeitenEintragJson>) => void
  data?: VkPreiseMasseBearbeitenEintragJson[]
  zufuhrMultis: ZufuhrMultiDisplayJson[]
  forEinzelhandel: boolean
}

export const VkPreisMassenPflegeTable = ({
  selected,
  onSelect,
  actions,
  reload,
  preislisteId,
  preisgruppen,
  data = [],
  zufuhrMultis,
  forEinzelhandel
}: VkPreisMassenPflegeTableProps) => {
  const { darkMode } = useContext(ThemeContext)
  const { openLabelsEdit } = useArtikelLabelEdit()
  const { get: getLabels } = useArtikelLabelCache()
  const { get: getBetriebstyp } = useArtikelBetriebstypCache()
  const { et } = useEnums()
  const { isPIMModel } = useContext(AppContext)

  const columns = useMemo<Column<VkPreiseMasseBearbeitenEintragJson>[]>(() => {
    let cols = [
      { type: 'string', field: 'standort', header: 'Standort' }
    ] as Column<VkPreiseMasseBearbeitenEintragJson>[]
    const importFehlerFormatter = (row) => {
      const nas = []
      if (row?.importFehlerData) {
        nas.push('Daten')
      }
      if (row?.importFehlerPreiseEk) {
        nas.push('EK-Preise')
      }
      if (row?.importFehlerPreiseVk) {
        nas.push('VK-Preise')
      }
      return nas
    }

    cols.push(
      {
        field: 'importFehlerData',
        header: 'Import-Fehler',
        hideIfEmpty: true,
        valueGetter: importFehlerFormatter,
        align: 'center',
        body: (row) => {
          return row?.artikel?.importFehlerData ||
            row?.artikel?.importFehlerPreiseEk ||
            row?.artikel?.importFehlerPreiseVk
            ? importFehlerFormatter(row).map((medal) => (
                <Medal
                  key={medal}
                  text={medal}
                  tooltip="Wegen Aktualisierungsfehler nicht aktuell"
                  backgroundColor={orange[500]}
                />
              ))
            : null
        }
      },
      { type: 'string', field: 'artikel.hageNummer', header: 'hage-Nr.' },
      { type: 'string', field: 'artikel.btNummer', header: 'Artikel-Nr.', off: isPIMModel },
      { type: 'string', field: 'artikel.bez1', header: 'Bez1' },
      { type: 'string', field: 'artikel.bez2', header: 'Bez2' },
      { type: 'string', field: 'artikel.bez3', header: 'Bez3' },
      { type: 'string', field: 'artikel.bez4', header: 'Bez4' },
      {
        field: 'artikel.labels',
        header: 'Labels',
        sortable: true,
        valueGetter: (row) => row.artikel?.labels?.map((label) => getLabels(label)?.kurz),
        body: (row) => <LabelChipContainer labels={row.artikel?.labels?.map(getLabels)} />
      },
      {
        field: 'artikel.warengruppe.nummer',
        header: 'WG-Nr',
        body: (row: any) => <WarengruppeNrCell warengruppe={row.artikel?.warengruppe} />
      },
      {
        field: 'artikel.warengruppe.name',
        header: 'WG-Name',
        body: (row: any) => <WarengruppeNameCell warengruppe={row.artikel?.warengruppe} />
      },
      {
        field: 'artikel.betriebsTyp',
        header: 'B-Typ',
        body: (row) => <ArtikelBetriebstypCell value={row.artikel?.betriebsTyp} />,
        valueGetter: (row) =>
          `${getArtikelBetriebstypLabel(getBetriebstyp(row.artikel?.betriebsTyp))}`
      },
      {
        field: 'error',
        header: 'Fehler',
        valueGetter: (row) => row.error && row.error.title + ': ' + row.error.details,
        body: (row) =>
          row.error && (
            <span>
              {row.error.title}: <Typography variant="caption">{row.error.details}</Typography>
            </span>
          ),
        span: (row) => row.error && 100
      },
      { type: 'string', field: 'kalkulationsvorschlag', header: 'Kalkulationsvorschlag' },
      { type: 'money2', field: 'lp', header: 'Listenpreis' },
      { type: 'money2', field: 'kalkEK', header: 'kalk. EK' },
      { type: 'string', field: 'einh', header: 'Einheit' }
    )

    if (preisgruppen) {
      preisgruppen.forEach((pg, idx) => {
        const header = (name) => (
          <span>
            {pg.name}
            <br />
            {name}
          </span>
        )

        cols.push(
          {
            type: 'money2',
            field: `__${pg.name}.preis`,
            header: header('Preis'),
            valueGetter: (row) => row.pgs && row.pgs[idx]?.preis
          },
          {
            type: 'string',
            field: `__${pg.name}.detail`,
            header: header('Basis'),
            valueGetter: (row) => row.pgs && row.pgs[idx]?.detail
          },
          {
            type: 'money2',
            field: `__${pg.name}.opreis`,
            header: header('Letzter Preis'),
            valueGetter: (row) => row.pgs && row.pgs[idx]?.opreis
          },
          {
            type: 'string',
            field: `__${pg.name}.opreisbasis`,
            header: header('Letzte Basis'),
            valueGetter: (row) => row.pgs && row.pgs[idx]?.opreisbasis
          },
          {
            type: 'money2',
            field: `__${pg.name}.delta`,
            header: header('Delta %'),
            valueGetter: (row) => row.pgs && row.pgs[idx]?.delta
          }
        )
      })
    }
    if (!forEinzelhandel) {
      cols.push(
        {
          field: 'frachtkosten.frachtArt',
          header: 'Frachtart',
          valueGetter: (row) => et(HkmEnum.VkFrachtArt, row.frachtkosten?.frachtArt)
        },
        {
          field: 'frachtkosten.frachtNichtSkonto',
          header: 'Nicht Skonto?',
          type: 'boolean'
        },
        {
          field: 'frachtkosten.frachtNachRabatt',
          header: 'Nach Rabatt?',
          type: 'boolean'
        },
        {
          field: 'frachtkosten.frachtProz',
          header: 'Fracht Proz.',
          type: 'money2'
        },
        {
          field: 'frachtkosten.frachtAbs',
          header: 'Fracht Abs.',
          type: 'money2'
        },
        {
          field: 'frachtkosten.enthalteneFrachtZufuhr',
          header: 'Ent. FZ',
          headerTip: 'Enthaltene FrachtZufuhr',
          type: 'money2'
        },
        {
          field: 'frachtkosten.ekNichtSkonto',
          header: 'EK Nicht Skonto',
          type: 'money2'
        },
        {
          field: 'frachtkosten.vkNichtSkonto',
          header: 'VK Nicht Skonto',
          type: 'money2'
        },
        {
          field: 'frachtkosten.zufuhrMultiId',
          header: 'Zufuhrmulti',
          valueGetter: (row) => {
            const zfm =
              row.frachtkosten?.zufuhrMultiId &&
              zufuhrMultis?.find((zfm) => zfm.id === row.frachtkosten.zufuhrMultiId)
            return zfm && `${zfm.name}-${zfm.bez}`
          }
        }
      )
    }

    return cols
  }, [isPIMModel, preisgruppen, forEinzelhandel, getLabels, getBetriebstyp, et, zufuhrMultis])

  const tableActions = useMemo(
    (): DataTableAction[] => [
      {
        icon: 'info',
        tooltip: 'Archivierter Artikel',
        handler: () => {},
        check: (row) => (row.archiviert ? Permission.ENABLED : Permission.HIDDEN)
      },
      {
        icon: 'edit',
        tooltip: 'Preispflege öffnen',
        getLink: (data: any) => {
          return AppPaths.VkPreisPflegeFn(preislisteId, data.vkPreisListeEintragId)
        }
      },
      {
        icon: 'arrow_forward',
        tooltip: 'Artikel öffnen',
        getLink: (data: any) => AppPaths.ArtikelFn(data.artikel?.id)
      }
    ],
    [preislisteId]
  )

  const openLabelDialog = useCallback(() => {
    openLabelsEdit({
      artikel: Array.from(selected).map((select) => select.artikel),
      callback: {
        then: () => reload()
      }
    })
  }, [openLabelsEdit, selected, reload])

  const topActions = useMemo(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Labels bearbeiten',
        icon: 'label',
        onClick: openLabelDialog
      }
    ],
    [openLabelDialog]
  )

  const getRowStyle = useCallback(
    (row: any) => {
      if (row.error) return { backgroundColor: red[darkMode ? 800 : 100] }
      return getArchiviertRowStyle(row?.archiviert)
    },
    [darkMode]
  )

  return (
    <DataTableCard
      name="VkPreisMassenPflegeTable"
      filterMode="both"
      title="Verkaufspreise"
      columns={columns}
      actions={tableActions}
      topActions={topActions}
      value={data}
      dense
      paging
      selectCol
      selectMode="multi"
      selected={selected}
      onSelect={onSelect}
      bottomActions={actions}
      stickyColumsLeft={3}
      rowStyle={getRowStyle}
      localStateName="VkPreisMassenPflegeTable"
    />
  )
}
