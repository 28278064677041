import {alpha, createTheme, ThemeProvider} from '@mui/material'
import { indigo } from '@mui/material/colors'
import { asNumber } from '@utils/utils'
import { createContext, ReactNode, useMemo, useState } from 'react'
import { hagebauGreen } from "@utils/ui/styles";

export type ThemeContextType = {
  darkMode: boolean
  setDarkMode: (mode: any) => void
  screenScale: number
  setScreenScale: (scale: any) => void
}

const init = {
  darkMode: false,
  setDarkMode: (_mode) => {},
  screenScale: 1,
  setScreenScale: (_scale) => {}
} as ThemeContextType

export const ThemeContext = createContext<ThemeContextType>(init)

const safeScreenScale = (scale: any, usedef: boolean = false) => {
  switch (asNumber(scale)) {
    case 12:
      return 12
    case 14:
      return 14
    default:
      return usedef ? 16 : undefined
  }
}

export interface ThemeProps {
  custom: (mode: any) => any
  children: ReactNode
}

export const Theme = ({ custom, children }: ThemeProps) => {
  const [darkMode, setDarkModeInt] = useState<any>(
    () => localStorage.getItem('darkMode') === 'true'
  )
  const [screenScale, setScreenScaleInt] = useState<number>(() =>
    safeScreenScale(localStorage.getItem('screenScale'), true)
  )
  const theme = useMemo(() => {
    const c = custom(darkMode)
    const palette = c.palette || {}
    const typography = c.typography || {}
    const components = c.components || {}
    return createTheme({
      ...c,
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          smallDesktop: 1280,
          lg: 1600,
          xl: 2400
        }
      },
      components: {
        ...components,
        MuiButton: {
          styleOverrides: {
            text: {
              '&:hover': {
                backgroundColor: alpha(hagebauGreen,0.3)
              }
            },
            outlined: {
              '&:hover': {
                backgroundColor: alpha(hagebauGreen,0.3)
              }
            }
          }
        },
        MuiCssBaseline: {
          styleOverrides: {
            html: {
              height: '100%',
              overflow: 'hidden',
              margin: 0,
              padding: 0,
              fontSize: safeScreenScale(screenScale)
            },
            body: {
              height: '100%',
              overflow: 'hidden',
              margin: 0,
              padding: 0
            },
            '#root': {
              height: '100%',
              overflow: 'hidden',
              margin: 0,
              padding: 0
            },
            'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus': {
              WebkitTextFillColor: darkMode ? 'white' : 'black',
              WebkitBoxShadow: `0 0 0 30px ${darkMode ? '#424242' : '#fff'} inset !important`,
              filter: 'none'
            },
            textarea: {
              color: darkMode ? 'white' : 'black',
              backgroundColor: darkMode ? '#424242' : '#fff',
              '&:focus': {
                outline: 'none'
              }
            },
            a: {
              color: darkMode ? indigo[200] : indigo[800]
            }
          }
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundImage: 'unset',
              backgroundColor: darkMode ? '#424242' : null
            }
          }
        },
        MuiLink: {
          styleOverrides: {
            root: {
              color: darkMode ? indigo[100] : indigo[700]
              // textDecorationColor: darkMode ? indigo[100] : indigo[700]
            }
          }
        }
      },
      palette: {
        ...palette,
        background: {
          ...palette.background,
          paper: darkMode ? '#424242' : '#fff'
        },
        mode: darkMode ? 'dark' : 'light'
      },
      typography: {
        ...typography,
        useNextVariants: true
      },
      darkMode
    })
  }, [custom, darkMode, screenScale])

  const context = useMemo(() => {
    const setDarkMode = (v) => {
      const d = v.target ? v.target.checked : v === true
      setDarkModeInt(d)
      localStorage.setItem('darkMode', d ? 'true' : 'false')
    }

    const setScreenScale = (v) => {
      const d = v?.target?.value
      setScreenScaleInt(d)
      localStorage.setItem('screenScale', d)
    }

    return {
      darkMode,
      setDarkMode,
      screenScale,
      setScreenScale
    }
  }, [darkMode, screenScale])

  return (
    <ThemeContext.Provider value={context}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  )
}
