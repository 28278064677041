import { api } from '@one/api'
import {
  PreisEbeneDisplayJson,
  UseCaseStateJson,
  VkKalkulationsvorschlagJson,
  VkPreisGruppeJson,
  VkPreiseBearbeitenKopfJson,
  VkRundungsregelJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { buildMap, compareStrings, safeArray } from '@utils/utils'
import { useCallback, useEffect, useRef, useState } from 'react'

export interface VkPreiseBearbeitenKopfEx extends VkPreiseBearbeitenKopfJson {
  eintragId: number | null
  artikelPos: number
  artikelCount: number
  fatalError: UseCaseStateJson
  standorteMap?: Map<number, PreisEbeneDisplayJson>
  standort0Id?: number
  preisgruppenMap?: Map<number, VkPreisGruppeJson>
  kalkulationsvorschlaegeMap?: Map<number, VkKalkulationsvorschlagJson>
  rundungsregelnMap?: Map<number, VkRundungsregelJson>
}

const empty = {
  artikelListe: [],
  artikelPos: -1,
  artikelCount: 0,
  fatalError: null
} as VkPreiseBearbeitenKopfEx

export type HasName = { name?: string }
export const compareNames = (a: HasName, b: HasName) => compareStrings(a.name, b.name)

export const useVkPreisKopf = (preislisteId: number, eintragId: number) => {
  const [kopf, setKopf] = useState<VkPreiseBearbeitenKopfEx>(empty)
  const lastRef = useRef<number>(null)
  const [apiCall, kopfLoading] = useApiCaller(api)

  const reloadKopf = useCallback(() => {
    apiCall<VkPreiseBearbeitenKopfJson>({
      method: 'GET',
      rest: 'vkpreisliste/open',
      params: { id: preislisteId },
      onSuccess: (data) => {
        const artikelListe = safeArray(data.artikelListe)
        const eintrId = eintragId || (artikelListe.length > 0 ? artikelListe[0].id : null)
        const fp = artikelListe.findIndex((i) => i.id === Number(eintrId))
        const kalkulationsvorschlaege = safeArray(data.kalkulationsvorschlaege).sort(compareNames)
        const kalkulationsvorschlaegeMap = buildMap(kalkulationsvorschlaege, (b) => b.id)
        const preisgruppen = safeArray(data.preisgruppen).sort(compareNames)
        const preisgruppenMap = buildMap(preisgruppen, (b) => b.id)
        const standorte = safeArray(data.standorte).sort((a, b) => compareStrings(a.nr, b.nr))
        const standorteMap = buildMap(standorte, (b) => b.id)
        const standort0Id = (standorte.find((s) => s.defaultStandort) || {}).id
        const rundungsregeln = safeArray(data.rundungsregeln).sort(compareNames)
        const rundungsregelnMap = buildMap(rundungsregeln, (b) => b.id)
        setKopf({
          id: data.id,
          eintragId: eintrId,
          artikelListe,
          artikelPos: fp >= 0 ? fp + 1 : -1,
          artikelCount: artikelListe.length,
          standorte,
          standorteMap,
          standort0Id,
          preisgruppen,
          preisgruppenMap,
          kalkulationsvorschlaege,
          kalkulationsvorschlaegeMap,
          rundungsregeln,
          rundungsregelnMap
        } as VkPreiseBearbeitenKopfEx)
      },
      onError: (error) => setKopf({ ...empty, fatalError: error })
    })
  }, [apiCall, eintragId, preislisteId])

  useEffect(() => {
    if (lastRef.current !== preislisteId) {
      lastRef.current = preislisteId
      setKopf(empty)
      reloadKopf()
    }
  }, [apiCall, eintragId, preislisteId, reloadKopf])

  useEffect(() => {
    if (kopf.id != null && eintragId !== kopf.eintragId) {
      const seek = eintragId || kopf.eintragId
      const fp = kopf.artikelListe.findIndex((i) => i.id === Number(seek))
      setKopf((old) => ({
        ...old,
        artikelPos: fp >= 0 ? fp + 1 : -1,
        eintragId: seek
      }))
    }
  }, [eintragId, kopf.artikelListe, kopf.eintragId, kopf.id])

  return { kopf, reloadKopf, kopfLoading }
}
