import { Grid } from '@mui/material'
import { PimArtikelVersionJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'

export interface ArtikelPimMasseProps {
  model: PimArtikelVersionJson
}

export const ArtikelPimMasse = ({ model }: ArtikelPimMasseProps) => {
  if (!model) {
    return null
  }
  model.anzahlPackstuecke

  return (
    <CardEx title="Maße">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Breite netto"
            value={model.artikelBreiteNetto}
            name="artikelBreiteNetto"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Höhe netto"
            value={model.artikelHoeheNetto}
            name="artikelHoeheNetto"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Tiefe netto"
            value={model.artikelTiefeNetto}
            name="artikelTiefeNetto"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Länge brutto"
            value={model.artikelLaengeBrutto}
            name="artikelLaengeBrutto"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Gewicht brutto"
            value={model.artikelGewichtBrutto}
            name="artikelGewichtBrutto"
            empty
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
