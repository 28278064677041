import { Grid } from '@mui/material'
import { PimArtikelJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'

export interface ArtikelPimSpracheProps {
  artikel: PimArtikelJson
}

export const ArtikelPimSprache = ({ artikel }: ArtikelPimSpracheProps) => {
  if (!artikel || !artikel.version?.sprache) {
    return null
  }

  const sprache = artikel.version.sprache

  return (
    <CardEx title={`Texte ${sprache?.sprache?.name ?? ''}`} name="texte">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Lieferschein 1"
            value={sprache.lieferschein1}
            name="lieferschein1"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Lieferschein 2"
            value={sprache.lieferschein2}
            name="lieferschein2"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Lieferschein 3"
            value={sprache.lieferschein3}
            name="lieferschein3"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Lieferschein 4"
            value={sprache.lieferschein4}
            name="lieferschein4"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="Etikett 1" value={sprache.etikett1} name="etikett1" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="Etikett 2" value={sprache.etikett2} name="etikett2" empty />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12} />
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Sellingpoint 1"
            value={sprache.sellingpoint1}
            name="sellingpoint1"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Sellingpoint 2"
            value={sprache.sellingpoint2}
            name="sellingpoint2"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Sellingpoint 3"
            value={sprache.sellingpoint3}
            name="sellingpoint3"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Sellingpoint 4"
            value={sprache.sellingpoint4}
            name="sellingpoint4"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Sellingpoint 5"
            value={sprache.sellingpoint5}
            name="sellingpoint5"
            empty
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="Plakattext 1" value={sprache.plakattext1} name="plakattext1" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="Plakattext 2" value={sprache.plakattext2} name="plakattext2" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="Plakattext 3" value={sprache.plakattext3} name="plakattext3" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="Plakattext 4" value={sprache.plakattext4} name="plakattext4" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="Plakattext 5" value={sprache.plakattext5} name="plakattext5" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="Plakattext 6" value={sprache.plakattext6} name="plakattext6" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="Plakattext 7" value={sprache.plakattext7} name="plakattext7" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="Plakattext 8" value={sprache.plakattext8} name="plakattext8" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Plakatlayout"
            value={artikel.version.plakatlayout}
            name="plakatlayout"
            empty
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Produktreferenz"
            value={sprache.produktreferenz}
            name="produktreferenz"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Produkt-Dimension 1"
            value={sprache.produktDimension1}
            name="produktDimension1"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Produkt-Dimension 2"
            value={sprache.produktDimension2}
            name="produktDimension2"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Produkt-Dimension 3"
            value={sprache.produktDimension3}
            name="produktDimension3"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="Farbe" value={sprache.farbe} name="farbe" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Lieferumfang"
            value={sprache.lieferumfang}
            name="lieferumfang"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="Material" value={sprache.material} name="material" empty />
        </Grid>
      </Grid>
    </CardEx>
  )
}
