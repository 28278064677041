import { Grid } from '@mui/material'
import { PimArtikelJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'

export interface ArtikelPimPimProduktProps {
  artikel: PimArtikelJson
}

export const ArtikelPimPimProdukt = ({ artikel }: ArtikelPimPimProduktProps) => {
  if (artikel?.version == null || artikel.version.pimProdukt?.version == null) {
    return null
  }

  const pimProdukt = artikel.version.pimProdukt

  return (
    <CardEx title="Produkt" name="Pimprodukt">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="hage-Nr." value={pimProdukt.hageNummer} name="hageNummer" />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Zolltarif-Nummer"
            value={pimProdukt.version.zolltarifnummer}
            name="zolltarifnummer"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Statistische Warennummer"
            value={pimProdukt.version.statistischeWarennummer}
            name="statisitscheWarennummer"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Marke" value={pimProdukt.version.marke} name="marke" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Vertriebskanal"
            value={pimProdukt.version.vertriebskanal}
            name="vertriebskanal"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Primärklassifikation"
            value={pimProdukt.version?.sprache?.primaerklassifikation}
            name="primaerklassifikation"
            empty
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
