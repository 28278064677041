import { ArrowBack } from '@mui/icons-material'
import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  LinearProgress,
  Paper,
  Typography
} from '@mui/material'
import { UseCaseStateJson } from '@one/typings/apiTypings'
import { formatDurationToNow, toDate } from '@utils/dateutils'
import { Button } from '@utils/ui/Buttons/Button'
import { UILockMode, UILockType } from '@utils/uilock'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { BlurPlane } from './BlurPlane'
import { GlassPlane } from './GlassPlane'
// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles()({
  progressSpeed: {
    '& .MuiLinearProgress-bar': {
      transition: 'none'
      // animationDuration: '0.2s'
    }
  }
})

export const noLock = {} as UILockType

export type StatePlaneProps = {
  wait?: boolean
  error?: UseCaseStateJson
  altLink?: string
  closeLabel?: string
  noNav?: boolean
  uiLock?: UILockType
  children: React.ReactNode
  onClose?: () => void
}

export const StatePlane = ({
  wait = false,
  error = null,
  uiLock = noLock,
  altLink,
  closeLabel,
  noNav = false,
  onClose = null,
  children
}: StatePlaneProps) => {
  const { classes } = useStyles()
  const navigate = useNavigate()

  const showNav = !noNav && !uiLock?.onClose

  const mode = uiLock?.mode || (error && UILockMode.ERROR) || (wait && UILockMode.WAIT) || null

  const lock = !!wait || mode != null

  const overlayColor = mode === UILockMode.ERROR ? 'rgb(255, 161 ,161, 25%)' : undefined

  const closeHandler = uiLock.onClose || onClose

  const closeBtnLabel = uiLock.onClose ? 'Zurück' : 'Schließen'

  const reload = uiLock.reload

  const overlay = useMemo(() => {
    switch (mode) {
      case UILockMode.WAIT: {
        return (
          <Grid container direction="column" width="auto" alignItems="center">
            <Grid item>
              <CircularProgress size={120} />
            </Grid>
            {uiLock.waitMessage ? (
              <Grid item paddingTop={3}>
                <Paper style={{ padding: 12 }} elevation={8}>
                  <Grid container spacing={3}>
                    {uiLock.waitMessage ? (
                      <Grid item>
                        <Typography variant="body1">{uiLock.waitMessage}</Typography>
                      </Grid>
                    ) : null}
                    {/* {waitMessage ? (
                      <Grid item>
                        <Typography variant="h5">{waitMessage}</Typography>
                      </Grid>
                    ) : null} */}
                  </Grid>
                </Paper>
              </Grid>
            ) : null}
          </Grid>
        )
      }

      case UILockMode.RETRY: {
        const info = uiLock.title || 'Die Verarbeitung wird abgeschlossen, bitte warten...'
        return (
          <Card elevation={8}>
            <CardContent>
              <Grid container direction="column" width="auto" alignItems="center">
                <Grid item>
                  <Typography variant="h5">{info}</Typography>
                </Grid>
                {uiLock.error?.mainMessage?.message ? (
                  <Grid item>
                    <Typography variant="body2" component="p">
                      {uiLock.error?.mainMessage?.message}
                    </Typography>
                  </Grid>
                ) : null}
                {uiLock.error?.messages ? (
                  <Grid item>
                    <Typography variant="body2" component="p">
                      {uiLock.error?.messages?.map((m: any) => m.message).join('\n')}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item padding={3}>
                  <CircularProgress size={120} />
                </Grid>
                {uiLock.waitMessage ? (
                  <Grid item>
                    <Typography variant="body1">{uiLock.waitMessage}</Typography>
                  </Grid>
                ) : null}
              </Grid>
            </CardContent>
            <CardActions style={{ justifyContent: 'space-around' }}>
              {showNav && history.length > 0 ? (
                <Button
                  label="Zurück"
                  size="small"
                  startIcon={<ArrowBack />}
                  onClick={() => navigate(-1)}
                />
              ) : null}
              {showNav && altLink && <Button label="Zur Übersicht" to={altLink} size="small" />}
              {uiLock.cancelCallback || uiLock.cancelled ? (
                <Button
                  variant="contained"
                  onClick={uiLock.cancelCallback}
                  label={uiLock.cancelled ? 'Wird abgebrochen...' : 'Abbrechen'}
                  disabled={uiLock.cancelled}
                  color="secondary"
                />
              ) : null}
            </CardActions>
          </Card>
        )
      }

      case UILockMode.ASYNC_WAIT: {
        const at = uiLock.asyncTask || {}
        const s1 = at.aktCount
        const s2 = at.totalCount === 0 ? null : at.totalCount
        const laufzeit = formatDurationToNow(toDate(at.start))
        const waitTitle = (uiLock.title || 'Bitte warten...').split('\n')
        const progress = s1 != null && s2 != null ? Math.min((s1 / s2) * 100, 100) : null
        const progressMsg = `Gestartet vor ${laufzeit}`

        return (
          <Card elevation={8}>
            <CardContent>
              <Grid container direction="column" width="auto" alignItems="center">
                {waitTitle.length > 0 ? (
                  <Grid item paddingBottom={3}>
                    <Typography variant="h5">{waitTitle[0]}</Typography>
                    {waitTitle
                      .filter((msg, idx) => idx > 0)
                      .map((msg) => (
                        <Typography key={msg} variant="body1">
                          {msg}
                        </Typography>
                      ))}
                  </Grid>
                ) : null}
                {at.messages ? (
                  <Grid item paddingBottom={3}>
                    {at.messages.map((msg) => (
                      <Typography key={msg.message} variant="body1">
                        {msg.message}
                      </Typography>
                    ))}
                  </Grid>
                ) : null}
                <Grid item textAlign="center" paddingTop={0}>
                  <Typography variant="body2">{progressMsg}</Typography>
                </Grid>
                <Grid item width="30vw">
                  <LinearProgress
                    className={progress == null ? null : classes.progressSpeed}
                    value={progress}
                    variant={progress != null ? 'determinate' : 'indeterminate'}
                    style={{ height: '12px' }}
                  />
                </Grid>
                <Grid item textAlign="center" paddingTop="2px">
                  <Typography variant="body2">{at.progressInfo}</Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions style={{ justifyContent: 'space-around' }}>
              {closeHandler ? (
                <Button
                  variant="contained"
                  onClick={closeHandler}
                  label={closeLabel || closeBtnLabel}
                />
              ) : null}
              {showNav && history.length > 0 ? (
                <Button
                  label="Zurück"
                  size="small"
                  startIcon={<ArrowBack />}
                  onClick={() => navigate(-1)}
                />
              ) : null}
              {showNav && altLink && <Button label="Zur Übersicht" to={altLink} size="small" />}
            </CardActions>
          </Card>
        )
      }

      case UILockMode.ERROR: {
        const errorTitle = 'Der Vorgang hat einen Fehler gemeldet'
        const err = uiLock.error || error
        return (
          <Card elevation={8}>
            <CardContent>
              <Grid container direction="column" width="auto" alignItems="center">
                <Grid item textAlign="center">
                  <Typography variant="h5">{errorTitle}</Typography>
                </Grid>
                {err?.mainMessage?.message && (
                  <Grid item textAlign="center" paddingTop={0}>
                    <Typography variant="body2" component="h2">
                      {err.mainMessage?.message}
                    </Typography>
                  </Grid>
                )}
                {err.messages && (
                  <Grid item textAlign="center" paddingTop={0}>
                    <Typography variant="body2" component="p">
                      {err.messages?.map((m: any) => m.message).join('\n')}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <CardActions style={{ justifyContent: 'space-around' }}>
              {showNav && history.length > 0 ? (
                <Button label="Zurück" startIcon={<ArrowBack />} onClick={() => navigate(-1)} />
              ) : null}
              {reload ? <Button label="Neu laden" onClick={() => reload()} /> : null}
              {showNav && altLink && <Button label="Zur Übersicht" to={altLink} />}
              {closeHandler ? (
                <Button variant="contained" onClick={closeHandler} label={closeBtnLabel} />
              ) : null}
              {uiLock.retryCallback ? (
                <Button
                  variant="contained"
                  onClick={uiLock.retryCallback}
                  label="Erneut versuchen"
                />
              ) : null}
            </CardActions>
          </Card>
        )
      }
    }
  }, [
    mode,
    uiLock.waitMessage,
    uiLock.title,
    uiLock.error,
    uiLock.cancelCallback,
    uiLock.cancelled,
    uiLock.asyncTask,
    uiLock.retryCallback,
    showNav,
    altLink,
    navigate,
    classes.progressSpeed,
    closeHandler,
    closeLabel,
    closeBtnLabel,
    reload,
    error
  ])

  return (
    <GlassPlane show={lock} overlay={overlay} overlayColor={overlayColor}>
      <BlurPlane blur={lock}>{children}</BlurPlane>
    </GlassPlane>
  )
}
