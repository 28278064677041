import { SelectField } from '@utils/ui/fields/SelectField'
import { useMemo } from 'react'

export const KassenPreisBasisField = ({
  referenzPreisgruppeId,
  name = null,
  onChange,
  preisgruppen,
  readonly = null,
  required = null,
  emptyText = null
}) => {
  const options = useMemo(() => {
    const preisgruppenSafe = preisgruppen || []
    return preisgruppenSafe.filter((pg) => !pg.kasse)
  }, [preisgruppen])

  if (readonly) {
    if (referenzPreisgruppeId != null) {
      const pg = options.find((i) => i.id === referenzPreisgruppeId)
      return <div>{pg.name}</div>
    }
    return null
  }

  return (
    <SelectField
      value={referenzPreisgruppeId}
      name={name}
      onChange={onChange}
      disabled={readonly}
      renderItem={(a) => a.name}
      options={options}
      required={required}
      emptyText={emptyText}
      fullWidth
    />
  )
}
