import { PimMengeneinheitDisplayJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'

export type PimMengeneinheitFieldProps = {
  id?: string
  value: PimMengeneinheitDisplayJson
  values: PimMengeneinheitDisplayJson[]
  label?: string
  name: string
  onChange: ValueChangeFn<SeArtikelJson>
  fullWidth?: boolean
  disabled?: boolean
  error?: any
}

export const PimMengeneinheitField = ({
  id,
  value,
  values,
  label,
  name,
  onChange,
  fullWidth,
  disabled,
  error
}: PimMengeneinheitFieldProps) => {
  return (
    <AutocompleteEx<PimMengeneinheitDisplayJson>
      id={id}
      name={name}
      label={label}
      value={value}
      options={values}
      onChange={onChange}
      optionLabel={(option) => option.name}
      fullWidth={fullWidth}
      disabled={disabled}
      error={error}
    />
  )
}
