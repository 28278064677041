import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import {
  LieferantAboDisplayJson,
  LieferantAboSearcherLieferantAboCriteriaJson
} from '@one/typings/apiTypings'
import { useObserver } from '@utils/observer'
import { useQuery } from '@utils/routing'
import { useSearcherState } from '@utils/searcher'
import { AppRouteCtx } from '@utils/ui/App/AppRouteCtx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useContext, useEffect, useRef, useState } from 'react'
import { LieferantAboUebersichtParams } from './LieferantAboUebersichtParams'
import { LieferantAboUebersichtTable } from './LieferantAboUebersichtTable'

const mitStatistik = false // wg performance

const initialData = ({ aenderungsTyp }) => ({
  name: '',
  aenderungsTyp,
  abonniertStatus: null,
  mitStatistik
})

export const LieferantAboUebersichtView = () => {
  const firstRef = useRef(true)
  const firstWithParamRef = useRef(true)

  const { visible } = useContext(AppRouteCtx)

  const [{ [AppPaths.LieferantAboUebersichtQueries.aenderungsTyp]: aenderungsTyp }, setQuery] =
    useQuery()

  const [lastLieferantenAenderungsTyp, setLastLieferantenAenderungsTyp] = useState(aenderungsTyp)

  const { criteria, result, search, searchEx, onCriteriaChange } = useSearcherState<
    LieferantAboSearcherLieferantAboCriteriaJson,
    LieferantAboDisplayJson
  >({
    api,
    url: '/lieferantabo',
    initialParams: initialData({ aenderungsTyp }),
    doOpen: false,
    handleOpenParams: (params) => {
      return { ...params, mitStatistik: false }
    },
    valueDecorator: (path, v) => {
      switch (path) {
        case 'name':
          return v ? v.toUpperCase() : ''
        default:
          return v
      }
    },
    stateInterceptor: (path, state) => {
      switch (path) {
        case 'aenderungsTyp':
          return { ...state, name: '', abonniertStatus: null, lieferant: null, mitStatistik }
        default:
          return { ...state, mitStatistik }
      }
    },
    onData: (data) => {
      setLastLieferantenAenderungsTyp(data.searcher.criteria.aenderungsTyp)
      return data
    }
  })

  const aenderungsTypRef = useRef(aenderungsTyp)

  useEffect(() => {
    if (criteria.aenderungsTyp !== aenderungsTypRef.current) {
      aenderungsTypRef.current = criteria.aenderungsTyp
      setQuery({
        [AppPaths.LieferantAboUebersichtQueries.aenderungsTyp]: null
      })
    }
  }, [criteria.aenderungsTyp, setQuery])

  const visibleRef = useRef(visible)

  useEffect(() => {
    if (visible != visibleRef.current) {
      visibleRef.current = visible
      if (!visible) {
        return
      }
      if (aenderungsTyp) {
        firstRef.current = true
      }
    }
    if (firstRef.current) {
      firstRef.current = false
      if (aenderungsTyp) {
        search()
      } else {
        onCriteriaChange(initialData) // oder defaults aus Params
      }
    } else if (firstWithParamRef.current && aenderungsTyp) {
      firstWithParamRef.current = false
      searchEx(initialData({ aenderungsTyp }))
    }
  }, [aenderungsTyp, search, onCriteriaChange, searchEx, visible])

  useEffect(() => {
    firstWithParamRef.current = true
  }, [aenderungsTyp])

  useObserver(EventNames.ARTIKELABO, () => {
    search()
  })

  return (
    <Frame space>
      <FrameRow>
        <LieferantAboUebersichtParams
          value={criteria}
          onSearch={search}
          onChange={onCriteriaChange}
        />
      </FrameRow>
      <FrameBody>
        <LieferantAboUebersichtTable
          result={result}
          lieferantenAenderungsTyp={lastLieferantenAenderungsTyp}
          reload={search}
        />
      </FrameBody>
    </Frame>
  )
}
