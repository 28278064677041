import { TableRows } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { ArtikelPager } from '@one/components/common/ArtikelPager'
import { BetriebstypKontextField } from '@one/components/common/BetriebstypKontextField'
import { formatWarengruppe } from '@one/components/common/WarengruppeCell'
import { VkPreisListeEintragDisplayJson } from '@one/typings/apiTypings'
import { formatDateRange } from '@utils/dateutils'
import { AppContext } from '@utils/ui/App/AppContext'
import { Button } from '@utils/ui/Buttons/Button'
import { CardEx } from '@utils/ui/CardEx'
import { StaticField } from '@utils/ui/fields/StaticField'
import { formatMoney2, formatValues } from '@utils/utils'
import { useContext } from 'react'
import { makeStyles } from 'tss-react/mui'
import { VkPreiseBearbeitenEdit } from './model/VkPreisTypes'

// Styling definitions
const useStyles = makeStyles()({
  flexGrow: {
    flexGrow: 1
  },
  width100: {
    width: '100%'
  },
  deleteButton: {
    paddingLeft: '15px'
  },
  fields: {
    marginTop: '-32px'
  },
  artikelRow: {
    borderBottom: '1px solid black'
  },
  artikel: {
    fontWeight: '100'
  },
  headline: {
    marginTop: '-12px',
    marginBottom: '-12px'
  },
  title: {
    flexGrow: 1
  },
  abgeschlossen: {
    padding: '0px 8px',
    fontSize: '13px'
  }
})

interface RenderPreisInfoProps {
  label: string
  labelEk: string
  value: string
  valueEk: string
  style?: any
}
export interface VkPreisPflegeKopfProps {
  isChanged: boolean
  artikelList: VkPreisListeEintragDisplayJson[]
  artikelListLoading: boolean
  artikelPos: number
  onSwitchArtikel: (id: number) => void
  model: VkPreiseBearbeitenEdit
  standortId?: number
  preislisteId?: number
}

export const VkPreisPflegeKopf = ({
  standortId,
  isChanged,
  artikelList,
  artikelListLoading,
  artikelPos,
  onSwitchArtikel,
  preislisteId,
  model
}: VkPreisPflegeKopfProps) => {
  const { classes } = useStyles()

  const { isPIMModel, isFachhandel, isEinzelhandel } = useContext(AppContext)

  const artikel = model.artikel || {}

  const artikelBez = artikel.bez1 && formatValues(', ', artikel.bez1, artikel.bez2)

  const lieferant = model.lieferant || {}

  const lieferantName =
    lieferant.name1 && formatValues(' ', lieferant.name1, lieferant.name2, `(${lieferant.nummer})`)

  const neueKondi = model.neueKondi

  const name = model.name || ''

  const alleAbgeschlossen =
    neueKondi.blaetter.filter((b) => b.abgeschlossen).length ===
    neueKondi.blaetter.filter((b) => b.standortVk).length

  const listenpreis =
    (standortId != null &&
      model.neueKondi.blaetter.find((blatt) => blatt.standortId === standortId)
        ?.effektiverListenpreis) ||
    neueKondi.listenpreis

  const ekFreiHaus =
    (standortId != null &&
      model.neueKondi.blaetter.find((blatt) => blatt.standortId === standortId)
        ?.effektiverEkFreiHaus) ||
    neueKondi.ekFreiHaus

  const ekFreiHausInfo = ekFreiHaus == null ? 'Listenpreis' : ''

  const header = (
    <Grid container spacing={2}>
      <Grid item className={classes.title}>
        <Typography variant="h6">{`${model.forEinzelhandel ? 'Einzelhandelverkaufspreis' : 'Fachhandelverkaufspreis'} für Artikel '${artikelBez}'`}</Typography>
      </Grid>
      <Grid item>
        <Button
          key="artikel"
          label="Artikelansicht"
          variant="outlined"
          size="small"
          to={artikel.id ? AppPaths.ArtikelFn(artikel.id) : null}
        />
      </Grid>
      <Grid item>
        <ArtikelPager
          artikelList={artikelList}
          artikelListLoading={artikelListLoading}
          artikelPos={artikelPos}
          onSwitchArtikel={onSwitchArtikel}
          disabled={isChanged}
        />
      </Grid>
      {artikel.archiviert && (
        <Grid item xs={12}>
          <Typography variant="h6" color={'#ff0000'}>
            Archivierter Artikel
          </Typography>
        </Grid>
      )}
    </Grid>
  )

  const RenderPreisInfo = ({ label, labelEk, value, valueEk, style }: RenderPreisInfoProps) => {
    let fieldValue = value
    let fieldLabel = label
    if (
      neueKondi.mengenEinheit !== neueKondi.mengenEinheitEkEinheit ||
      neueKondi.preismenge !== neueKondi.preismengeEkEinheit
    ) {
      fieldValue = `${fieldValue} (${valueEk})`
      fieldLabel = `${fieldLabel} (${labelEk})`
    }

    return <StaticField label={fieldLabel} style={style} value={fieldValue} fullWidth />
  }

  return (
    <CardEx
      backLink
      overviewLink={AppPaths.VkPreisUebersicht[0]}
      navLinks={[
        {
          name: 'nav-massenpflege',
          icon: <TableRows />,
          label: 'Zur Massenpflege',
          to: AppPaths.VkPreisMassenPflegeFn(preislisteId)
        }
      ]}
      header={header}
    >
      <Grid container direction="row" spacing={2}>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <StaticField label="Artikel-Nr." value={artikel.btNummer} fullWidth />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <StaticField label="hage-Nr." value={artikel.hageNummer} fullWidth />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <StaticField label="EAN" value={artikel.ean} fullWidth />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <StaticField label="IAN" value={artikel.industrieArtikelNummer} fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <StaticField label="Lieferant" value={lieferantName} fullWidth />
          </Grid>
          <Grid item xs={12} md={2}>
            <BetriebstypKontextField owner={model} />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <StaticField label="Warengruppe" value={formatWarengruppe(artikel?.warengruppe)} />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <RenderPreisInfo
              label="EKFreiH"
              labelEk="EK-Freihaus-Einheit"
              style={ekFreiHausInfo ? { fontSize: '80%', opacity: 0.8 } : null}
              value={ekFreiHausInfo || formatMoney2(ekFreiHaus)}
              valueEk={
                neueKondi.ekFreiHausEkEinheit
                  ? formatMoney2(neueKondi.ekFreiHausEkEinheit)
                  : 'Listenpreis'
              }
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <RenderPreisInfo
              label="Listenpreis"
              labelEk="EK-Listenpreis"
              value={formatMoney2(listenpreis)}
              valueEk={formatMoney2(neueKondi.listenpreisEkEinheit)}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <RenderPreisInfo
              label="Mengeneinheit"
              labelEk="EK-Mengeneinheit"
              value={
                neueKondi.preismenge == null
                  ? null
                  : `${neueKondi.preismenge} ${neueKondi.mengenEinheit}`
              }
              valueEk={
                neueKondi.preismengeEkEinheit == null
                  ? null
                  : `${neueKondi.preismengeEkEinheit} ${neueKondi.mengenEinheitEkEinheit}`
              }
            />
          </Grid>

          <Grid item sm={12} md={6} lg={2}>
            <StaticField label="Name der Preisliste" value={name} placeholder="leer" fullWidth />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <StaticField
              label="Gültigkeitszeitraum"
              value={formatDateRange(neueKondi.gueltigVon, neueKondi.gueltigBis)}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </CardEx>
  )
}
