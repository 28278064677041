import { Grid } from '@mui/material'
import { ErpArtikelDetailsJson, PimArtikelJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { StaticField } from '@utils/ui/fields/StaticField'

export interface ArtikelPimKennzeichenProps {
  artikel: PimArtikelJson
  details: ErpArtikelDetailsJson | null
}

export const ArtikelPimEigenschaften = ({ artikel, details }: ArtikelPimKennzeichenProps) => {
  if (!artikel || !artikel.version) {
    return null
  }
  return (
    <CardEx title="Kennzeichen">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Artikelart"
            value={artikel.version.artikelArt}
            name="ArtikelArt"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="hage-Nr." value={artikel.hageNummer} name="hageNummer" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Basis-Warengruppe"
            value={artikel.version.basisWarengruppe}
            name="basisWarengruppe"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="BDB-Warengruppe"
            value={artikel.version.bdbWarengruppe}
            name="bdbWarengruppe"
            empty
          />
        </Grid>
        {details?.warengruppe && (
          <Grid item xs={12} sm={6} md={4}>
            <StaticField
              label="Warengruppe"
              value={`${details.warengruppe.nummer} - ${details.warengruppe.name}`}
              name="warengruppe"
              empty
            />
          </Grid>
        )}
        {details?.sparte && (
          <Grid item xs={12} sm={6} md={4}>
            <StaticField
              label="Sparte"
              value={`${details.sparte.nr} - ${details.sparte.bezeichnung}`}
              name="sparte"
              empty
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="EAN" value={artikel.version.gtin} name="gtin" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Match-Code"
            value={artikel.versionKontext?.matchcode}
            name="matchcode"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            name="vorlaeufer"
            label="Vorläufer"
            value={artikel.version.vorlaeufer}
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            name="nachfolger"
            label="Nachfolger"
            value={artikel.version.nachfolger}
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Produkt-Typ"
            value={artikel.version.produkttyp}
            name="produktTyp"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Anzahl Packstücke"
            value={artikel.version.anzahlPackstuecke}
            name="anzahlPackstuecke"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Chargenpflicht"
            value={artikel.version.chargenpflicht ? 'Ja' : 'Nein'}
            name="chargenpflicht"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Grundpreisauszeichnungspflicht"
            value={artikel.version.grundpreisauszeichnungspflicht ? 'Ja' : 'Nein'}
            name="grundpreisauszeichnungspflicht"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Intrastat Mengeneinheit"
            value={artikel.version.intrastatMengeneinheit}
            name="intrastatMengeneinheit"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="intrastat Region"
            value={artikel.version.intrastatRegion}
            name="intrastatRegion"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Materialgruppe"
            value={artikel.version.materialgruppe}
            name="materialgruppe"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            name="eigenmarke"
            label="Eigenmarke"
            checked={artikel.version.eigenmarke}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Baustein-Kennzeichen"
            name="bausteinKennzeichen"
            checked={artikel.version.bausteinKennzeichen}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Arena-Kennzeichen"
            name="arenaKennzeichen"
            checked={artikel.version.arenaKennzeichen}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            name="gfcArtikel"
            label="GFC-Artikel"
            checked={artikel.version.gfcArtikel}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Rundungsschema-Relevant"
            name="rundungschemaRelevanz"
            checked={artikel.version.rundungschemaRelevanz}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Preisbindung"
            name="preisbindung"
            checked={artikel.version.preisbindung}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Rabattfähig"
            name="rabattfaehig"
            checked={artikel.version.rabattfaehig}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Skontofähig"
            name="skontofaehig"
            checked={artikel.version.skontofaehig}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            name="ohneVerkaufspreis"
            label="ohne Verkaufspreis"
            checked={artikel.version.ohneVerkaufspreis}
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
