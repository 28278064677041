import { Grid, Typography } from '@mui/material'
import { ArtikelCell } from '@one/components/common/ArtikelCell'
import { ArtikelDatenpoolUebernahmeJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'

export interface UebernahmeEigenlistungDetailsProps {
  artikel: ArtikelDatenpoolUebernahmeJson
}

export const UebernahmeEigenlistungDetails = ({ artikel }: UebernahmeEigenlistungDetailsProps) => {
  const fehler =
    artikel && artikel.fehler ? (
      <Grid item flexGrow={1}>
        <CardEx title="Fehler" height="100%" borderless>
          {artikel.fehler.subMessages.map((item, index) => (
            <div key={`${item.messageId.id}-${index}`}>
              <Typography variant="body2">{item.message}</Typography>
            </div>
          ))}
        </CardEx>
      </Grid>
    ) : null

  const artikelList =
    artikel && artikel.artikel?.length ? (
      <Grid item flexGrow={1}>
        <CardEx
          title="Artikel, die eine Anlage verhindern"
          subheader="hage-Nr., IAN oder EAN werden schon verwendet:"
          height="100%"
          borderless
        >
          {artikel.artikel
            .filter((a, idx) => artikel.artikel.findIndex((aa) => aa.id == a.id) == idx)
            .map((item) => (
              <ArtikelCell key={`${item.id}`} artikel={item} asLink />
            ))}
        </CardEx>
      </Grid>
    ) : null

  return (
    <Grid container direction="column" spacing={1} height="calc( 100% + 8px )" marginBottom={-1}>
      {fehler}
      {artikelList}
    </Grid>
  )
}
