import { Grid } from '@mui/material'
import { PimLandField } from '@one/components/common/PimLandField'
import {
  PimArtikelVersionLandJson,
  PimLandDisplayJson,
  SeArtikelJson
} from '@one/typings/apiTypings'
import { ModelAction, onChangeWrapper, ValueChangeFn } from '@utils/modelmgr'
import { RowForm } from '@utils/ui/fields/RowForm'
import { TextField } from '@utils/ui/fields/TextField'
import { aidOf, resolveObjectField } from '@utils/utils'
import React from 'react'
import { EigenlistungsArtikelUseCase } from './model/EigenlistungsArtikelUseCase'

type PimArtikelSteuerindikationProps = {
  land: PimArtikelVersionLandJson
  laender: PimLandDisplayJson[]
  onChange: ValueChangeFn<SeArtikelJson>
  errors: any
  errorsPath: string
}

const PimArtikelSteuerindikation = ({
  land,
  laender,
  onChange,
  errors,
  errorsPath
}: PimArtikelSteuerindikationProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={3} xl={1}>
        <PimLandField
          label="Land"
          name="land"
          value={land.land}
          values={laender}
          onChange={onChange}
          error={resolveObjectField(errors, `${errorsPath}.land`)}
          long
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3} xl={2}>
        <TextField
          label="Steuerindikation"
          name="steuerindikation"
          value={land.steuerindikation}
          onChange={onChange}
          error={resolveObjectField(errors, `${errorsPath}.steuerindikation`)}
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

export interface PimArtikelSteuerindikationenProps {
  steuerLaender: PimArtikelVersionLandJson[]
  laender: PimLandDisplayJson[]
  onChange: ValueChangeFn<SeArtikelJson>
  dispatch: (action: ModelAction) => void
  errors?: any
}

export const PimArtikelSteuerindikationen = ({
  steuerLaender,
  laender,
  onChange,
  dispatch,
  errors
}: PimArtikelSteuerindikationenProps) => (
  <RowForm
    title="Steuerindikationen"
    name="steuerindikationen"
    rows={steuerLaender}
    onAdd={() => dispatch({ type: EigenlistungsArtikelUseCase.ADDSTEUERLAND })}
    onRemove={(land) => dispatch({ type: EigenlistungsArtikelUseCase.REMOVESTEUERLAND, land })}
    body={(row, idx) => (
      <PimArtikelSteuerindikation
        land={row}
        laender={laender}
        onChange={onChangeWrapper(onChange, `pimArtikel.version.laender.[__aid=${aidOf(row)}]`)}
        errors={errors}
        errorsPath={`pimArtikel.version.laender.[${idx}]`}
      />
    )}
  />
)

export const PimArtikelSteuerindikationenMemo = React.memo(PimArtikelSteuerindikationen)
