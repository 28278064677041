import { UebernahmeEigenlistungsDialog } from '@one/components/Datenpool/UebernahmeEigenlistung/UebernahmeEigenlistungDialog'
import { ArtikelCell } from '@one/components/common/ArtikelCell'
import { LieferantCell } from '@one/components/common/LieferantCell'
import { formatLieferant, formatMengeneinheit } from '@one/components/common/formatters'
import { ArtikelDatenpoolDisplayJson } from '@one/typings/apiTypings'
import { formatDateTime } from '@utils/dateutils'
import { SearcherResultType } from '@utils/searcher'
import { Button } from '@utils/ui/Buttons/Button'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { useCallback, useMemo, useState } from 'react'

export interface ArtikelDatenPoolUebersichtTableProps {
  result: SearcherResultType<ArtikelDatenpoolDisplayJson>
  reload: VoidFunction
  borderless?: boolean
}

export const ArtikelDatenPoolUebersichtTable = ({
  result,
  reload,
  borderless
}: ArtikelDatenPoolUebersichtTableProps) => {
  const [selected, setSelected] = useState<Set<ArtikelDatenpoolDisplayJson>>(new Set())
  const [DlgAnker, showDlg] = useDialogAnker()

  const columns = useMemo<Column<ArtikelDatenpoolDisplayJson>[]>(
    () => [
      {
        field: 'hageNummer',
        header: 'hage-Nr.'
      },
      {
        field: 'gtin',
        header: 'EAN'
      },
      {
        field: 'lan',
        header: 'IAN'
      },
      {
        field: 'lieferantArtikelBez',
        header: 'Bezeichung'
      },
      {
        field: 'werkslistenPreis',
        header: 'Werkslistenpreis',
        type: 'money2'
      },
      {
        field: 'einkaufMeName',
        header: 'Einkauf-ME (DP)',
        headerTip: 'Einkaufsmengeneinheit Datenpool'
      },
      {
        field: 'seEinkaufMe',
        header: 'Einkauf-ME (ERP)',
        headerTip: 'Einkaufsmengeneinheit ERP',
        valueGetter: (row) => formatMengeneinheit(row.seEinkaufMe)
      },
      {
        field: 'lieferantWarenGruppe',
        header: 'Warengruppe'
      },
      {
        field: 'lieferant',
        header: 'Lieferant',
        body: (row) =>
          row.lieferant ? <LieferantCell lieferant={row.lieferant} asLink /> : row.supplierID,
        valueGetter: (row) => (row.lieferant ? formatLieferant(row.lieferant) : row.supplierID)
      },
      {
        field: 'uebernahmeInfo.artikel',
        header: 'EL Artikel',
        headerTip: 'Artikel als Eigenlistung',
        valueGetter: (row) =>
          `${row.uebernahmeInfo?.artikel?.hageNummer ?? ''} - ${
            row.uebernahmeInfo?.artikel?.bez1 ?? ''
          }`,
        body: (row) => <ArtikelCell artikel={row.uebernahmeInfo?.artikel} asLink />
      },
      {
        field: 'uebernahmeInfo.audit.updatedOn',
        header: 'EL Zeitpunkt',
        headerTip: 'Zeitpunkt letzte Übernahme',
        valueGetter: (row) =>
          row.uebernahmeInfo?.audit?.updatedOn
            ? formatDateTime(row.uebernahmeInfo?.audit?.updatedOn)
            : formatDateTime(row.uebernahmeInfo?.audit?.createdOn)
      }
    ],
    []
  )

  const onCreateEigenlistungClose = useCallback(
    (isSuccess: boolean) => {
      if (isSuccess) {
        reload()
      }
    },
    [reload]
  )

  const showCreateEigenlistung = useCallback(() => {
    const hageNummerArr = Array.from(selected).map((item) => ({
      hageNummer: item.hageNummer,
      supplierID: item.supplierID
    }))

    showDlg((visible, onClose) => (
      <UebernahmeEigenlistungsDialog
        open={visible}
        onClose={onClose(onCreateEigenlistungClose)}
        artikelKeys={hageNummerArr}
      />
    ))
  }, [selected, showDlg, onCreateEigenlistungClose])

  const actions = useMemo(
    () => [
      <Button
        key="eigenlistung"
        label="Eigenlistung anlegen"
        disabled={selected.size === 0}
        onClick={showCreateEigenlistung}
        variant="contained"
        size="small"
      />
    ],
    [showCreateEigenlistung, selected.size]
  )

  return (
    <>
      <DataTableCard
        name="ArtikelDatenPoolUebersichtTable"
        height="100%"
        title="Artikelübersicht"
        columns={columns}
        selectCol
        bottomActions={actions}
        selectMode="multi"
        selected={selected}
        onSelect={setSelected}
        dense
        initialOrderBy="name"
        filterMode="both"
        borderless={borderless}
        hover
        localStateName="ArtikelDatenPoolUebersichtTable"
        rowFiller
        result={result}
      />
      <DlgAnker />
    </>
  )
}
