import { Divider, Grid, Typography } from '@mui/material'
import { formatMengeneinheit } from '@one/components/common/formatters'
import { ArtikelMengeneinheitJson, DzArtikelJson, ZeArtikelJson } from '@one/typings/apiTypings'
import { formatNumber } from '@utils/numberutils'
import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { StaticField } from '@utils/ui/fields/StaticField'
import { useCallback, useMemo } from 'react'

export interface ArtikelMengeneinheitenProps {
  artikel: DzArtikelJson | ZeArtikelJson
  mode?: 'dz' | 'ze'
  loading?: boolean
}

export const ArtikelMengeneinheiten = ({ artikel, mode, loading }: ArtikelMengeneinheitenProps) => {
  const renderLabel = useCallback((mengeneinheit: any) => formatMengeneinheit(mengeneinheit), [])

  const columns = useMemo<Column<ArtikelMengeneinheitJson>[]>(
    () => [
      {
        field: 'mengeneinheit.bez',
        header: 'Mengeneinheit',
        body: (row) => renderLabel(row.mengeneinheit)
      },
      {
        field: 'umrechnungInfo',
        header: 'Umrechnung (Bruch)',
        body: (row) => {
          if (!row.umrechnungInfo) {
            // Nichts anzeigen
            return ''
          }

          let res = row.umrechnungInfo.replace(
            /SeMengeneinheit{.*}/,
            renderLabel(row.mengeneinheit)
          )
          if (row.faktor.nenner === 1) {
            res = res.replace(/(\d)+\/1/, `${row.faktor?.zaehler}`)
          }
          return res.replace(/[\d]+$/, renderLabel(row.basisMengeneinheit))
        }
      },
      {
        field: 'umrechnungInfo',
        header: 'Umrechnung',
        key: 'umrechnungInfoNumber',
        body: (row) => {
          const label = renderLabel(row.basisMengeneinheit) ?? ''
          if (!row.umrechnungInfo) {
            // Nichts anzeigen
            return ''
          }

          if (row.faktor.nenner === 1) {
            return `${row.faktor.zaehler} ${label}`
          }
          return `${formatNumber(
            Math.round((row.faktor.zaehler / row.faktor.nenner + Number.EPSILON) * 1000) / 1000,
            7
          )} ${label}`
        }
      },
      {
        field: 'gewicht',
        header: 'Gewicht'
      },
      { field: 'hoehe', header: 'Höhe' },
      { field: 'laenge', header: 'Länge' }
    ],
    [renderLabel]
  )

  const groupBy = useMemo(
    () => ({
      field: 'basisMengeneinheit.id',
      body: (row: any) => <b>Basismengeneinheit: {renderLabel(row.basisMengeneinheit)}</b>,
      groupEmpty: false
    }),
    [renderLabel]
  )

  const dzArtikel = artikel as DzArtikelJson

  return (
    <CardEx title="Mengeneinheiten">
      <DataTable
        name="MengeneinheitenTable"
        columns={columns}
        groupBy={groupBy}
        value={artikel.mengeneinheiten}
        loading={loading}
        dense
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h5" sx={{ marginTop: '20px' }}>
            Einheiten
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid item xs={12}>
          <Grid container width="100%" spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
              <StaticField
                label="Einkaufsmengeneinheit"
                value={formatMengeneinheit(artikel.einkaufME)}
                fullWidth
              />
            </Grid>
            {mode === 'dz' && (
              <>
                <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                  <StaticField
                    label="Verkaufsmengeneinheit"
                    value={formatMengeneinheit(dzArtikel.verkaufME)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                  <StaticField
                    label="Einzelmengeneinheit (kleinste lieferbare Einheit)"
                    value={formatMengeneinheit(dzArtikel.einzelME)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                  <StaticField
                    label="Lagermengeneinheit"
                    value={formatMengeneinheit(dzArtikel.lagerME)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                  <StaticField
                    label="Einkaufspreismenge"
                    value={formatNumber(dzArtikel.einkaufPreismengeWert, 2)}
                    fullWidth
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </CardEx>
  )
}
