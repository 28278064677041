import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import { formatNeonKontext } from '@one/components/common/formatters'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  HandelTyp,
  StandortJson,
  StandorteBearbeitenJson,
  SynchronisationStatus
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { useModelMgr } from '@utils/modelmgr'
import { AppContext } from '@utils/ui/App/AppContext'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ButtonWithConfirmation } from '@utils/ui/Buttons/ButtonWithConfirmation'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { Permission } from '@utils/ui/Permission'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { nameOf, singleFromSet } from '@utils/utils'
import { useContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { SynchronisationStatusCell } from './StandortgruppeUebersicht/SynchronisationStatusCell'

export const StandortePflegeView = () => {
  const { checkUserRole, isSortimente } = useContext(AppContext)

  const { et } = useEnums()

  const [selection, setSelection] = useState(new Set<StandortJson>())

  const { model, post, onValueChange, save, reload, errors, isChanged, isNew, uiLock } =
    useModelMgr<
      StandorteBearbeitenJson,
      StandorteBearbeitenJson,
      StandorteBearbeitenJson,
      StandortJson[]
    >({
      noid: true,
      api,
      title: 'Standorte',
      rest: 'standort',
      eventName: EventNames.STANDORT,
      saveMutator: (d) => d.standorte
    })

  const columns = useMemo(() => {
    return [
      {
        field: nameOf<StandortJson>('nr'),
        header: 'Nummer',
        type: 'number',
        width: '5em'
      },
      {
        field: nameOf<StandortJson>('name'),
        header: 'Name',
        width: '10em'
      },
      {
        off: !isSortimente,
        field: nameOf<StandortJson>('status'),
        header: 'Synchronisatsstatus',
        width: '10em',
        valueGetter: (row) => et(HkmEnum.SynchronisationStatus, row.status),
        body: (row) => <SynchronisationStatusCell value={row.status} />
      },
      {
        off: !isSortimente,
        field: nameOf<StandortJson>('kontext'),
        header: 'Kontext',
        width: '20em',
        valueGetter: (row) => {
          if (row.kontext == null) {
            return null
          }
          const kontext = model.kontexte?.find((k) => k.kontext === row.kontext)
          return `${kontext.kontextNr} ${kontext.kontextName}`
        },
        body: (row) => (
          <AutocompleteEx
            fullWidth
            emptyText="ohne"
            value={row.kontext}
            options={model.kontexte}
            optionValue="kontext"
            optionLabel={formatNeonKontext}
            name={`standorte.[id=${row.id}].kontext`}
            onChange={onValueChange}
            disabled={
              row.status != SynchronisationStatus.DEAKTIVIERT ||
              row.standortGruppeId != null ||
              row.kontextLock
            }
          />
        )
      },
      {
        off: !isSortimente,
        field: nameOf<StandortJson>('standortGruppeId'),
        header: 'Standortgruppe',
        width: '15em',
        valueGetter: (row) => {
          const sg = model.standortGruppen?.find((s) => s.id === row.standortGruppeId)
          return sg?.name || 'Keine'
        },
        body: (row) => {
          const sg = model.standortGruppen?.find((s) => s.id === row.standortGruppeId)
          return sg ? (
            <Link to={AppPaths.StandortgruppePflegeFn(sg.id)}>{sg.name}</Link>
          ) : (
            <small>Keine</small>
          )
        }
      },
      {
        off: isSortimente,
        field: nameOf<StandortJson>('eanGruppeId'),
        header: 'EAN-Gruppe',
        valueGetter: (row) => {
          const eang = model.eanGruppen?.find((eang) => eang.id === row.eanGruppeId)
          return eang?.name || 'Keine'
        }
      }
    ] as Column<StandortJson>[]
  }, [et, isSortimente, model.eanGruppen, model.kontexte, model.standortGruppen, onValueChange])

  const tableActions = useMemo(
    (): DataTableAction[] =>
      [
        {
          icon: 'category',
          tooltip: 'Sortiment',
          getLink: (row) => AppPaths.SortimentPflegeByStandortFn(row.id),
          check: (row) =>
            row.standortGruppeId == null && isSortimente && row.kontext != null
              ? Permission.ENABLED
              : Permission.HIDDEN
        },
        {
          icon: 'checklist',
          tooltip: 'VK-Preispflege Regeln',
          getLink: (row) => AppPaths.VkPreisgruppenRegelnByStandortFn(row.id),
          check: (row) =>
            row.standortGruppeId == null &&
            isSortimente &&
            row.kontext != null &&
            row.handelTyp === HandelTyp.EH
              ? Permission.ENABLED
              : Permission.HIDDEN
        }
      ] as DataTableAction<StandortJson>[],
    [isSortimente]
  )

  const bottomActions = useMemo(() => {
    const row = singleFromSet(selection)
    return [
      <ButtonWithConfirmation
        key="aktivieren"
        visible={isSortimente && row != null && row?.standortGruppeId == null}
        disabled={row == null || row.status === SynchronisationStatus.AKTIVIERT}
        onAction={() => post({ srv: 'aktivieren', params: { id: row.id } })}
        confirmationMsg="Aktivierung durchführen"
        label="Aktivieren"
        variant="contained"
      />,
      <ButtonWithConfirmation
        key="deaktivieren"
        visible={isSortimente && row != null && row?.standortGruppeId == null}
        disabled={row == null || row.status === SynchronisationStatus.DEAKTIVIERT}
        onAction={() => post({ srv: 'deaktivieren', params: { id: row.id } })}
        confirmationMsg="Deaktivierung durchführen"
        label="Deaktivieren"
        variant="contained"
      />
    ]
  }, [selection, isSortimente, post])

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameBody>
          <DataTableCard
            backLink
            title="Standorte"
            name="StandorteTable"
            value={model.standorte}
            columns={columns}
            filterMode="global"
            localStateName="StandorteTable"
            emptyMessage="Keine Standorte vorhanden"
            selectMode="single"
            actions={tableActions}
            bottomActions={bottomActions}
            dense
            rowFiller
            onSelect={setSelection}
            selected={selection}
          />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <SaveButton onClickVoid={save} isNew={isNew} isChanged={isChanged} />
            <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
