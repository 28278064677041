import { Grid } from '@mui/material'
import { PimArtikelVersionKontextJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { StaticField } from '@utils/ui/fields/StaticField'

export interface ArtikelPimKontextProps {
  model: PimArtikelVersionKontextJson
}

export const ArtikelPimKontext = ({ model }: ArtikelPimKontextProps) => {
  if (!model) {
    return null
  }
  return (
    <CardEx title="Kontext">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Bt-Artikel-Nr." value={model.btArtikelNr} name="btArtikelNr" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Bt-Warengruppe"
            value={model.btWarengruppe}
            name="btWarengruppe"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Artikelbeschreibung"
            value={model.artikelbeschreibung}
            name="artikelbeschreibung"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Fokus" value={model.focus} name="focus" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Versandkostenklasse"
            value={model.versandkostenklasse}
            name="versandkostenklasse"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Transportzeit"
            value={model.transportzeit}
            name="transportzeit"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Retourenfrist Online-Bestellung"
            value={model.retourenfristOnlineBestellung}
            name="retourenfristOnlineBestellung"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Cross-Channel Variante Online"
            value={model.crossChannelVariantenOnlineList?.join(', ')}
            name="crossChannelVarianteOnline"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Abweichende MwSt Endkunde-B2C"
            value={model.abweichendeMwStEndkundeB2C}
            name="abweichendeMwStEndkundeB2C"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Co Auftrag" value={model.coAuftrag} name="coAuftrag" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Online"
            checked={model.online}
            name="online"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Online Bestellbar Endkunde"
            checked={model.onlineBestellbarEndkunde}
            name="onlineBestellbarEndkunde"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Online Reservierfaehig Endkunde"
            checked={model.onlineReservierfaehigEndkunde}
            name="onlineReservierfaehigEndkunde"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Kontext Aktiv"
            checked={model.kontextAktiv}
            name="kontextAktiv"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="OE Norm-Relevanz"
            checked={model.oeNormRelevanz}
            name="oeNormRelevanz"
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
