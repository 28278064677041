import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { api } from '@one/api'
import { RabattgruppeField } from '@one/components/EkPreis/EKPreisPflege/fields/RabattgruppeField'
import {
  EkKonditionenRabattgruppenViewJson,
  EkPreiseMasseBearbeitenEintragJson
} from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { Button } from '@utils/ui/Buttons/Button'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { LabeledField } from '@utils/ui/fields/LabeledField'
import { distinctItems } from '@utils/utils'
import { useCallback } from 'react'
import { EkPreisMassenPflegeModel } from './model/EkPreisMassenPflegeModel'

export interface EkPreisMassenArtikelRabattgruppeDialogProps {
  kopf: EkPreisMassenPflegeModel
  visible?: boolean
  onClose?: (fn?: () => void) => () => void
  selected?: EkPreiseMasseBearbeitenEintragJson[]
  onComplete?: (updated?: any) => void
  allianzModus: boolean
}

export const EkPreisMassenArtikelRabattgruppeDialog = ({
  onClose,
  onComplete,
  kopf,
  visible = true,
  selected = [],
  allianzModus
}: EkPreisMassenArtikelRabattgruppeDialogProps) => {
  const eintragIds = distinctItems(selected, (e) => e.artikel.id).map((e) => e.eintragId)

  const { model, updModel, save } = useModelMgr({
    id: 'neu',
    api,
    title: 'EK-Preis Massenänderung',
    rest: '/ekpreisliste/executeaction',
    restSave: 'artikelrabattgruppe',
    restCreate: null,
    init: () => ({ staffelmenge: 0, rabattgruppeId: null, rabattgruppeKopfId: null }),
    onSave: onClose(onComplete),
    saveMutator: (data) => ({
      listeId: kopf.id,
      preisEbeneId: kopf.preisEbeneId,
      eintraege: eintragIds,
      rabattgruppeId: data.rabattgruppeKopfId
    })
  })

  const onRabattgruppe = useCallback(
    (rabattgruppe: EkKonditionenRabattgruppenViewJson) => {
      updModel((old) => {
        return {
          ...old,
          rabattgruppeId: rabattgruppe?.id,
          rabattgruppeKopfId: rabattgruppe?.kopf?.id
        }
      })
    },
    [updModel]
  )

  return (
    <Dialog open={visible} onClose={onClose()} maxWidth={false}>
      <DialogContent>
        <LabeledField
          // size="small"
          label="Rabattgruppe:"
          labelWidth="7rem"
          fieldWidth="16rem"
          control={
            <RabattgruppeField
              options={kopf.ekRabattgruppen}
              value={model.rabattgruppeId}
              onChange={onRabattgruppe}
              allianzModus={allianzModus}
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <ButtonRow>
          <Button
            onClickVoid={save}
            variant="contained"
            label={`Für ${eintragIds.length} Artikel anwenden`}
          />
          <CancelButton onClick={onClose()} />
        </ButtonRow>
      </DialogActions>
    </Dialog>
  )
}
