import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { api } from '@one/api'
import { formatLieferant } from '@one/components/common/formatters'
import { EigenlistungsArtikelBearbeitenJson, SeArtikelJson } from '@one/typings/apiTypings'
import { onChangeWrapper, useModelMgr } from '@utils/modelmgr'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { DeleteButton } from '@utils/ui/Buttons/DeleteButton'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { CardEx } from '@utils/ui/CardEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { IndexPanel, IndexPanelItem } from '@utils/ui/IndexPanel'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { useContext } from 'react'
import { PimArtikelBezeichungMemo } from './PimArtikelBezeichnung'
import { PimArtikelComplianceMemo } from './PimArtikelCompliance'
import { PimArtikelComplianceLaenderMemo } from './PimArtikelComplianceLaender'
import { PimArtikelDisplayArtikelMemo } from './PimArtikelDisplayArtikel'
import { PimArtikelFlieseMemo } from './PimArtikelFliese'
import { PimArtikelHagebauMemo } from './PimArtikelHagebau'
import { PimArtikelKennzeichenMemo } from './PimArtikelKennzeichen'
import { PimArtikelKopfMemo } from './PimArtikelKopf'
import { PimArtikelLieferantMemo } from './PimArtikelLieferant'
import { PimArtikelLieferantenMemo } from './PimArtikelLieferanten'
import { PimArtikelMasseMemo } from './PimArtikelMasse'
import { PimArtikelMengeneinheitenMemo } from './PimArtikelMengeneinheiten'
import { PimArtikelPackstueckeMemo } from './PimArtikelPackstuecke'
import { PimArtikelPflanzenMemo } from './PimArtikelPflanzen'
import { PimArtikelProduktMemo } from './PimArtikelProdukt'
import { PimArtikelSperrenMemo } from './PimArtikelSperren'
import { PimArtikelSteuerindikationenMemo } from './PimArtikelSteuerindikationen'
import { EigenlistungsArtikelUseCase } from './model/EigenlistungsArtikelUseCase'

export const EigenlistungsArtikelPflegeView = () => {
  const { id } = useContext(RouteContext) as any

  const {
    model,
    isNew,
    isChanged,
    envelope,
    errors,
    dispatch,
    save,
    reload,
    uiLock,
    remove,
    onValueChange
  } = useModelMgr<EigenlistungsArtikelBearbeitenJson, SeArtikelJson>({
    id,
    api,
    title: 'Eigenlistungsartikel',
    rest: 'artikel/eigenlistung',
    unwrapField: 'seArtikel',
    errorStyle: 'structured',
    eventName: EventNames.EIGENLISTUNGSARTIKEL,
    reducer: EigenlistungsArtikelUseCase.reducer,
    validate: EigenlistungsArtikelUseCase.validate
  })
  const content = [
    {
      id: 'kopf',
      label: 'Kopf',
      exclude: model.pimArtikel == null,
      body: () => (
        <PimArtikelKopfMemo
          pimArtikel={model.pimArtikel}
          onChange={onValueChange}
          errors={errors}
        />
      )
    },
    {
      id: 'bezeichner',
      label: 'Bezeichnungen',
      exclude: model.pimArtikel?.version?.sprache == null,
      body: () => (
        <PimArtikelBezeichungMemo
          pimArtikelVersionSprache={model.pimArtikel.version.sprache}
          onChange={onValueChange}
          errors={errors}
        />
      )
    },
    {
      id: 'kennzeichen',
      label: 'Kennzeichen',
      exclude: model.pimArtikel?.version?.sprache == null,
      body: () => (
        <PimArtikelKennzeichenMemo
          pimArtikelVersion={model.pimArtikel.version}
          onChange={onValueChange}
          errors={errors}
        />
      )
    },
    {
      id: 'hagebaulogistik',
      label: 'Hagebau Logistik',
      exclude: model.pimArtikel == null,
      body: () => (
        <PimArtikelHagebauMemo
          pimArtikel={model.pimArtikel}
          onChange={onValueChange}
          errors={errors}
        />
      )
    },
    {
      id: 'masse',
      label: 'Maße',
      exclude: model.pimArtikel == null,
      body: () => (
        <PimArtikelMasseMemo
          pimArtikel={model.pimArtikel}
          onChange={onValueChange}
          errors={errors}
        />
      )
    },
    {
      id: 'sperren',
      label: 'Sperren',
      exclude: model.pimArtikel == null,
      body: () => (
        <PimArtikelSperrenMemo
          pimArtikel={model.pimArtikel}
          onChange={onValueChange}
          errors={errors}
        />
      )
    },
    {
      id: 'produkt',
      label: 'Produkt',
      exclude: model.pimArtikel == null,
      body: () => (
        <PimArtikelProduktMemo
          produkt={model.pimArtikel.version.pimProdukt}
          onChange={onValueChange}
          errors={errors}
        />
      )
    },
    {
      id: 'display',
      label: 'Display',
      exclude: model.pimArtikel == null,
      body: () => (
        <PimArtikelDisplayArtikelMemo
          displayArtikel={model.pimArtikel.version.displayArtikel}
          onChange={onValueChange}
          dispatch={dispatch}
          errors={errors}
        />
      )
    },
    {
      id: 'compliance',
      label: 'Compliance',
      exclude: model.pimArtikel?.version?.compliance == null,
      body: () => (
        <PimArtikelComplianceMemo
          compliance={model.pimArtikel.version.compliance}
          onChange={onValueChange}
          errors={errors}
        />
      ),
      content: [
        {
          id: 'compliance-laender',
          label: 'Länder',
          body: () => (
            <PimArtikelComplianceLaenderMemo
              laender={envelope.laender}
              complianceLaender={model.pimArtikel.version.compliance?.laender}
              onChange={onValueChange}
              dispatch={dispatch}
              errors={errors}
            />
          )
        }
      ]
    },
    {
      id: 'fliese',
      label: 'Fliese',
      exclude: model.pimArtikel?.version == null,
      body: () => (
        <PimArtikelFlieseMemo
          fliese={model.pimArtikel.version.fliese}
          dispatch={dispatch}
          onChange={onValueChange}
          errors={errors}
        />
      )
    },
    {
      id: 'pflanze',
      label: 'Pflanze',
      exclude: model.pimArtikel?.version == null,
      body: () => (
        <PimArtikelPflanzenMemo
          pflanze={model.pimArtikel.version.pflanze}
          dispatch={dispatch}
          onChange={onChangeWrapper(onValueChange, 'pimArtikel.version.pflanze')}
          errors={errors}
        />
      )
    },
    {
      id: 'lieferanten',
      label: 'Lieferanten',
      exclude: model.pimArtikel == null,
      append: true,
      body: () => <PimArtikelLieferantenMemo dispatch={dispatch} />,
      content: model.pimArtikel?.version?.lieferanten?.map(
        (pimArtikelVersionLieferant, idx) =>
          ({
            id: `lieferant-${pimArtikelVersionLieferant.lieferant.id}`,
            label: formatLieferant(pimArtikelVersionLieferant.lieferant),
            body: () => (
              <PimArtikelLieferantMemo
                hageNummer={isNew ? null : model.pimArtikel.hageNummer}
                pimArtikelVersionLieferant={pimArtikelVersionLieferant}
                mengeneinheiten={envelope.mengeneinheiten}
                laender={envelope.laender}
                kontext={model.pimArtikel.versionKontext.kontext}
                onChange={onValueChange}
                dispatch={dispatch}
                errors={errors}
                idx={idx}
              />
            )
          }) as IndexPanelItem
      )
    },
    {
      id: 'mengeneinheiten',
      label: 'Mengeneinheiten',
      exclude: model.pimArtikel == null,
      body: () => (
        <PimArtikelMengeneinheitenMemo
          pimArtikelVersion={model.pimArtikel?.version}
          kontext={model.pimArtikel?.versionKontext?.kontext}
          onChange={onValueChange}
          mengeneinheiten={envelope.mengeneinheiten}
          dispatch={dispatch}
          errors={errors}
        />
      )
    },
    {
      id: 'steuerindikationen',
      label: 'Steuerindikationen',
      exclude: model.pimArtikel == null,
      body: () => (
        <PimArtikelSteuerindikationenMemo
          steuerLaender={model.pimArtikel.version.laender}
          laender={envelope.laender}
          dispatch={dispatch}
          onChange={onValueChange}
          errors={errors}
        />
      )
    },
    {
      id: 'packstuecke',
      label: 'Packstücke',
      exclude: model.pimArtikel == null,
      body: () => (
        <PimArtikelPackstueckeMemo
          packstuecke={model.pimArtikel.version.packstuecke}
          onChange={onValueChange}
          dispatch={dispatch}
          errors={errors}
        />
      )
    }
  ] as IndexPanelItem[]

  return (
    <StatePlane uiLock={uiLock}>
      <Frame space>
        <FrameRow>
          <CardEx
            title={
              isNew
                ? `Neuer Eigenlistungsartikel, Kontext: ${model?.pimArtikel?.versionKontext?.kontext ?? ''}`
                : `Eigenlistungsartikel ${model?.pimArtikel?.hageNummer} ${model?.pimArtikel?.version?.sprache?.lieferschein1 ?? ''}, Kontext: ${model?.pimArtikel?.versionKontext?.kontext ?? ''}`
            }
            backLink
            overviewLink={AppPaths.ArtikelUebersicht}
            contentStyle={{ paddingBottom: 0 }}
          />
        </FrameRow>
        <FrameBody>
          <IndexPanel content={content} />
        </FrameBody>
        <FrameRow>
          <ButtonRow>
            <DeleteButton isNew={isNew} remove={remove} addVersion />
            <SaveButton isNew={isNew} isChanged={isChanged} onClickVoid={save} />
            <ReloadButton isNew={isNew} isChanged={isChanged} onClickVoid={reload} />
          </ButtonRow>
        </FrameRow>
      </Frame>
    </StatePlane>
  )
}
