import { Menu as MenuIcon } from '@mui/icons-material'
import { Menu, MenuItem } from '@mui/material'
import { Button, ButtonProps } from '@utils/ui/Buttons/Button'
import { IconButton } from '@utils/ui/Buttons/IconButton'
import { isFunction, safeArray } from '@utils/utils'
import { ReactElement, ReactNode, useEffect, useState } from 'react'

export type MenuButtonItem = {
  label: string | ReactNode
  onClick?: (e: MouseEvent) => void
  disabled?: boolean
  forceEnabled?: boolean
  id?: string | number
}

export type MenuButtonProps = {
  label?: string
  title?: string
  icon?: ReactElement
  size?: 'small' | 'large'
  disabled?: boolean
  keepOpen?: boolean
  items: MenuButtonItem[] | (() => MenuButtonItem[])
  variant?: ButtonProps['variant']
}

export const MenuButton = ({
  label,
  title,
  icon,
  size,
  disabled,
  items,
  keepOpen,
  variant
}: MenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuItems, setMenuItems] = useState<MenuButtonItem[]>([])

  useEffect(() => {
    // @ts-ignore
    setMenuItems(safeArray(isFunction(items) ? items() : items))
  }, [items])

  const onOpen = (event) => {
    // @ts-ignore
    setMenuItems(safeArray(isFunction(items) ? items() : items))
    setAnchorEl(event.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  const onMenuItem = (item: MenuButtonItem) => (e) => {
    if (!keepOpen) {
      setAnchorEl(null)
    }
    if (item.onClick != null) {
      item.onClick(e)
    }
  }

  const button = label ? (
    <Button
      label={label}
      aria-controls="menubutton"
      aria-haspopup="true"
      size={size}
      onClick={onOpen}
      disabled={disabled}
      title={title}
      variant={variant}
      startIcon={icon}
    />
  ) : (
    <IconButton
      icon={icon || <MenuIcon />}
      aria-controls="menubutton"
      aria-haspopup="true"
      size={size}
      onClick={onOpen}
      disabled={disabled}
      title={title}
    />
  )

  return (
    <>
      {button}
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClose}>
        {menuItems.map((item: MenuButtonItem) => (
          <MenuItem
            key={item.id || `${item.label}`}
            onClick={onMenuItem(item)}
            disabled={item.forceEnabled ? false : item.disabled || item.onClick == null}
            // style={{ padding: '4px 8px' }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
