import { Grid } from '@mui/material'
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view'
import { restartTimer } from '@utils/utils'
import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { CardEx } from './CardEx'
import { HorizSplitPanel } from './HorizSplitPanel'
import { ScrollPanel } from './ScrollPanel'

type IndexItemProps = {
  nodeId: string
  label: string
  onSelect: (id: string) => void
  children?: ReactNode
}
const IndexItem = ({ nodeId, onSelect, label, children }: IndexItemProps) => (
  <TreeItem
    itemId={nodeId}
    label={label}
    style={{ whiteSpace: 'nowrap' }}
    onClick={(e) => {
      e.stopPropagation()
      e.preventDefault()
      onSelect(nodeId)
    }}
  >
    {children}
  </TreeItem>
)

const timerDelay = 100
const scrollOffset = -30

export type IndexPanelItem = {
  id: string
  label: string
  body: () => ReactNode
  append?: boolean
  exclude?: boolean
  content?: IndexPanelItem[]
}

export type IndexPanelProps = {
  content: IndexPanelItem[]
  leftStartWidth?: number
}

export const IndexPanel = ({ content, leftStartWidth }: IndexPanelProps) => {
  const uidSeed = useMemo(() => uuidv4(), [])
  const [currentId, setCurrentId] = useState('')

  const scrollRef = useRef<HTMLDivElement>()
  const itemsRef = useRef<HTMLDivElement>()
  const selectorRef = useRef()

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    // @ts-ignore
    const container = scrollRef.current.container
    let current = null
    const mainYOffset = container.getBoundingClientRect().top
    const parentElement = itemsRef.current
    for (let i = 0; i < parentElement.children.length; i++) {
      const childElement = parentElement.children[i] as HTMLElement
      const sectionTop = childElement.getBoundingClientRect().top
      if (mainYOffset >= sectionTop + scrollOffset) {
        current = childElement.getAttribute('id')
        if (childElement.children.length > 1) {
          for (let j = 1; j < childElement.children.length; j++) {
            let subChildItem = childElement.children[j] as HTMLElement;
            let subChildYOffset = subChildItem.getBoundingClientRect().top;
            if (mainYOffset >= subChildYOffset + scrollOffset ) {
                current = subChildItem.getAttribute('id')
            }
          }
        }
      }
    }
    if (current == null) {
      return;
    }
    restartTimer(selectorRef, () => setCurrentId(current), timerDelay)
  }

  const onSelect = (id: string) => {
    setCurrentId(id)
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const renderItems = (content: IndexPanelItem[], uidParent: string) => {
    return content
      ?.filter((item) => !item.exclude)
      .map((item) => {
        const nodeId = uidParent + '-' + item.id
        return (
          <IndexItem key={item.id} nodeId={nodeId} label={item.label} onSelect={onSelect}>
            {renderItems(item.content, nodeId)}
          </IndexItem>
        )
      })
  }

  const renderContent = (content: IndexPanelItem[], uidParent: string) => {
    return content
      ?.filter((item) => !item.exclude)
      .map((item) => {
        const uid = uidParent + '-' + item.id
        return (
          <Grid
            item
            direction="column"
            key={item.id}
            id={uid}
            is-indexitem="true"
            // spacing={4}
            paddingBottom={2}
            paddingTop={2}
            flexWrap="nowrap"
            xs={12}
          >
            {item.body && !item.append && item.body()}
            {renderContent(item.content, uid)}
            {item.body && item.append && item.body()}
          </Grid>
        )
      })
  }

  const selectIds = useCallback(
    (content: IndexPanelItem[], uidParent: string) =>
      content
        ?.filter((item) => !item.exclude)
        .map((item) => [
          uidParent + '-' + item.id,
          ...selectIds(item.content, uidParent + '-' + item.id)
        ]) ?? [],

    []
  )

  const expandedIds = useMemo(
    () => selectIds(content, uidSeed).flat(),
    [content, selectIds, uidSeed]
  )

  return (
    <HorizSplitPanel
      fullHeight
      leftStartWidth={leftStartWidth ?? 280}
      grow="right"
      left={
        <div style={{ paddingRight: 10, height: '100%' }}>
          <CardEx title="Inhalt" height="100%">
            <SimpleTreeView
              selectedItems={currentId && [currentId]}
              onItemFocus={(_e, nodeId: any) => {
                onSelect(nodeId)
              }}
              expandedItems={expandedIds}
            >
              {renderItems(content, uidSeed)}
            </SimpleTreeView>
          </CardEx>
        </div>
      }
      right={
        <ScrollPanel height="100%" onScroll={onScroll} scrollRef={scrollRef}>
          <Grid container spacing={1} paddingLeft={1} ref={itemsRef}>
            {renderContent(content, uidSeed)}
            <Grid item xs={12} height="100vh" />
          </Grid>
        </ScrollPanel>
      }
    />
  )
}
