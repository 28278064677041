import { LocalOffer } from '@mui/icons-material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { IconButton } from '@utils/ui/Buttons/IconButton'
import { TooltipEx } from '@utils/ui/TooltipWrapper'
import { AutocompleteEx } from '@utils/ui/fields/AutocompleteEx'

export interface StandortFieldProps {
  options?: any[]
  value?: any
  onChange?: (value: any) => void
  readonly?: boolean
  label?: string
  name?: string
  emptyText?: string
  fullWidth?: boolean
  withButtons?: boolean
  required?: boolean
  error?: string | boolean
}

export const StandortField = ({
  label,
  value,
  name,
  onChange,
  options,
  emptyText,
  withButtons,
  fullWidth = false,
  readonly = false,
  required = false,
  error
}: StandortFieldProps) => {
  const listeSafe = options || []

  let pos = value == null ? null : listeSafe.findIndex((s) => s.id === value)
  pos = pos < 0 ? null : pos

  const handleChange = (e) => {
    if (onChange) {
      onChange({ name, value: e.target.value })
    }
  }

  const handleChevron = (inc) => {
    if (onChange) {
      const npos = pos == null ? 0 : pos + inc
      if (npos >= 0 && npos < listeSafe.length) {
        const next = listeSafe[npos]
        onChange({ name, value: next.id })
      } else if (npos === -1 && !required) {
        onChange({ name, value: null })
      }
    }
  }

  const startAdornments = withButtons
    ? [
        <IconButton
          key="prev"
          icon={<ChevronLeftIcon />}
          size="small"
          disabled={(pos == null && value == null) || readonly}
          onClick={() => handleChevron(-1)}
        />,
        <IconButton
          key="next"
          icon={<ChevronRightIcon />}
          size="small"
          disabled={pos + 1 >= listeSafe.length || readonly}
          onClick={() => handleChevron(1)}
        />
      ]
    : null

  const renderItem = (opt) => (
    <div
      style={{ display: 'flex', width: '100%', fontWeight: opt.hatPreis ? 'bold' : null }}
      title={opt.hatPreis ? 'Preis vorhanden' : null}
    >
      <div
        style={{
          width: '4em',
          textAlign: 'right',
          paddingRight: 4
        }}
      >{`${opt.nr}:`}</div>
      <div style={{ flexGrow: 1 }}>{opt.alias || opt.name}</div>
      {opt.hatPreis ? (
        <TooltipEx title="Standort hat Preise">
          <LocalOffer fontSize="small" color="info" />
        </TooltipEx>
      ) : null}
    </div>
  )

  return (
    <AutocompleteEx
      label={label}
      value={value}
      fullWidth={fullWidth}
      options={listeSafe}
      onChange={handleChange}
      required={required}
      disabled={readonly}
      // startAdornments={startAdornments}
      optionValue="id"
      optionLabel={(opt) => `${opt.nr}: ${opt.alias || opt.name}`}
      filterItem={(opt) => !opt.ausgeblendet}
      renderItem={renderItem}
      emptyText={emptyText}
      error={error}
      withButtons
    />
  )
}
