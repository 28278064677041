import {
  PimArtikelVersionComplianceJson,
  PimArtikelVersionComplianceLandJson
} from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { ScrollPanel } from '@utils/ui/ScrollPanel'

export interface ArtikelPimComplianceLaenderProps {
  compliance: PimArtikelVersionComplianceJson
}

export const ArtikelPimComplianceLaender = ({ compliance }: ArtikelPimComplianceLaenderProps) => {
  const columnLaender: Column<PimArtikelVersionComplianceLandJson>[] = [
    {
      field: 'pimLand.name',
      header: 'Landname'
    },
    {
      field: 'pimLand.bez',
      header: 'Bezeichnung'
    },
    {
      field: 'pimLand.kurzBez',
      header: 'Kurzbezeichnung'
    },
    {
      field: 'pimLand.beschreibung',
      header: 'Beschreibung'
    },
    {
      field: 'getraenkeMehrwegPfand',
      header: 'Getränke Mehrweg Pfand'
    },
    {
      field: 'getraenkeEinwegPfand',
      header: 'Getränke Einweg Pfand'
    },
    {
      field: 'relevantKaminoefenOeNorm',
      header: 'Kaminöfen OE Norm',
      type: 'boolean'
    }
  ]

  return (
    <CardEx title="Compliance-Länder">
      <ScrollPanel autoHeight autoHeightMax="600px">
        <DataTable
          name="PimArtikelVersioncomplianceLaenderTabelle"
          columns={columnLaender}
          value={compliance.laender}
          emptyMessage="Keine Daten"
          dense
        />
      </ScrollPanel>
    </CardEx>
  )
}
