import { Grid } from '@mui/material'
import { PimMengeneinheitField } from '@one/components/common/PimMengeneinheitField'
import {
  NeonKontext,
  PimArtikelVersionJson,
  PimMengeneinheitDisplayJson,
  SeArtikelJson
} from '@one/typings/apiTypings'
import { ErrorsType, ModelAction, ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { NumberField } from '@utils/ui/fields/NumberField'
import { RowForm } from '@utils/ui/fields/RowForm'
import React from 'react'
import { EigenlistungsArtikelUseCase } from './model/EigenlistungsArtikelUseCase'
import { PimArtikelMengeneinheitMemo } from './PimArtikelMengeneinheit'
import { resolveObjectField } from '@utils/utils'

export interface PimArtikelMengeneinheitenProps {
  pimArtikelVersion: PimArtikelVersionJson
  onChange: ValueChangeFn<SeArtikelJson>
  mengeneinheiten: PimMengeneinheitDisplayJson[]
  kontext: NeonKontext
  dispatch: (action: ModelAction) => void
  errors: ErrorsType
}

export const PimArtikelMengeneinheiten = ({
  pimArtikelVersion,
  onChange,
  mengeneinheiten,
  kontext,
  dispatch,
  errors
}: PimArtikelMengeneinheitenProps) => {
  const onAddMengeneinheit = () => {
    dispatch({ type: EigenlistungsArtikelUseCase.ADDMENGENEINHEIT })
  }

  return (
    <CardEx title="Mengeneinheiten und EANs" contentStyle={{ padding: 0, paddingBottom: 0 }}>
      <Grid container spacing={2} margin={0} marginBottom={4}>
        <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
          <PimMengeneinheitField
            label="Verkaufsverpackungmengeneinheit"
            value={pimArtikelVersion.verkaufsverpackungMengeneinheit}
            name="pimArtikel.version.verkaufsverpackungMengeneinheit"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.verkaufsverpackungMengeneinheit')}
            values={mengeneinheiten}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
          <PimMengeneinheitField
            label="Einzelmengeneinheit"
            value={pimArtikelVersion.einzelMengeneinheit}
            name="pimArtikel.version.einzelMengeneinheit"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.einzelMengeneinheit')}
            values={mengeneinheiten}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2} xl={1}>
          <NumberField
            label="Grundpreismenge"
            value={pimArtikelVersion.grundpreismenge}
            name="pimArtikel.version.grundpreismenge"
            fraction={0}
            thousandSeparator
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.grundpreismenge')}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} padding={1}>
        <RowForm
          rows={pimArtikelVersion.mengeneinheiten}
          onAdd={onAddMengeneinheit}
          addLabel="Mengeneinheit hinzufügen"
          borderlessItems
          body={(me, idx) => (
            <PimArtikelMengeneinheitMemo
              kontext={kontext}
              hageNummer={pimArtikelVersion.hageNummer}
              pimArtikelVersionMengeneinheit={me}
              mengeneinheiten={mengeneinheiten}
              onChange={onChange}
              dispatch={dispatch}
              errors={errors}
              idx={idx}
            />
          )}
        />
      </Grid>
    </CardEx>
  )
}

export const PimArtikelMengeneinheitenMemo = React.memo(PimArtikelMengeneinheiten)
