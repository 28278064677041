import { Grid } from '@mui/material'
import { PimArtikelVersionPflanzeJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { StaticField } from '@utils/ui/fields/StaticField'

export interface ArtikelPimPflanzenProps {
  pflanze: PimArtikelVersionPflanzeJson
}

export const ArtikelPimPflanzen = ({ pflanze }: ArtikelPimPflanzenProps) => {
  return (
    <CardEx title="Pflanze" name="Pflanze">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField label="Nummer" value={pflanze.nummer} name="nummer" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Pflanzen pro Tray"
            value={pflanze.pflanzenProTray}
            name="pflanzenProTray"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Trays pro Lage"
            value={pflanze.pflanzenTraysProLage}
            name="pflanzenTraysProLage"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StaticField
            label="Lagen pro CC"
            value={pflanze.pflanzenLagenProCc}
            name="pflanzenLagenProCc"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Nummer" value={pflanze.nummer} name="nummer" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Blütenfarbe" value={pflanze.bluetenfarbe} name="bluetenfarbe" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Blütenmerkmal"
            value={pflanze.bluetenmerkmal}
            name="bluetenmerkmal"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Blattfarbe" value={pflanze.blattfarbe} name="blattfarbe" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Ausführung" value={pflanze.ausfuehrung} name="ausfuehrung" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Art"
            value={pflanze.botanischeBezeichnungArt}
            name="botanischeBezeichnungArt"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Sorte"
            value={pflanze.botanischeBezeichnungSorte}
            name="botanischeBezeichnungSorte"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Gattung"
            value={pflanze.botanischeBezeichnungGattung}
            name="botanischeBezeichnungGattung"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Höhe min" value={pflanze.hoeheMin} name="hoeheMin" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Höhe max" value={pflanze.hoeheMax} name="hoeheMax" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Bezeichnung"
            value={pflanze.deutscheBezeichnung}
            name="deutscheBezeichnung"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Lebenszyklus"
            value={pflanze.lebenszyklus}
            name="lebenszyklus"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Qualität" value={pflanze.qualitaet} name="qualitaet" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Wuchsform" value={pflanze.wuchsform} name="wuchsform" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Standort" value={pflanze.standort} name="standort" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Topfgrösse" value={pflanze.topfgroesse} name="topfgroesse" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Blüte"
            checked={pflanze.bluete}
            name="bluete"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Duft"
            checked={pflanze.duft}
            name="duft"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Platzierung hängend"
            checked={pflanze.platzierungHaengend}
            name="platzierungHaengend"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Platzierung stellbar"
            checked={pflanze.platzierungStellbar}
            name="platzierungStellbar"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Winterhart"
            checked={pflanze.winterhart}
            name="winterhart"
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
