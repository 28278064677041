import { useArtikelLabelEdit } from '@one/components/Label/useArtikelLabelEdit'
import { ArtikelDisplayTinyJson, LabelJson } from '@one/typings/apiTypings'
import { Action } from '@utils/ui/Action'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
export interface ArtikelLabelTableViewProps {
  artikel: ArtikelDisplayTinyJson
  labels: LabelJson[]
  loading?: boolean
  reload: () => void
}
export const ArtikelLabelTableView = ({
  labels,
  artikel,
  reload,
  loading = false
}: ArtikelLabelTableViewProps) => {
  const { openLabelsEdit, removeArtikelLabels } = useArtikelLabelEdit()

  const columns: Column<LabelJson>[] = [
    {
      field: 'system',
      header: 'Systemlabel',
      sortable: true
    },
    {
      field: 'kurz',
      header: 'Kurzbezeichnung',
      sortable: true
    },
    {
      width: '100%',
      field: 'bezeichnung',
      header: 'Bezeichnung',
      sortable: true
    }
  ]

  const onAddLabel = () => {
    openLabelsEdit({ artikel: [artikel], callback: { then: () => reload() } })
  }

  const tableAction: Action[] = [
    {
      icon: 'add',
      onClick: onAddLabel
    }
  ]

  const actions = [
    {
      icon: 'delete',
      handler: (label: LabelJson) => () => {
        removeArtikelLabels([artikel.id], [label.id], {
          then: () => reload()
        })
      }
    }
  ]

  return (
    <DataTableCard
      name="artikelLabelTable"
      value={labels}
      title="Artikel Label"
      columns={columns}
      topActions={tableAction}
      loading={loading}
      noScrollPanel
      actions={actions}
      emptyMessage="Keine Label vorhanden"
      dense
    />
  )
}
