import { Grid } from '@mui/material'
import { PimArtikelVersionJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { NumberField } from '@utils/ui/fields/NumberField'
import { StringArrayField } from '@utils/ui/fields/StringArrayField'
import { TextField } from '@utils/ui/fields/TextField'
import React from 'react'
import { resolveObjectField } from '@utils/utils'

export interface PimArtikelKennzeichenProps {
  pimArtikelVersion: PimArtikelVersionJson
  onChange: ValueChangeFn<SeArtikelJson>
  errors: any
}

export const PimArtikelKennzeichen = ({
  pimArtikelVersion,
  onChange,
  errors
}: PimArtikelKennzeichenProps) => {
  return (
    <CardEx title="Bezeichnungen">
      <Grid container spacing={2}>
        <Grid item sm={6} md={3} lg={2}>
          <TextField
            label="Basis-Warengruppe"
            value={pimArtikelVersion.basisWarengruppe}
            name="pimArtikel.version.basisWarengruppe"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.basisWarengruppe')}
            fullWidth
          />
        </Grid>
        <Grid item sm={6} md={3} lg={2}>
          <TextField
            label="BDB-Warengruppe"
            value={pimArtikelVersion.bdbWarengruppe}
            name="pimArtikel.version.bdbWarengruppe"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.bdbWarengruppe')}
            fullWidth
          />
        </Grid>
        <Grid item sm={6} md={3} lg={2}>
          <TextField
            label="Farbgruppe"
            value={pimArtikelVersion.farbgruppe}
            name="pimArtikel.version.farbgruppe"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.farbgruppe')}
            fullWidth
          />
        </Grid>

        <Grid item sm={6} md={3} lg={2}>
          <NumberField
            label="Anzahl Packstuecke"
            value={pimArtikelVersion.anzahlPackstuecke}
            name="pimArtikel.version.anzahlPackstuecke"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.anzahlPackstuecke')}
            fraction={0}
            fullWidth
          />
        </Grid>
        <Grid item sm={6} md={3} lg={2}>
          <NumberField
            label="Grundpreismenge"
            value={pimArtikelVersion.grundpreismenge}
            name="pimArtikel.version.grundpreismenge"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.grundpreismenge')}
            fraction={0}
            fullWidth
          />
        </Grid>

        <Grid item sm={6} md={3} lg={2}>
          <TextField
            label="Intrastat-Mengeneinheit"
            value={pimArtikelVersion.intrastatMengeneinheit}
            name="pimArtikel.version.intrastatMengeneinheit"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.intrastatMengeneinheit')}
            fullWidth
          />
        </Grid>

        <Grid item sm={6} md={3} lg={2}>
          <TextField
            label="Intrastat-Region"
            value={pimArtikelVersion.intrastatRegion}
            name="pimArtikel.version.intrastatRegion"
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.intrastatRegion')}
            fullWidth
          />
        </Grid>

        <Grid item sm={6} md={3} lg={2}>
          <StringArrayField
            label="Regalplatzierungen"
            value={pimArtikelVersion.regalplatzierungen}
            name="pimArtikel.version.regalplatzierungen"
            onChange={onChange}
            // error={resolveObjectField(errors, 'pimArtikel.version.regalplatzierungen')}
            fullWidth
          />
        </Grid>

        <Grid item sm={6} md={3} lg={2}>
          <Checkbox
            label="Arena-Kennzeichen"
            checked={pimArtikelVersion.arenaKennzeichen}
            name="pimArtikel.version.arenaKennzeichen"
            onChange={onChange}
          />
        </Grid>
        <Grid item sm={6} md={3} lg={2}>
          <Checkbox
            label="Grundpreisauszeichnungspflicht"
            checked={pimArtikelVersion.grundpreisauszeichnungspflicht}
            name="pimArtikel.version.grundpreisauszeichnungspflicht"
            onChange={onChange}
          />
        </Grid>
        <Grid item sm={6} md={3} lg={2}>
          <Checkbox
            label="Ohne Verkaufspreis"
            checked={pimArtikelVersion.ohneVerkaufspreis}
            name="pimArtikel.version.ohneVerkaufspreis"
            onChange={onChange}
          />
        </Grid>
        <Grid item sm={6} md={3} lg={2}>
          <Checkbox
            label="Preisbindung"
            checked={pimArtikelVersion.preisbindung}
            name="pimArtikel.version.preisbindung"
            onChange={onChange}
          />
        </Grid>
        <Grid item sm={6} md={3} lg={2}>
          <Checkbox
            label="Rabattfaehig"
            checked={pimArtikelVersion.rabattfaehig}
            name="pimArtikel.version.rabattfaehig"
            onChange={onChange}
          />
        </Grid>
        <Grid item sm={6} md={3} lg={2}>
          <Checkbox
            label="RundungschemaRelevanz"
            checked={pimArtikelVersion.rundungschemaRelevanz}
            name="pimArtikel.version.rundungschemaRelevanz"
            onChange={onChange}
          />
        </Grid>
        <Grid item sm={6} md={3} lg={2}>
          <Checkbox
            label="Skontofaehig"
            checked={pimArtikelVersion.skontofaehig}
            name="pimArtikel.version.skontofaehig"
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelKennzeichenMemo = React.memo(PimArtikelKennzeichen)
