/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import { Alert, Typography } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { MessageID } from '@one/MessageIDs'
import { api } from '@one/api'
import { EkPreisListeFreigabeDialog } from '@one/components/EkPreis/EkPreisListeFreigabeDialog'
import { EkKonditionenJson, EkPreiseBearbeitenJson } from '@one/typings/apiTypings'
import { formatDateTime } from '@utils/dateutils'
import { useModelMgr } from '@utils/modelmgr'
import { notifyObservers, useObserver } from '@utils/observer'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { AppRouteCtx } from '@utils/ui/App/AppRouteCtx'
import { AppSwitchCtx } from '@utils/ui/App/AppSwitchCtx'
import { Button } from '@utils/ui/Buttons/Button'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { DeleteButton } from '@utils/ui/Buttons/DeleteButton'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { SaveButton } from '@utils/ui/Buttons/SaveButton'
import { UndoButton } from '@utils/ui/Buttons/UndoButton'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { asNumber } from '@utils/utils'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EkPreisPflegeBody } from './EkPreisPflegeBody'
import { EkPreisPflegeKopfMemo } from './EkPreispflegeKopf'
import { useEkPreisArtikelListe } from './model/EkPreisArtikelListe'
import { EkPreiseBearbeitenEdit } from './model/EkPreiseTypes'
import {
  EkPreiseUsecase,
  prepareEkPreisModelEdit,
  prepareEkPreisModelSave,
  prepareEkPreisModelSaveEvent,
  pruefeAbschliessbar,
  validateEkPreisModel
} from './model/EkPreiseUsecase'

const getEmptyModel = () => ({
  artikel: {},
  neueKondi: { konditionsEbenen: [] },
  actionFreigeben: {},
  kondiDefinition: {}
})

export const EkPreispflegeView = () => {
  const { id: _id, eintragId } = useContext(RouteContext) as any
  const id = asNumber(_id)

  const navigate = useNavigate()
  const { replaceHistory, pushHistory } = useContext(AppSwitchCtx)
  const { visible } = useContext(AppRouteCtx)
  const [DlgAnker, showDialog] = useDialogAnker()

  const observerId = useObserver(
    EventNames.EKPREISLISTE,
    (event) => {
      const preisListId = event.data?.id
      if (preisListId && id === preisListId) {
        reloadEx({ notifyReload: true })
      }
    },
    visible
  )

  const {
    model,
    errors,
    isNew,
    isChanged,
    save,
    reload,
    reloadEx,
    updModel,
    uiLock,
    remove,
    dispatch,
    undo,
    canUndo
  } = useModelMgr<
    EkPreiseBearbeitenJson,
    EkPreiseBearbeitenEdit,
    EkPreiseBearbeitenJson,
    EkKonditionenJson
  >({
    id,
    api,
    title: 'EK-Preis',
    rest: 'ekpreisliste',
    restps: { eintragId },
    init: getEmptyModel,
    reducer: EkPreiseUsecase.reducer,
    observerId,
    eventName: EventNames.EKPREISLISTE,
    autoValidate: true,
    retryMessageId: MessageID.IDE_ARTIKEL_PREISE_MUESSEN_NOCH_GEHOLT_WERDEN,
    validate: validateEkPreisModel,
    editMutator: prepareEkPreisModelEdit,
    saveMutator: prepareEkPreisModelSave,
    eventMutator: prepareEkPreisModelSaveEvent
  })

  const actionFreigeben = model.actionFreigeben

  const [artikelList, artikelCount, artikelPos, reloadEkListe, artikelListLoading] =
    useEkPreisArtikelListe(id, model.eintragId)

  const onSwitchArtikel = useCallback(
    (eid) => {
      if (!isChanged) {
        replaceHistory(() => AppPaths.EkPreisPflegeFn(id, eid))
      }
    },
    [isChanged, id, replaceHistory]
  )

  const onFreigeben = useCallback(() => {
    showDialog((open, onClose) => (
      <EkPreisListeFreigabeDialog
        open={open}
        onClose={onClose}
        id={model.id}
        navigate={navigate}
        onSuccess={() => {
          notifyObservers({
            name: EventNames.EKPREISLISTE,
            origin: observerId,
            data: model.listeDisplay
          })
          reload()
        }}
      />
    ))
  }, [showDialog, model.id, model.listeDisplay, navigate, observerId, reload])

  const gotoNextArtikel = useCallback(() => {
    if (artikelCount > 1) {
      const np = artikelPos < artikelCount ? artikelPos + 1 : 1
      const artId = artikelList[np - 1].id
      replaceHistory(() => AppPaths.EkPreisPflegeFn(id, artId))
    }
  }, [artikelCount, artikelList, artikelPos, id, replaceHistory])

  const saveAndNext = useCallback(() => {
    save({ then: gotoNextArtikel })
  }, [gotoNextArtikel, save])

  const [saveTrigger, setSaveTrigger] = useState(0)
  useEffect(() => {
    if (saveTrigger) {
      setSaveTrigger(0)
      if (isChanged) {
        save({ then: gotoNextArtikel })
      } else if (saveTrigger === 2) {
        gotoNextArtikel()
      }
    }
  }, [gotoNextArtikel, isChanged, save, saveTrigger])

  const next = useCallback(() => {
    if (!model.neueKondi.abgeschlossen && pruefeAbschliessbar(model.neueKondi)) {
      updModel({ neueKondi: { ...model.neueKondi, abgeschlossen: true } })
      setSaveTrigger(2)
    } else if (isChanged) {
      saveAndNext()
    } else {
      gotoNextArtikel()
    }
  }, [gotoNextArtikel, isChanged, model.neueKondi, saveAndNext, updModel])

  const abschliessbar = useMemo(
    () => !model?.neueKondi?.abgeschlossen && pruefeAbschliessbar(model?.neueKondi),
    [model?.neueKondi]
  )

  const nextText = useMemo(() => {
    if (isChanged) {
      if (abschliessbar) {
        if (artikelCount > 1) {
          return 'Speichern, abschließen & weiter'
        }
        return 'Speichern & abschließen'
      }
      if (artikelCount > 1) {
        return 'Speichern & weiter'
      }
      return 'Speichern'
    }

    if (abschliessbar) {
      if (artikelCount > 1) {
        return 'Abschließen & weiter'
      }
      return 'Abschließen'
    }
    return 'Weiter'
  }, [abschliessbar, artikelCount, isChanged])

  const onDeleteClick = useCallback(
    () =>
      remove({
        onRemoved: () => {
          pushHistory(AppPaths.EkPreisUebersicht)
        },
        ovrId: id
      }),
    [pushHistory, remove, id]
  )

  const onDeleteArtikel = useCallback(() => {
    if (artikelCount === 1) {
      onDeleteClick()
    } else {
      remove({
        onRemoved: () => {
          reloadEkListe()
          gotoNextArtikel()
        },
        srv: 'deleteEintrag',
        params: { eintragId: model.eintragId }
      })
    }
  }, [remove, artikelCount, onDeleteClick, gotoNextArtikel, reloadEkListe, model])

  const actions = model.freigegeben ? (
    <>
      <Typography paddingRight={1}>{`Die Preisliste wurde am ${formatDateTime(
        model.freigegebenUm
      )} freigegeben`}</Typography>
      <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
    </>
  ) : (
    <>
      <UndoButton onClick={undo} canUndo={canUndo} size="small" />
      <DeleteButton
        variant="text"
        label="Preisliste löschen"
        onDelete={onDeleteClick}
        isNew={isNew}
        deleteMsg="Gesamte Preisliste wirklich löschen"
        tooltip="Gesamte Preisliste löschen"
      />
      {!isChanged && !isNew && actionFreigeben.visible && (
        <Button
          label="Preisliste freigeben"
          onClick={onFreigeben}
          variant="text"
          color="primary"
          disabled={actionFreigeben.enabled === false}
          tooltip={actionFreigeben.info}
        />
      )}
      <div style={{ width: 64 }} />
      <DeleteButton
        key="artikel-entfernen"
        onDelete={onDeleteArtikel}
        tooltip="Artikel wird aus der Liste entfernt"
        deleteMsg={`Möchten Sie den Artikel wirklich aus dieser EK-Preisliste entfernen?`}
        disabled={!!model.neueKondi.abgeschlossen || !!model.freigegeben}
      />
      <SaveButton onClickVoid={save} isNew={isNew} isChanged={isChanged} />
      {(artikelCount > 1 || abschliessbar) && (
        <Button label={nextText} onClick={next} variant="contained" color="primary" />
      )}
      <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
    </>
  )

  const shortcuts = {
    'alt+w': next,
    'alt+s': () => save()
  }

  return (
    <ShortCutHandler shortcuts={shortcuts}>
      <StatePlane uiLock={uiLock} altLink={AppPaths.EkPreisUebersicht}>
        <Frame space>
          <FrameRow>
            <EkPreisPflegeKopfMemo
              id={id}
              dispatch={dispatch}
              isChanged={isChanged}
              lieferant={model.lieferant}
              preislistenName={model.name}
              artikel={model.artikel}
              artikelList={artikelList}
              artikelListLoading={artikelListLoading}
              artikelPos={artikelPos}
              onSwitchArtikel={onSwitchArtikel}
              ekRabattgruppen={model.ekRabattgruppen}
              artikelRabattgruppeId={model.neueKondi.artikelRabattgruppeId}
              allianzModus={model.allianzModus}
              abgeschlossen={model.neueKondi?.abgeschlossen}
              kontext={model.kontext}
              betriebstyp={model.betriebstyp}
            />
          </FrameRow>
          <FrameBody>
            {model.invalid ? (
              <Alert severity="error">
                <Typography>
                  Diese Preisliste ist defekt
                  <br />
                  Bitte löschen!
                </Typography>
              </Alert>
            ) : (
              <EkPreisPflegeBody dispatch={dispatch} model={model} errors={errors} />
            )}
          </FrameBody>
          <FrameRow>
            <ButtonRow>{actions}</ButtonRow>
          </FrameRow>
        </Frame>
      </StatePlane>
      <DlgAnker />
    </ShortCutHandler>
  )
}
