import { Grid } from '@mui/material'
import { HkmEnum } from '@one/enums/HkmEnum'
import { PimArtikelJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ErrorsType, ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { SelectEnumField } from '@utils/ui/fields/SelectEnumField'
import { StaticField } from '@utils/ui/fields/StaticField'
import { TextField } from '@utils/ui/fields/TextField'
import React from 'react'
import { resolveObjectField } from '@utils/utils'

export interface PimArtikelKopfProps {
  pimArtikel: PimArtikelJson
  errors: ErrorsType
  onChange: ValueChangeFn<SeArtikelJson>
}

export const PimArtikelKopf = ({ pimArtikel, onChange, errors }: PimArtikelKopfProps) => {
  return (
    <CardEx title="Kopf">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <StaticField label="hage-Nr." value={pimArtikel.hageNummer} name="hageNummer" />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2}>
          <SelectEnumField
            label="Artikelart"
            value={pimArtikel.version.artikelArt}
            name="pimArtikel.version.artikelArt"
            onChange={onChange}
            enumType={HkmEnum.PimArtikelArt}
            error={resolveObjectField(errors, 'pimArtikel.version.artikelArt')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <StaticField
            label="gtin"
            name="pimArtikel.version.gtin"
            value={pimArtikel.version.gtin}
            error={resolveObjectField(errors, 'pimArtikel.version.gtin')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <TextField
            label="Vorläufer"
            name="pimArtikel.version.vorlaeufer"
            value={pimArtikel.version.vorlaeufer}
            error={resolveObjectField(errors, 'pimArtikel.version.vorlaeufer')}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <TextField
            label="Nachfolger"
            name="pimArtikel.version.nachfolger"
            value={pimArtikel.version.nachfolger}
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.nachfolger')}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2}>
          <TextField
            label="Produkttyp"
            name="pimArtikel.version.produkttyp"
            value={pimArtikel.version.produkttyp}
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.produkttyp')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <TextField
            label="Materialgruppe"
            name="pimArtikel.version.materialgruppe"
            value={pimArtikel.version.materialgruppe}
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.materialgruppe')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <TextField
            label="Ursprungsland"
            name="pimArtikel.version.ursprungsland"
            value={pimArtikel.version.ursprungsland}
            onChange={onChange}
            error={resolveObjectField(errors, 'pimArtikel.version.ursprungsland')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Checkbox
            label="Baustein"
            name="pimArtikel.version.bausteinKennzeichen"
            checked={pimArtikel.version.bausteinKennzeichen}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Checkbox
            label="Eigenmarke"
            name="pimArtikel.version.eigenmarke"
            checked={pimArtikel.version.eigenmarke}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Checkbox
            label="GFC-Artikel"
            name="pimArtikel.version.gfcArtikel"
            checked={pimArtikel.version.gfcArtikel}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Checkbox
            label="Chargenpflicht"
            name="pimArtikel.version.chargenpflicht"
            checked={pimArtikel.version.chargenpflicht}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelKopfMemo = React.memo(PimArtikelKopf)
