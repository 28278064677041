import { Grid } from '@mui/material'
import { PimArtikelVersionDisplayJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ModelAction, onChangeWrapper, ValueChangeFn } from '@utils/modelmgr'
import { NumberField } from '@utils/ui/fields/NumberField'
import { RowForm } from '@utils/ui/fields/RowForm'
import { TextField } from '@utils/ui/fields/TextField'
import { aidOf } from '@utils/utils'
import React, { SyntheticEvent } from 'react'
import { EigenlistungsArtikelUseCase } from './model/EigenlistungsArtikelUseCase'

export interface PimArtikelDisplayArtikelProps {
  displayArtikel: PimArtikelVersionDisplayJson[]
  onChange: ValueChangeFn<SeArtikelJson>
  dispatch: (action: ModelAction) => void
  errors: any
}

export const PimArtikelDisplayArtikel = ({
  displayArtikel,
  onChange,
  dispatch,
  errors
}: PimArtikelDisplayArtikelProps) => {
  return (
    <RowForm
      title="Display-Artikel"
      name="displayartikel"
      rows={displayArtikel}
      addLabel="Display hinzufügen"
      onAdd={() =>
        dispatch({
          type: EigenlistungsArtikelUseCase.ADDDISPLAYARTIKEL
        })
      }
      onRemove={(display) =>
        dispatch({ type: EigenlistungsArtikelUseCase.REMOVEDISPLAYARTIKEL, display })
      }
      body={(display) => (
        <PimArtikelDisplayMemo
          display={display}
          onChange={onChangeWrapper(
            onChange,
            `pimArtikel.version.displayArtikel.[__aid=${aidOf(display)}]`
          )}
        />
      )}
    />
  )
}

export const PimArtikelDisplayArtikelMemo = React.memo(PimArtikelDisplayArtikel)

type PimArtikelDisplayProps = {
  display: PimArtikelVersionDisplayJson
  onChange: (e: SyntheticEvent) => void
}

const PimArtikelDisplay = ({ display, onChange }: PimArtikelDisplayProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={3} xl={1}>
        <TextField
          label="hage-Nr."
          value={display.hageNummer}
          onChange={onChange}
          name="hageNummer"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3} xl={2}>
        <NumberField
          label="Menge"
          value={display.menge}
          onChange={onChange}
          name="menge"
          fraction={0}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={6} lg={3} xl={2}>
        <NumberField label="EK" value={display.ek} onChange={onChange} name="ek" fraction={2} />
      </Grid>
    </Grid>
  )
}

const PimArtikelDisplayMemo = React.memo(PimArtikelDisplay)
