import { Grid } from '@mui/material'
import { PimArtikelVersionFlieseJson } from '@one/typings/apiTypings'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { StaticField } from '@utils/ui/fields/StaticField'

export interface ArtikelPimFliesenProps {
  fliese: PimArtikelVersionFlieseJson
}

export const ArtikelPimFliesen = ({ fliese }: ArtikelPimFliesenProps) => {
  return (
    <CardEx title="Fliese" name="Fliese">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Nummer" value={fliese.nummer} name="nummer" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Kategorie" value={fliese.kategorie} name="kategorie" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Serienname" value={fliese.serienname} name="serienname" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Angebotstext 1"
            value={fliese.angebotstext1}
            name="angebotstext1"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Angebotstext 2"
            value={fliese.angebotstext2}
            name="angebotstext2"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}></Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Produktbreite"
            value={fliese.produktBreite}
            name="produktBreite"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Produktlänge"
            value={fliese.produktLaenge}
            name="produktLaenge"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Produkthöhe" value={fliese.produktHoehe} name="produktHoehe" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Nennbreite" value={fliese.nennBreite} name="nennBreite" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Nennlänge" value={fliese.nennLaenge} name="nennLaenge" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Verdrängungsraum"
            value={fliese.verdraengungsraum}
            name="verdraengungsraum"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Farbe" value={fliese.farbe} name="farbe" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Farbfamilie" value={fliese.farbfamilie} name="farbfamilie" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Material" value={fliese.material} name="material" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Oberfläche" value={fliese.oberflaeche} name="oberflaeche" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Design" value={fliese.design} name="design" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Einsatzbereich"
            value={fliese.einsatzbereich}
            name="einsatzbereich"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Nassbereich" value={fliese.nassbereich} name="nassbereich" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Rutschfestigkeit"
            value={fliese.rutschfestigkeit}
            name="rutschfestigkeit"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Abriebgrupppe"
            value={fliese.abriebgruppe}
            name="abriebgruppe"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField label="Formatgruppe" value={fliese.formatgruppe} name="formatgruppe" empty />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Gewicht pro Quadratmeter"
            value={fliese.gewichtProM2}
            name="gewichtProM2"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Inhalt pro Palette"
            value={fliese.inhaltProPaletteM2}
            name="inhaltProPaletteM2"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Inhalt pro Karton"
            value={fliese.inhaltProKartonM2}
            name="inhaltProKartonM2"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Kantenausbildung"
            value={fliese.kantenausbildung}
            name="kantenausbildung"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Glasierung"
            checked={fliese.glasierung}
            name="glasierung"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StaticField
            label="Oberflächenveredelung"
            value={fliese.oberflaechenveredelung}
            name="oberflaechenveredelung"
            empty
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Checkbox
            disabled
            labelNotDisabled
            indeterminate
            label="Frostsicher"
            checked={fliese.frostsicher}
            name="frostsicher"
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
