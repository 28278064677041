import { Grid } from '@mui/material'
import { PimArtikelJson, SeArtikelJson } from '@one/typings/apiTypings'
import { ValueChangeFn } from '@utils/modelmgr'
import { CardEx } from '@utils/ui/CardEx'
import { NumberField } from '@utils/ui/fields/NumberField'
import React from 'react'

export interface PimArtikelMasseProps {
  pimArtikel: PimArtikelJson
  onChange: ValueChangeFn<SeArtikelJson>
  errors: any
}

export const PimArtikelMasse = ({ pimArtikel, onChange, errors }: PimArtikelMasseProps) => {
  return (
    <CardEx title="Maße">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Breite Netto"
            name="pimArtikel.version.artikelBreiteNetto"
            value={pimArtikel.version.artikelBreiteNetto}
            fraction={4}
            thousandSeparator
            suffix=" mm"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Höhe Netto"
            name="pimArtikel.version.artikelHoeheNetto"
            value={pimArtikel.version.artikelHoeheNetto}
            fraction={4}
            thousandSeparator
            suffix=" mm"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Tiefe Netto"
            name="pimArtikel.version.artikelTiefeNetto"
            value={pimArtikel.version.artikelTiefeNetto}
            fraction={4}
            thousandSeparator
            suffix=" mm"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Gewicht Brutto"
            name="pimArtikel.version.artikelGewichtBrutto"
            value={pimArtikel.version.artikelGewichtBrutto}
            fraction={4}
            thousandSeparator
            suffix=" kg"
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberField
            label="Länge Brutto"
            name="pimArtikel.version.artikelLaengeBrutto"
            value={pimArtikel.version.artikelLaengeBrutto}
            fraction={4}
            thousandSeparator
            suffix=" mm"
            onChange={onChange}
            fullWidth
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelMasseMemo = React.memo(PimArtikelMasse)
